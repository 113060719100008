import React,{Fragment} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import GutenbergRenderer from '../gutenberg';
import {PageTitle} from '../components/common';
import {MetaInfo, MetaInfoRegular} from '../components/typography';
import {CeficShare as SocialShare} from '../components/social-shares';
import {CommitteeMembersGroup, CommitteeBoard} from '../components/committees';


export const CommitteePage = (props) => {
  let content = (<div dangerouslySetInnerHTML={{__html:props.content}}/>);
  let subcommittees = false;
  let members = false;
  if(props.acf.template_options && props.acf.template_options.use_content_from){
    content = (<div dangerouslySetInnerHTML={{__html:props.acf.template_options.use_content_from.content}}/>);
  }

    if(props.acf.template_options && props.acf.template_options.show_sub_committees && props.sub_committees){
      subcommittees =
        (<Fragment>
           {props.sub_committees.map(sc => (
             <CommitteeBoard {...sc} key={sc.slug}/>
           ))}
         </Fragment>);
    }
  if(props.acf.template_options && props.acf.template_options.show_members && props.member_groups){
    members = (
      <Fragment>
        {props.member_groups.map(mg =>   <CommitteeMembersGroup show_thumbnail={props.acf.template_options.show_members_picture} show_link={props.acf.template_options.activate_member_profile_pages} show_website={props.acf.template_options.show_members_website} committee_slug={props.path} key={mg.name} {...mg}/>)}
      </Fragment>
    );
  }
  return(
    <Fragment>
      <PageTitle title={props.title}/>
      <div className="wp_content">
        {content}
        {subcommittees}
        {members}
      </div>
    </Fragment>
  );
};

export default (props) =>{
  const data = props.data.wordpressWpCommittee;
  const blocks = data.editor_blocks;
  return(
    <Layout ancestors={data.ancestors} pageContext={props.pageContext}>
      <div style={{maxWidth:1025}} className="mx-0 px-0">
        <div style={{maxWidth: 734}} className="mx-0 px-0">
          <CommitteePage {...data}/>
          <SocialShare  url={data.path} context={props.pageContext.yoast} />
        </div>
      </div>
    </Layout>
  );
}
export const defaultCommitteeQuery = graphql`
query DefaultCommitteeById($id: String!) {
  wordpressWpCommittee(id:{eq:$id}){
    title
    slug
    path
    wordpress_id
    content
    ancestors {
      name
      slug
      path
    }
    member_groups {
      name
      members {
        title
        slug
        featured_media{
          localFile {
            childImageSharp{
              fixed(width: 90, height: 100, quality:100){
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        acf{
          company
          position
          other_titles
          website {
            target
            url
            title
          }
        }
      }
    }
    sub_committees {
      slug
      wordpress_id
      title
      menu_order
      chairman {
       title
       acf {
        company
       }
     }
     executive_director {
       title
       acf {
         company
       }
     }
    }
    acf{
     template_options{
        use_content_from {
          content
          template
        }
        show_sub_committees
        show_members
        show_members_picture
        activate_member_profile_pages
        show_members_website
     }
     box_options{
      border_color
      inline_subcommittees
     }
    }
  }
}
`;
