import React from 'react';
import {
  StaticQuery,
  graphql
} from 'gatsby';
import {
  Link
} from '../link';
import SocialLinks from '../widgets/social_links';
import NewsAppWidget from '../NewsApp/topbarWidget';

const MenuItem = (props) => {
  return (
    <li className="lg:mr-6 lg:mb-0 mb-2 text-white w-full lg:w-auto text-center" style={{order:props.order}}>
      <Link id={"toolbar-top__menu_item__"+props.order} className="toolbar-top__menu_item uppercase no-underline text-2xs tracking-wide  hover:font-bold" to={props.slug} target={props.target} style={{color:'inherit'}} url={props.url}>{props.title}</Link>
    </li>
  )
}

export const ToolbarTopMenu = () => (
<StaticQuery query={
    graphql`
		query ToolbarTopMenuQuery {
			menu: allWordpressMenusMenusItems(filter:{slug:{eq:"top_toolbar_menu"}}){
				edges{
					node{
						items{
							wordpress_id
							title
							type:object
							url
							slug
							order:menu_order
						}
					}
				}
			}
		}`
	} render={data=> {
			const menu = data.menu.edges[0].node
			let i = 0
			return(
				<nav id="toobar-top-nav" className="top-navbar w-full pt-4 lg:pt-0">
				<ul className="list-reset flex flex-col lg:flex-row h-full items-center w-full lg:justify-end">
        {menu.items.map((item, index)=> {
							i += (i===menu.items.length-1?2:1);
							return (
								<MenuItem {...item}  key={"toolbar-top-nav_"+index} order={i}/>
							)
						})
				}
			  <li className="lg:mr-6 text-white text-center bg-brand px-2 py-1 w-full lg:w-auto mb-2 lg:mb-0" style={{order:3}}>
            <NewsAppWidget/>
          </li>
				<li className = "lg:mr-6 text-white w-full lg:w-auto text-center mb-2 lg:mb-0" style = {{order: 4}} >
		  		  <SocialLinks fontSize="text-2xs" textColor="white"/>
				  </li>
				</ul>
				</nav>
			)
		}
	}
	/>
)
