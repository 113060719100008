import {createStore as reduxCreateStore} from 'redux';
//import devToolsEnhancer from 'remote-redux-devtools';
const reducer = (state, action)=> {
  if(action.type === `OPEN_PANEL`){

    return Object.assign({}, state, {
      panel: {
        isOpen:true,
        content: state.panel.content,
        context: {}
      },
      sdgPanel: {
        isOpen: false
      },
      searchPanel: {
        isOpen: false,
      },
    });
  }
  if(action.type === `CLOSE_PANEL`){
    return Object.assign({}, state, {
      panel: {isOpen:false, content:state.panel.content}
    });
  }
  if(action.type === `SET_PANEL_CONTENT`){
    const new_state =Object.assign({}, state, {
      panel: {
        isOpen:state.panel.isOpen,
        content: action.content,
        context: action.context || {}
      }
    });
    //window.dataLayer.
    if (action.hasOwnProperty('path')){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'open_in_panel',
        'url': action.path,
				'path': action.path
      });
    }
    return new_state;
  }
  if(action.type === `OPEN_SEARCH`){
    return Object.assign({},state, {
      searchPanel: {
        ...state.searchPanel,
        isOpen: true,
      },
      panel: {
        ...state.panel,
        isOpen: false
      },
      sdgPanel: {
        ...state.sdgPanel,
        isOpen: false
      }
    });
  }
  if(action.type === `CLOSE_SEARCH`){
    return Object.assign({},state, {
      searchPanel: {
        ...state.searchPanel,
        isOpen: false,
      },
    });
  }
  if(action.type === `OPEN_SDG`){
    return Object.assign({},state, {
      sdgPanel: {
        isOpen: true,
        item: action.item
      },
      searchPanel: {
        isOpen: false,
      },
      panel: {
        ...state.panel,
        isOpen: false
      }
    });
  }
  if(action.type === `CLOSE_SDG`){
    return Object.assign({},state, {
      sdgPanel: {
        ...state.sdgPanel,
        isOpen: false
      },
    });
  }

  if(action.type === 'UPDATE_SEARCH_STRING') {
    return Object.assign({},state, {
      searchPanel: {
        ...state.searchPanel,
        searchString: action.searchString,
      },
    });
  }

  if(action.type === 'UPDATE_SEARCH_ITEMS') {

    return Object.assign({},state, {
      searchPanel: {
        ...state.searchPanel,
        items: action.items,
      },
    });
  }
  if(action.type === 'CLICK_AFTER_SEARCH'){
    if(action.item && action.item.path){
      let position = -1;
      state.searchPanel.items.forEach((item, index)=>{
        if(item.wordpress_id === action.item.wordpress_id){
          position = index+1;
        }
      });
      const res = {
        event: 'search_success',
        searchString: state.searchPanel.searchString,
        target: action.item.path,
        total_results: state.searchPanel.items.length,
        target_position: position
      };
      if(window.dataLayer){
        window.dataLayer.push(res);
      }
    }

    return state;
  }
  return state;
};

const initialState = {
  upper_menus:[],
  panel: {
    content: '',
    isOpen: false
  },
  searchPanel: {
    isOpen: false
  },
  sdgPanel: {
    isOpen: false,
    items: []
  },
  sdgPanel: {
    isOpen: false,
    item: undefined,
  }
};

const createStore = () => reduxCreateStore(reducer, initialState/*, devToolsEnhancer()*/);
export default createStore;
