import React from 'react';
import {Link} from '../../link';
import {navigate} from 'gatsby';
import {SubTitle, PBody, MetaInfo } from '../../typography';
import Location from '../parts/location';
import {SecondaryButton} from '../../buttons';
import EventTimes from '../parts/time';
export const EventPreviewWithButton = (props) =>{
  const componentId='event-preview__'+props.slug;
  return (
    <article className="py-5" id={componentId}>
      <div>
        <EventTimes hideTimes {...props} separator="<br/>"/>
        <SubTitle dangerouslySetInnerHTML={{__html:props.title}}/>
      </div>
      <Location location={props.location}/>
      <div className="mt-5">
        <Link id={componentId+'__cta'} to={props.path} className="event-preview__cta">
          <SecondaryButton label='Join Us +' className="mt-5"/>
        </Link>
      </div>
    </article>
  );
};
