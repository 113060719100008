import React, {useEffect} from 'react'
import {useStaticQuery, graphql} from 'gatsby'

export const useFormPages = () => {
  const data = useStaticQuery(graphql`
    query allFormPages {
      allWordpressPage(filter: {template:{eq:"views/template-form.blade.php"}}){
        nodes{
          title
          path
          content
          wordpress_id
          slug
          acf {
            form
          }
        }
      }
      allGravityForm{
        nodes {
          wordpress_id
          wordpress_fields
        }
      }
    }
    `)
  const results = data.allWordpressPage.nodes.map(page => {
    return {
      page: page,
      form: data.allGravityForm.nodes.find(form=>form.wordpress_id===page.acf.form)
    }

  })
  return results
}
