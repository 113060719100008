import React, {Fragment} from 'react';
import {
  PageTitle
} from '../common';
import {MetaInfo} from '../typography';

class Job extends React.Component {
  constructor(props){
    super(props);
  }
  render(){
    return(
      <Fragment>
        <PageTitle title={this.props.title} />
        <div className="mb-4">
          <MetaInfo >Deadline {this.props.deadline} </MetaInfo>
        </div>
        <div className="wp_content" dangerouslySetInnerHTML={{__html:this.props.content}}/>
      </Fragment>
    );
  }
}
export default Job;
