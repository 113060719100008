import React, {useEffect, useState} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle, WidgetAside} from '../components/common';
import SupportingDocuments from '../components/library/supporting_documents';
import {CeficShare as SocialShare} from '../components/social-shares';
import FlexibleContent from '../components/flexible-builder';
import {GreyAside} from '../components/aside';
import {withPrintSupport} from '../components/printer';
import {DefaultTemplateHandler} from '../components/printer/handlers/default';


const Content = (props) => {
  return (
    <div {...props}/>
  );
}

const ContentWithPrintSupport = withPrintSupport(
  Content,
  DefaultTemplateHandler
)

export default ({data, pageContext}) =>{
  const acf = data.wordpressPage.acf;
  const contactBox = acf && acf.contact_box && acf.contact_box.show_contact_box?acf.contact_box:null;

  return(
    <>
			<Layout pageContext={pageContext} aside={GreyAside({aside:data.wordpressPage.aside})} ancestors={data.wordpressPage.ancestors}>
      <div style={{maxWidth:1080}} className="container mx-0 px-0">
        <div style={{maxWidth: 910}} className="container mx-0 px-0">
          <PageTitle title={data.wordpressPage.title}/>
          <ContentWithPrintSupport handler_data={data.wordpressPage} className="wp_content" dangerouslySetInnerHTML={{__html:data.wordpressPage.content}} />
          {data.wordpressPage.display_sharing_toolbar &&
           <SocialShare printOptions={data.wordpressPage.printOptions} url={data.wordpressPage.path} context={pageContext.yoast} className="my-10"/>
          }
        </div>
        {data.wordpressPage?.widgets?.widgets.length>0 &&
         <FlexibleContent data={data.wordpressPage.widgets.widgets}/>
        }

        {data.wordpressPage.supporting_documents &&
         <SupportingDocuments {...data.wordpressPage.supporting_documents}/>
        }
      </div>

      </Layout>



    </>
  );
}
export const defaultPagequery = graphql`
query DefaultPageById($id: String!) {
  wordpressPage(id:{eq:$id}){
    title
    slug
    path
    wordpress_id
    wordpress_parent
    content
    excerpt
    aside {
     ...AsideFragment
    }
display_sharing_toolbar
printOptions {
display
}
widgets{
      ...FlexbileContentBuilder
    }

     supporting_documents {
      title
      documents {
        title
        excerpt
        content_type
        topics
        slug
        date
        wordpress_id
        default_image {
          localFile {
            childImageSharp {
              fixed(width: 97, height: 138) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        acf {
          document_type
          external_link
          file {
            mime_type
            alt
          }
        }
        featured_media {
          localFile {
            smallHThumb: childImageSharp {
              fixed(width: 97, height: 138) {
                ...GatsbyImageSharpFixed
              }
           }
          }
        }
      }
    }
    ancestors {
      slug
      name:title
path
    }
    acf {
      contact_box{
          show_contact_box
          title
          text
          phone
          email
        }
    }
  }
}
`;
