import React from 'react';
import { Link } from 'gatsby';

const Breadcrumb = ( { label, to } ) => (
  <div className="lg:vertical-breadcrumb py-3 px-1 uppercase text-left lg:text-right  border-l-0 lg:border-l border-b lg:border-0 w-full lg:w-auto border-grey-lighter text-grey-dark hover:text-brand cursor-pointer">
    <Link className='no-underline' style={{color:'inherit'}} to={to} dangerouslySetInnerHTML={{__html:label}}/>
  </div>
);

export default ({items})=> (

  <div id="breadcrumbs" className="relative">
    <div className="lg:sticky lg:pin-t lg:h-screen">
      <div className="breadcrumb-wrapper lg:flex lg-absolute lg:h-full ">
        {items && items.slice(0).reverse().map(i=>{
          if(!!i.path){
            return (<Breadcrumb label={i.name} to={i.path} key={i.path}/>)
          }
          let fixed_slug = i.slug;
          if(fixed_slug[0]!=="/"){
            fixed_slug = `/${fixed_slug}`;
          }
          if(fixed_slug.slice(-1)!=='/'){
            fixed_slug=fixed_slug+'/';
          }
          return(
            <Breadcrumb label={i.name} to={fixed_slug} key={i.slug}/>
          );
        })
        }
      </div>
    </div>
  </div>
);
