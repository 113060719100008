import React from 'react';
import {
  StaticQuery,
  graphql
} from 'gatsby';
import {
  Link
} from '../link';

const MenuItem = (props) => {
  return (
    <li className="">
      <Link className="menu uppercase no-underline text-black text-2xs tracking-wide  hover:font-bold hover:text-brand" to={props.slug} target={props.target} dangerouslySetInnerHTML={{__html:props.title}}/>
    </li>
  );
};

export const FooterMenu = () => (
<StaticQuery query={
    graphql`
query FooterMenuQuery {
menu: allWordpressMenusMenusItems(filter:{slug:{eq:"footer"}}){
  edges{
    node{
      items{
        wordpress_id
        title
        type:object
        url
        slug
        order:menu_order
      }
    }
  }
}}
`} render={data=> {
  const menu = data.menu.edges[0].node;
  return(
    <nav id="footer-nav" className="mb-5 text-center lg:text-left">
      <ul className="list-reset">
        {menu.items.map((menu, index)=> (
          <MenuItem {...menu} key={index}/>
))
}
</ul> <
/nav>
);
}
}
/>
);
