import React from "react";
const path = require("path");
import {graphql} from 'gatsby';
import SupportingDocuments from './supporting_documents';
import RelatedDocuments from './related-content';



export const ComponentsMap = {
  "SupportingDocumentsWidget": SupportingDocuments,
  "RelatedDocumentsWidget":RelatedDocuments
}

const DefaultComponent = (props) => {
  return(<></>)
}
const FlexibleContent = ({data}) => {
  return  (
    <div>
      {
        !!data && data.map((group, group_id) => {
          if(!!group){
            const Component = ComponentsMap[group.kind]?ComponentsMap[group.kind]:DefaultComponent;
            return (
              <Component {...group} key={group_id}/>
            );
          }
        }
                          )
      }
   </div>
  );
};
export default FlexibleContent;

export const query = graphql`
fragment FlexbileContentBuilder on BlockCollection{
  widgets{
    kind
    ... on RelatedDocumentsWidget{
      title
      items{
        title
        path
        description
        slug
        is_download
        is_external
        featured_media{
          localFile {
            smallHThumb: childImageSharp {
              fixed(width: 97, height: 138) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    ... on SupportingDocumentsWidget {
      title
      options {
        tagline_mode
      }
      items {
        title
        path
        description
        slug
        type
        date
        is_download
        is_external
        featured_media{
          localFile {
            smallHThumb: childImageSharp {
              fixed(width: 97, height: 138) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
}
`
