import React, { Component } from 'react'
import { PBody, SubSubTitle, SmallButtonLabel, PageTitle, PSubTitle } from '../typography';
import {ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import {ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import classnames from 'classnames';
import {SecondaryButton} from '../buttons.js'
import {Link} from '../link'

export class MobileRelatedCategories extends Component {
  render(){
    return(
      <>
        {this.props.title && 
         <PageTitle dangerouslySetInnerHTML={{__html:this.props.title}}/>
        }
        <div className="mt-10">
          {this.props.categories.map(category=><Category {...category} key={category.wordpress_id} />)}
        </div>
      </>
    )
  }
}
export class RelatedCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hoverChevronRight: false,
      hoverChevronLeft: false,
      showPrev: false,
      showNext: false,
      startingIndex: 0
    }
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }
  componentDidMount(){
    this.setState({
      showPrev: this.state.startingIndex>0,
      showNext: this.state.startingIndex+3<this.props.categories.length
    });
  }
  next(step=3){
    const futureStartingIndex = Math.min(this.state.startingIndex + step, this.props.categories.length-1);
    this.setState({
      showPrev: futureStartingIndex > 0,
      showNext: futureStartingIndex+3 < this.props.categories.length,
      startingIndex: futureStartingIndex
    });
  }
  prev(step=3){
    const futureStartingIndex = Math.max(this.state.startingIndex - step, 0);
    this.setState({
      showPrev: futureStartingIndex > 0,
      showNext: futureStartingIndex+3 < this.props.categories.length,
      startingIndex: futureStartingIndex
    });
  }
  render() {
    const categoriesDisplay = this.props.categories.slice(this.state.startingIndex,this.state.startingIndex+3)

    const classesChevronLeft = classnames(
      'fill-current',
      {
        'text-grey': !this.state.hoverChevronLeft,
        'text-brand': this.state.hoverChevronLeft
      }
    );
    const classesChevronRight = classnames(
      'fill-current',
      {
        'text-grey': !this.state.hoverChevronRight,
        'text-brand': this.state.hoverChevronRight
      }
    );
    return (
      <div className='related_categories mt-10'>
        {this.props.title && 
         <PSubTitle dangerouslySetInnerHTML={{__html:this.props.title}}/>
        }
        <div className='flex flex-row mt-10'>
          {this.state.showPrev && 
          <div
            className='flex items-center m-2'
          >
            <button
              onMouseEnter={() => (this.state.startingIndex != 0) && this.setState({hoverChevronLeft: true})}
              onMouseLeave={() => this.setState({hoverChevronLeft: false})}
              disabled={this.state.startingIndex == 0}
           onClick={() => this.prev(3)}
            >
              <ChevronLeft width={25} height={25} className={classesChevronLeft}/>
            </button>
          </div>
          }
          <div className='flex flex-grow flex-row'>
              {categoriesDisplay.map((category) => (
                <Category {...category}  key={category.wordpress_id}/>
              ))}
          </div>
          {this.state.showNext && 
          <div 
            className='flex items-center m-2'
          >
            <button
              onMouseEnter={() => (this.state.startingIndex < (this.props.categories.length - 1)) && this.setState({hoverChevronRight: true})}
              onMouseLeave={() => this.setState({hoverChevronRight: false})}
              onClick={() => this.next(3) }
            >
              <ChevronRight width={25} height={25} className={classesChevronRight}/>
            </button>
           </div>
          }
        </div>
      </div>
    )
  }
}

export class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered : false
    }
  }
  render() {
    return (
      <div
        id={`related_category__slide__${this.props.wordpress_id}`}
        className='related_category__slide flex flex-col flex-no-grow justify-between m-2 border p-4'
        style= {{flexBasis: 293}}
        onMouseEnter={() => this.setState({hovered: true})}
        onMouseLeave={() => this.setState({hovered: false})}
      >
        <SubSubTitle hover={this.state.hovered} dangerouslySetInnerHTML={{__html:this.props.name}}/>
        <PBody dangerouslySetInnerHTML={{__html:this.props.description}}/>
        <div>
          
          <Link to={this.props.path} className="related_category__slide__cta">
      <SecondaryButton label="READ MORE+"/>
      </Link>
        </div>
      </div>
    )
  }
}



export default RelatedCategories
