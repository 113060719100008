import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import { PageTitle } from "../components/common";
import { CeficShare as SocialShare } from "../components/social-shares";
import FlexibleContent from "../components/flexible-builder";
import { withFilteredList } from "../components/posts/providers";
import { GridPostsList } from "../components/posts/list";
import { GreyAside } from "../components/aside";

export default ({ data, pageContext }) => {
	const grid_settings = data.wordpressPage.grid_settings;
	let filters = [];
	const gql_filters = ["tags", "industries", "categories"];
	filters = gql_filters
		.map((f_key, idx) => {
			const nodes = data?.[f_key]?.nodes;
			const tax = nodes?.[0]?.taxonomy?.slug;
			const matchKey = grid_settings?.[f_key]?.match_key || tax.slug;
			if (nodes?.length > 0 && !!tax) {
				return {
					taxonomy: tax,
					matchKey: matchKey,
					placeholder: grid_settings?.[f_key]?.placeholder,
					selected_term: null,
					order: idx,
					terms: nodes.map((n) => {
						return { value: n.id, label: n.name };
					}),
					display_filter: grid_settings?.[f_key]?.display,
				};
			}
		})
		.filter((f) => !!f && f?.display_filter);

	const FilteredGridList = withFilteredList(GridPostsList);

	return (
		<Layout
			pageContext={pageContext}
			aside={GreyAside({ aside: data.wordpressPage.aside })}
			ancestors={data.wordpressPage.ancestors}
		>
			<div style={{ maxWidth: 1080 }} className=" container mx-0 px-0">
				<div style={{ maxWidth: 910 }} className="container mx-0 px-0">
					<PageTitle title={data.wordpressPage.title} />
					<div
						className="wp_content"
						dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
					/>

					{grid_settings?.posts?.length > 0 && (
						<FilteredGridList
							filters={filters}
							posts={grid_settings.posts.map((p) => {
								const tags_label = `${p?.tags?.map((c) => c.name)?.join(",")} ${
									!!p.industries.length
										? " - " + p?.industries?.map((c) => c.name)?.join(",")
										: ""
								}`;
								return {
									...p,
									tags_label: tags_label,
								};
							})}
							analyticsName={"crpost"}
						/>
					)}

					{data.wordpressPage.display_sharing_toolbar && (
						<SocialShare
							url={pageContext.yoast.canonical}
							context={pageContext.yoast}
							className="my-10"
						/>
					)}
				</div>

				{data.wordpressPage?.widgets?.widgets.length > 0 && (
					<FlexibleContent data={data.wordpressPage.widgets.widgets} />
				)}
			</div>
		</Layout>
	);
};
export const defaultPagequery = graphql`
	query CCanGridPageById($id: String!) {
		categories: allWordpressWpCcanCategory(
			filter: { count: { gt: 0 } }
			sort: { order: ASC, fields: name }
		) {
			nodes {
				taxonomy {
					name
					slug
				}
				name
				id: wordpress_id
				count
			}
		}
		industries: allWordpressWpCcanIndustry(
			filter: { count: { gt: 0 } }
			sort: { order: ASC, fields: name }
		) {
			nodes {
				taxonomy {
					name
					slug
				}
				name
				id: wordpress_id
				count
			}
		}
		tags: allWordpressTag(
			filter: { count: { gt: 0 } }
			sort: { order: ASC, fields: name }
		) {
			nodes {
				taxonomy {
					name
					slug
				}
				name
				id: wordpress_id

				count
			}
		}

		wordpressPage(id: { eq: $id }) {
			title
			slug
			path
			wordpress_id
			wordpress_parent
			content
			excerpt

			display_sharing_toolbar
			widgets {
				...FlexbileContentBuilder
			}
			aside {
				...AsideFragment
			}

			ancestors {
				path
				name: title
			}

			grid_settings: ccan_grid_settings {
				restrict_to_category
				industries: industries_filter {
					display
					placeholder
					match_key
				}
				tags: tags_filter {
					display
					placeholder
					match_key
				}
				categories: categories_filter {
					display
					placeholder
					match_key
				}
				posts {
					slug
					path
					title
					menu_order
					categories: ccan_category {
						id: wordpress_id
						name
					}
					industries {
						id: wordpress_id
						name
					}
					tags {
						id: wordpress_id
						name
					}
					featured_media {
						localFile {
							verticalThumb: childImageSharp {
								fluid(maxWidth: 335, maxHeight: 160, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`;
