import React from 'react';
import {Link as GatsbyLink} from 'gatsby';


// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
export const Link = ({ children, to, activeClassName,url, ...other }) => {
  // strip wp source if any.


  // copy url to to for backward compatibility
  if((url && url!=='') && (!to || to==='')) {
    to = url
  }

  const backend_url = `https://${process.env.GATSBY_WP_URL}`
  let _to = to.toString();
  ///  _to = _to.replace(backend_url, '')
  const re_domain = new RegExp(`^${backend_url}`);
  const re_lib_item = new RegExp(`^${backend_url}/library-item/.*`);

  const internal = _to && (!/^http(s)?:\/\//.test(_to) || (re_domain.test(_to) && !re_lib_item.test(_to))) ;


  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    _to = _to.replace(backend_url, '');
    return (
      <GatsbyLink to={_to} activeClassName={activeClassName} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={_to} target='_blank' rel="noopener noreferrer" {...other} >
      {children}
    </a>
  );
};
