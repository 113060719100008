import React from 'react';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import {LargeButtonLabel} from '../typography';
import classnames from "classnames";

export const SearchField = (props) => {
  const className = classnames(
    "flex items-center border-b border-b-2 border-black ",
    props.className
  );
  return(
    <div className={className}>
    <TextInput
      className="appearance-none bg-transparent border-none w-full  text-grey-darker mr-3 py-1 px-2 leading-tight focus:outline-none"
      placeholder={props.placeholder}
      value={props.value}
      onChange={e=>{props.onChangeHandler(e.target.value)}}
    />

    <SearchIcon/>
  </div>
)
};

export const TextInput = (props) => (
  <input type="text" placeholder={props.placeholder} id={props.id} value={props.value} onChange={props.onChange} className={props.className}/>
);
export const MailInput = ({placeholder, id, value, onChange, className,...props}) => (
  <input type="mail" placeholder={placeholder} id={id} value={value} onChange={onChange} className={classnames("appearance-none bg-transparent border-b border-black  text-grey-darker mr-3 py-1 px-2 leading-tight focus:outline-none", className)} {...props}/>
);
export const CheckboxInput = (props) => (
  <div>
  <label htmlFor={props.id}>
    <input className='mr-2 mb-3' type="checkbox" id={props.id} name={props.name} value={props.value} onChange={props.onChange} checked={props.checked} />
    {props.children}
  </label>
  </div>
);

export const FormGroupLabel = (props)=>(
  <p className="uppercase font-lg tracking-02 mb-7" dangerouslySetInnerHTML={{__html:props.name}}/>
);

export class SubmitButton extends React.Component{
  constructor (props) {
    super(props);
    this.state = {
      hover: false
    };
  }
  render(){
    const className= classnames(
      'border p-button text-center align-bottom',
      {
        'border-grey-darkest': !this.state.hover,
        'border-brand bg-brand text-black': this.state.hover
      },
      this.props.className
    );
    return (
      <button className={className} onClick={this.props.onClickHandler} onMouseEnter={e=>{this.setState({hover:true});}} onMouseLeave={e=>{this.setState({hover:false});}} type='submit' form={this.props.form} value="Submit">
        <LargeButtonLabel hover={this.state.hover} dangerouslySetInnerHTML={{__html:this.props.label}} />
      </button>
    );
  }
}
