import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle, WidgetAside} from '../components/common';
import SupportingDocuments from '../components/library/supporting_documents';
import {CeficShare as SocialShare} from '../components/social-shares';
import Form from '../components/gravityforms'

export const FormComponent = (props) => {
  const form = {
    ...props.form,
    fields: props.form.wordpress_fields.map(field=>JSON.parse(field))
  }
  return (
    <>
      <PageTitle title={props.page.title}/>
      <div className="wp_content" dangerouslySetInnerHTML={{__html:props.page.content}} />
      <Form {...form}/>

    </>
  )
}

export default ({data, pageContext}) =>{
  const acf = data.wordpressPage.acf;
  const form_id = acf.form
  const form = data.allGravityForm.nodes.find(form=>form.wordpress_id === form_id)

  const contactBox = acf && acf.contact_box && acf.contact_box.show_contact_box?acf.contact_box:null;
  return(
    <Layout pageContext={pageContext} aside={WidgetAside({contactBox:contactBox})} ancestors={data.wordpressPage.ancestors}>
      <div style={{maxWidth:1080}} className="container mx-0 px-0">
        <div style={{maxWidth: 910}} className="container mx-0 px-0">
          <FormComponent
            page={data.wordpressPage}
            form={form} />
          {data.wordpressPage.display_sharing_toolbar && 
           <SocialShare  url={pageContext.yoast.canonical} context={pageContext.yoast} className="my-10"/>
          }
        </div>
        {data.wordpressPage.supporting_documents && 
         <SupportingDocuments {...data.wordpressPage.supporting_documents}/>
        }
      </div>
    </Layout>
  );
}
export const defaultPagequery = graphql`
query DefaultPageWithFormById($id: String!) {
  wordpressPage(id:{eq:$id}){
    title
    slug
    path
    wordpress_id
    wordpress_parent
    content
    excerpt
    display_sharing_toolbar
    ancestors {
      slug
      name:title
    }
    acf {
      form
    }
  }
  allGravityForm{
    nodes {
     wordpress_id
     wordpress_fields
   }
  }
}
`;

