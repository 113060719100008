import React from 'react';
import classnames from 'classnames';
import {ReactComponent as Download } from '../../../assets/icons/file-download.svg';
import {ReactComponent as  ChevronRight } from '../../../assets/icons/chevron-right.svg';
import {SubSubTitle, PBody, MetaInfo} from '../../typography';
import Img from 'gatsby-image';
import Moment from 'react-moment';

const LibraryThumbnail = (props) => {
  let thumbnail = false;

  if (props.featured_media && props?.featured_media?.localFile?.smallHThumb){
    thumbnail = props.featured_media.localFile.smallHThumb;
  }else if(props.default_image) {
    thumbnail = props.default_image.localFile.childImageSharp;
  }
  return (
    <div className="relative flex-no-shrink  " style={{width:97, height:138}}>
      {thumbnail!==false ?
       <Img fixed={thumbnail.fixed} style={{margin:'0 auto'}}/>
       :
       <svg className="fill-current block text-grey-lightest flex-no-shrink" width="100%" height="100%">
         <rect width="100%" height="100%"/>
       </svg>
      }
      {props.hover &&
       <svg className="block absolute opacity-75 pin-y pin-l fill-current text-brand flex-no-shrink z-50" width='100%' height='100%'>
         <rect width="100%" height="100%"/>
       </svg>
      }
    </div>
  );
};

export class HorizontalPreview extends React.Component  {
  constructor(props){
    super(props);
    this.state = {
      hover: false
    };
    this.clickHandler = this.clickHandler.bind(this);
  }
  clickHandler() {
    if(this.props.acf.document_type === 'link'){
      window.open(this.props.acf.external_link, '_blank');
    }
  }
  render(){
    const classesChevron = classnames(
      'fill-current',
      {
        'text-grey-darkest': !this.state.hover,
        'text-brand': this.state.hover
      }
    );
    const className=classnames(
      'relative flex items-top justify-center  border-b',
      {'hover:cursor-pointer': this.props.acf.document_type !== 'file'}
    )
    return(
      <div className={className}  onMouseEnter={e=>{this.setState({hover:this.props.acf.document_type!=='file'});}} onMouseLeave={e=>{this.setState({hover:false});}} onClick={this.clickHandler}  >
        {this.props.showThumbnail &&
         <LibraryThumbnail hover={this.state.hover?this.state.hover:undefined} {...this.props}/>
        }
        <div className="px-15px py-2 flex flex-col jutify-between overflow-auto flex-grow">
          <div><SubSubTitle hover={this.state.hover?this.state.hover:undefined} dangerouslySetInnerHTML={{__html:this.props.title}}/></div>
          <div className="">
            <PBody dangerouslySetInnerHTML={{__html:this.props.excerpt}}/>
          </div>
          {!!this.props.date &&
          <div className="mt-auto">
            <MetaInfo> <Moment format="DD/MM/YYYY">{this.props.date}</Moment></MetaInfo>
          </div>
          }
        </div>
        <div className="flex items-center ml-3">
          {this.props.acf.document_type === 'file' &&
          <a href={`https://${process.env.GATSBY_WP_URL}/library-item/${this.props.slug}`} target="_blank">
            <Download width={20} height={20} className='fill-current text-grey-darkest hover:text-brand'/>
           </a>
          }
      {this.props.acf.document_type === 'link' &&
       <a href={this.props.acf.external_link} target="_blank" className="flex items-center">
         {this.state.hover &&
          <span>Go to website</span>
         }
         <ChevronRight width={20} height={20} className={classesChevron}/>
       </a>
      }
        </div>
      </div>
    );
  }
};
