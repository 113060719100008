import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import {WidgetAside, PageTitle} from '../components/common';
import MediaQuery from 'react-responsive';
import {LocalPostsList as LNL} from '../components/posts/list';
import EventsList from '../components/events/list';
import {GreyAside} from '../components/aside';

const EventsArchive = (props )=> {
  const page = props.data.page;


  return(
    <Layout aside={GreyAside({aside:page.aside})} pageContext={props.pageContext} ancestors={page.ancestors}>
      <div style={{maxWidth:968}} className="container mx-0 px-0">
        <div style={{maxWidth: 910}} className="container mx-0 px-0">
          <PageTitle title={page.title}/>
          {page.content && page.content!== '' &&
           <div className="page-content" dangerouslySetInnerHTML={{__html:page.content}}/>
          }
        </div>
        <EventsList openInPanel={true} />
      </div>
    </Layout>
  );
};

export default EventsArchive;


export const EventsArchiveQuery = graphql`
  query EventsArchiveQuery($id: String) {
    page: wordpressPage(id:{eq:$id}){
      title
      content
      ancestors {
        name:title
        path
        slug
      }
 aside {
     ...AsideFragment
    }

      acf {
      contact_box{
          show_contact_box
          title
          text
          phone
          email
        }
      }
    }
  }
`
