
import React from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';

// headings //

export const PageTitle = (props)=>(
  <h1 className="font-serif font-normal text-5xl capitalize tracking-02 leading-47" {...props}/>
);
export const SubTitle = (props)=>{
  const className= classNames(
    'font-serif font-normal capitalize text-2xl tracking-normal leading-24',
    {
      'text-brand': props.hover
    },
    {
      'text-black': !props.hover
    }
  );
  let hprops = {...props};
  delete hprops.hover;
  return(
    <h2 className={className} {...hprops}></h2>
  );
};
export const SubSubTitle = (props)=>{
  const className= classNames(
    'font-sans font-light uppercase text-lg tracking-02 leading-22',
    {
      'text-brand': props.hover,
      'text-black': !props.hover
    },


  );
  let hprops = {...props};
  delete hprops.hover;
  return(
    <h2 className={className} {...hprops}></h2>
  );
};

export const SubSectionTitle = (props)=>(
  <h4 className="font-serif font-normal capitalize text-lg tracking-02 leading-24" {...props}/>
);

export const HomeSectionTitle = (props)=>(
  <h1 className="font-serif font-normal capitalize text-6xl tracking-02 leading-60" {...props}/>
);


export const ListItemTitle = (props) => (
  <h1 className="font-serif font-normal leading-24 m-0" {...props}/>
)
// p styles

export const PTitle = ({className,...props})=>(
  <p className={classNames("font-serif font-normal text-5xl capitalize tracking-02 leading-47",className)} {...props}/>
);
export const PSubTitle = ({className,...props})=>(
  <p className={classNames("font-serif font-normal text-3xl capitalize tracking-02 leading-47",className)} {...props}/>
);
export const PBody = (props)=>(
  <p className="font-sans font-light text-base text-black tracking-02 leading-21" {...props}/>
);

export const NewsTag = ({className,...props})=>(
  <p className={classNames("font-sans font-bold text-brand text-xs tracking-05 leading-15 my-20px", className)} {...props}/>
);

//span styles
export const SpanTag = ({className,...props})=>(
  <span className={classNames("font-sans font-bold text-brand text-xs tracking-05 leading-15 ", className)} {...props}/>
);




export const MetaInfo = ({className,...props}) =>{
  const classes=classNames(
    "font-sans font-bold text-grey text-xs tracking-05 leading-15 ",
    className
  );
  return( <span className={classes} {...props}/>);
};

export const MetaInfoRegular = (props) =>(
  <span className="font-sans  text-grey-darkest text-xs tracking-05  " {...props}/>
);

//forms

export const InputLabel = (props) => (
  <label className="" {...props}/>
);
export const FormMessage = (props)=>(
  <p className="" {...props}/>
);

//Buttons labels
export const SmallButtonLabel = (props)=>{

  const className= classNames(
    'font-sans font-normal uppercase text-sm tracking-1 leading-17 text-grey-darkest',
    {
      'text-grey-darkest': !props.hover,
      'text-brand': props.hover
    }
  );
  const {hover, ...html_props} = props

  return (
    <span className={className} {...html_props}/>
  );
};
const LargeButtonLabel = (props)=>{

  const className= classNames(
    'font-sans font-normal uppercase text-sm tracking-1 leading-17 text-grey-darkest',
    {
      'text-grey-darkest': !props.hover,
      'text-white': props.hover
    }
  );
  const {hover, ...html_props} = props

  return (
    <span className={className} {...html_props}/>
  );
};
LargeButtonLabel.propTypes={hover:PropTypes.bool};

export {LargeButtonLabel};
//Menus
export const NavLabel = (props) => {
  const className = classNames(
    'font-sans tracking-wide uppercase leading-17 text-black-menu hov er:text-brand text-sm',
    {
      'font-light': !props.active,
      'font-bold': props.active
    }
  );
  return (
    <span className={className} {...props}/>
  );
};


export const NavDrawerLabel = (props) => (
  <span className="font-sans uppercase font-light text-grey-darkest  hover:text-brand text-2xs opacity-50 tracking-1 leading-16" {...props}/>
);

export const ToolbarNavLabel = (props) => (
  <span className="font-sans font-normal uppercase text-white text-xxs tracking-1 leading-12" {...props}/>
);
