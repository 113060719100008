import React, {Fragment} from 'react';
import MediaQuery from 'react-responsive';
import {graphql, useStaticQuery} from 'gatsby';
import {EventPreviewWithButton} from './previews';
import {AllEvents} from './list';
import {chunk, buildIndex} from '../../utils';

import {ReactComponent as ChevronRight} from '../../assets/icons/chevron-right.svg';
import {ReactComponent as ChevronLeft} from '../../assets/icons/chevron-left.svg';


class EventsFeed extends React.Component {
    constructor(props){
    super(props);
    this.state = {
      postsById: [],
      currentPage: 0,
      totalPages: 0,
      perPage:[],
      posts: [],
      cycle: true
    };
    this.perPage = 2;
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
      this.containerRef = React.createRef();
      this.maxItemWidth = 275;
  }


  componentDidMount(){
    if(this.containerRef.current){
      this.perPage = Math.min(4, Math.floor((this.containerRef.current.clientWidth)/this.maxItemWidth));
    }
    const chunked = chunk(this.props.posts.map(i=>i.id), this.perPage);
    const byId = buildIndex(this.props.posts);
    this.setState({
      posts: [...this.props.posts],
      postsById: byId,
      totalPages: chunked.length,
      perPage: [...chunked],
    });
    
  }
  prev(){
    let currentPage = this.state.currentPage;
    if(currentPage - 1 >=0 ){
      currentPage = currentPage -1;
    }else {
      currentPage = this.state.cycle?this.state.totalPages-1:Math.max(this.state.totalPages -1, 0);
    }
    this.setState({currentPage: currentPage});
  }
  next(){
    let currentPage = this.state.currentPage;
    if(currentPage + 1 >= this.state.totalPages ){
      currentPage = 0;
    }else {
      currentPage = currentPage +1;
    }
    this.setState({currentPage: currentPage});
  }
  render(){
    const slide = this.state.perPage.length ?this.state.perPage[this.state.currentPage].map(i=>this.state.posts[this.state.postsById[i]]): [];
    return(
      <div ref={this.containerRef} className="w-full">
        {this.state.totalPages > 1 && 
         <div style={{textAlign:"end"}}>
           <ChevronLeft onClick={this.prev} width={25} height={25} className="text-black fill-current flex-no-shrink mr-5 hover:cursor-pointer hover:text-brand" />
           <ChevronRight onClick={this.next} width={25} height={25} className="text-black fill-current flex-no-shrink ml-5 hover:cursor-pointer hover:text-brand"/>
         </div>
        }
      <div className="flex  flex-no-wrap justify-around bg-white" >
        {slide && slide.map(post => (
          <div className="flex-grow mx-5" key={`event-slide-${post.id}`}style={{maxWidth:this.maxItemWidth}}>
            <EventPreviewWithButton {...post}  />
          </div>
            ))}
      </div>
      </div>
    );
  }
}

export default  (props) => {
  const events = AllEvents();
  return(
    <Fragment>
      <MediaQuery minWidth={768}>
        <EventsFeed posts={[...events]}/>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        {events.slice(0,4).map(e=>(
          <EventPreviewWithButton key={e.path} {...e}/>
        ))}
      </MediaQuery>
    </Fragment>
  );
};
