import React, { useEffect } from "react";
import ContactBox from "./widgets/contactbox";
import TocWidget from "./widgets/toc";
import PlainHTMLWidget from "./widgets/plainhtml";
import ScrollArea from "react-scrollarea/src/js/ScrollAreaWithoutCss";

const widgetsMap = {
	ContactBoxWidget: ContactBox,
	TOCWidget: TocWidget,
	PlainHTMLWidget: PlainHTMLWidget,
};

// outer wrapper for asides
// the actual aside component (GrayAside for instance) is passed as prop to the Layout component, then the layout component passes is to the AsideComponent through the children prop

const AsideComponent = (props) => {
	return (
		<aside
			className="aside lg:border-l  border-grey-lighter lg:ml-4"
			style={{ width: 400, maxWidth: "100vw" }}
		>
			{props.children}
		</aside>
	);
};

export default AsideComponent;

const AsideContent = (props) => (
	<div className="w-full lg:max-w-sm bg-panel-secondary h-full px-15px pt-10">
		<div className="mx-auto">
			{props?.aside?.widgets?.map((w, w_id) => {
				const Component = widgetsMap.hasOwnProperty(w.kind)
					? widgetsMap[w.kind]
					: null;
				if (Component) {
					return (
						<div className="aside__widget mb-4" key={w_id}>
							<Component {...w} />
						</div>
					);
				}
			})}
		</div>
	</div>
);
const StickyAside = (props) => {
	return (
		<div className="sticky pin-t">
			<ScrollArea
				className={"scrollarea max-h-screen"}
				contentClassName={"scrollarea-content"}
				smoothScrolling
				speed={0.8}
				verticalContainerClassName={"scrollbar-container"}
				verticalContainerClassNameActive={"scrollbar-container--active"}
				verticalContainerClassNameHorizontal={"scrollbar-container--horizontal"}
				verticalContainerClassNameVertical={"scrollbar-container--vertical"}
				verticalScrollbarClassName={"scrollbar"}
				stopScrollPropagation={true}
			>
				<AsideContent {...props} />
			</ScrollArea>
		</div>
	);
};

export const GreyAside = (props) => {
	return (
		<div className="bg-panel-secondary relative h-full">
			{props?.aside?.options?.scroll_with_page ? (
				<AsideContent {...props} />
			) : (
				<StickyAside {...props} />
			)}
		</div>
	);
};

export const query = graphql`
	fragment AsideFragment on Aside {
		options {
			aside_type
			scroll_with_page
		}
		widgets {
			kind
			... on ContactBoxWidget {
				show_contact_box
				title
				text
				phone
				email
			}
			... on TOCWidget {
				title
				entries {
					href
					label
				}
			}
			... on PlainHTMLWidget {
				title
				data
			}
		}
	}
`;
