import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"
import RelatedCategories, {MobileRelatedCategories} from './index'
import MediaQuery from 'react-responsive'
export class wrapper extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query relatedCategories{
            allWordpressCategory(filter:{count:{gt:0}}){
                nodes {
                  id
                  wordpress_id
                  name
                  description
                  path
                  ancestors {
                    id
                  }
              }
          }
        }
        `}
        render={data => {
          let filteredData = [...data.allWordpressCategory.nodes];
          if(this.props.rootCategory){
            filteredData = filteredData.filter(item => item.ancestors.length==1 && item.ancestors.map(i=>i.id).includes(this.props.rootCategory.id))
          }
          if(this.props.exclude){
            const excluded_ids = this.props.exclude.map(i => i.id);
            filteredData = filteredData.filter(item => !excluded_ids.includes(item.id));
          }
          
          
         
          return (
            <>
            <MediaQuery minWidth={768}>
              <RelatedCategories title={this.props.title} categories={filteredData}/>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
              <MobileRelatedCategories title={this.props.title} categories={filteredData}/>
            </MediaQuery>
            </>
          )
        }}
      />
    )
  }
}

export default wrapper
