import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle, WidgetAside} from '../components/common';
import {CeficShare as SocialShare} from '../components/social-shares';

export default (props) =>{
  const data = props.data.wordpressWpSdg;
  return(
    <Layout pageContext={props.pageContext} aside={WidgetAside()} ancestors={data.ancestors}>
    <div style={{maxWidth:1025}} className="mx-0 px-0">
    <div style={{maxWidth: 734}} className="mx-0 px-0">
      <PageTitle title={data.title}/>
      <div className='flex my-8 flex-wrap'>
        {data.sdg_type.length > 0 && data.sdg_type.map((type)=>{
          return (
            <div style={{color: type.acf.color}} className='m-2 ml-0 flex items-center'>
              <span className="dot mr-2 " style={{backgroundColor: type.acf.color}}></span> {type.name}
            </div>

          )
        })}
      </div>

      <div className="wp_content sdg" dangerouslySetInnerHTML={{__html:data.content}} />
      <SocialShare className="mt-10" url={props.pageContext.yoast.canonical} context={props.pageContext.yoast} />
    </div>
      </div>
    </Layout>
  );
}
export const defaultSdgQuery = graphql`
query DefaultSdgById($id: String!) {
  wordpressWpSdg(id:{eq:$id}){
    title
    slug
    wordpress_id
    content
    sdg_type {
      name
      acf {
       color
      }
    }
    ancestors {
      name
      slug
      path
    }
  }


}
`;
