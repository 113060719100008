import React from 'react';
import {PBody, SubSubTitle} from '../../typography';
import classnames from "classnames";


const ContactBox = React.forwardRef((props, ref) => {
  const className = classnames(
    "contact-box",
    "p-15px",
    "border",
    props.className
  );
  return (
    <div className={className}>
      {props.title &&
       <p dangerouslySetInnerHTML={{__html:props.title}} className="font-sans font-light text-lg uppercase tracking-02 leading-22 mb-3 contact-box__title"/>
      }
      <div className="contact-box__content">
        {props.text &&
         <PBody dangerouslySetInnerHTML={{__html:props.text}} className="contact-box__text"/>
        }

        {props.phone &&
         <a href={"tel:"+props.phone} className="contact-box__phone" ><PBody className="mb-0 text-black" dangerouslySetInnerHTML={{__html:props.phone}}/></a>
        }

        {props.email &&
         <PBody className="contact-box__email" dangerouslySetInnerHTML={{__html:props.email}}/>
        }
      </div>
    </div>
  );
});

export default ContactBox;
