import React from 'react';
import {
  StaticQuery,
  graphql
} from 'gatsby';
import Img from 'gatsby-image';
import {
  AddressWidget
} from './widgets';
import {
  FooterMenu
} from './menu';
import BottomToolbar from './bottom-toolbar';
import SocialLinks from './widgets/social_links';
import SubscribeToNewsletter from './widgets/mailchimp';
import NewsAppFooterWidget from './NewsApp/footerWidget';
const Footer = (props) => {
  const linked_website = props.options.main_footer.linked_website;
  return (
    <footer className="page-footer ">
      <div className="bg-panel-secondary p-5 pt-10">
        <div className=" page-footer__widgets container mx-auto lg:flex lg:justify-between justify-center items-center lg:items-start">
          <div className="mb-5 lg:mb-0">
            <AddressWidget/>
            {linked_website && linked_website.length &&
             <div className="flex justify-center lg:justify-between">
               {linked_website.map((website, index) => (
                 <a key={index} href={website.link.url} target={(website.link.target?website.link.target:'_system')} className='my-1 mx-4 lg:mx-1'>
                   <Img fixed={website.logo.localFile.childImageSharp.fixed}/>
                 </a>
               ))}
             </div>
            }
          </div>
          <div className="mb-5 text-center lg:text-left">
            { props?.options?.main_footer?.footer_menu_title &&
            <p className="font-medium uppercase mb-2 ">{props?.options?.main_footer?.footer_menu_title}</p>
            }
            <FooterMenu/>
          </div>
          <div className="text-center lg:text-left mb-5">
            { props?.options?.main_footer?.footer_news_app_title &&
              <p className="font-bold text-brand uppercase mb-4 ">{props?.options?.main_footer?.footer_news_app_title}</p>
            }
            <NewsAppFooterWidget/>
          </div>
          <div className="mb-5">
            <div className="flex flex-row items-top justify-between mb-5">
              <p className="uppercase font-medium  mb-2 lg:mb-0">{props.options.main_footer.footer_social_title}</p>
              <SocialLinks />
            </div>

            <div className="text-center" style={{maxWidth:460}}>
              <SubscribeToNewsletter id="newsletter-footer"/>
            </div>
          </div>
        </div>
      </div>
      <BottomToolbar {...props.options.bottom_toolbar}/>
    </footer>
  );
};

export default () => (
  <StaticQuery query= {
    graphql `
query FooterOptions{
    wordpressAcfOptions{
    options {
      bottom_toolbar{
        before_menu
      }
      main_footer {
        footer_social_title
        footer_menu_title
        footer_news_app_title
        linked_website {
          logo {
            localFile{
             childImageSharp {
                 fixed(height:32){
                    ...GatsbyImageSharpFixed
                 }
             }
            }
          }
          link {
            title
            url
            target
          }
        }
      }
      corporate_name
      postal_address
      logo_tag_line
    }
  }}
`
  } render={data =>(<Footer {...data.wordpressAcfOptions}/>)
}
/>
);
