import React from 'react';

const PlainHTMLWidget = ( props ) =>
      {
        return (	<div dangerouslySetInnerHTML={{__html: props.data}} />)
      }

;

export default PlainHTMLWidget;
