import {defaultPreprocessor} from './common';


export const PostTemplateHandler = (data) => {
	// transform content from default.js template


	const el = defaultPreprocessor(data);

	// remove ToC if present
	const Highlights = el.querySelectorAll('.wp-block-cefic-cefic-hightlights-block');

	Highlights.forEach(block => {

		const parent= block.closest('.wp-block-columns');
		if(parent){
			block = block.cloneNode(true);
			parent.replaceWith(block)
		}else {

		};
		const Toc =block.querySelector('.wp-block-column.highlight_rightpanel');
		Toc?.remove();
		const panel = block.querySelector('.wp-block-column.highlight_leftpanel');
		panel?.classList?.add('print-override');

	})
//
	return el;

};
