import React from 'react';
import Layout from '../components/layout';
import {CommitteePage} from '../templates/committee';
import {PageTitle, WidgetAside} from '../components/common';
import classnames from 'classnames';
import {graphql, navigate} from 'gatsby';
import MediaQuery from 'react-responsive';
import {connect} from 'react-redux';
import {CeficShare as SocialShare} from '../components/social-shares';

export const Box = (props) => {
  const className = classnames(
    'organisation-box',
    'border p-5 mb-5 mx-4 flex-1 flex flex-col',
    {
      'text-white cursor-pointer':props.hover,
      'text-left': props.textLeft,
      'justify-start': props.alignTop,
      'justify-center': !props.alignTop,
      'border-brand': !props.borderColor,
      'bg-brand': (!props.borderColor && props.hover),
      'bg-grey-lightest': props.active
    }
  );
  let style = { };
  if(props.borderColor){
    style = {...style, borderColor:props.borderColor};
  }
  if(props.hover){
    style = {...style, backgroundColor:props.borderColor};
  }

  return(
    <div className={className} style={style} onClick={props.clickHandler} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      {props.children}
    </div>
  );
};

export const BoxRow = (props) => {
  const max_width=props.single?360:760;
  return (
    <div className="container" style={{maxWidth:max_width}}>
      <div className="flex flex-wrap justify-around">
        {props.children}
      </div>
    </div>
  );
};

export class OrgChartBox extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover: false,
    };
  }
  render(){

  return (
    <Box
      onMouseEnter={e=>this.setState({hover:true})}
      onMouseLeave={e=>this.setState({hover:false})}
      borderColor={this.props.acf.box_options.border_color}
      textLeft={this.props.acf.box_options.inline_subcommittees}
      alignTop={this.props.acf.box_options.inline_subcommittees}
      clickHandler={e=>this.props.clickHandler(this.props)}
      hover={this.state.hover}
      active={this.props.active}
    >
      <p className='uppercase mb-2' dangerouslySetInnerHTML={{__html:this.props.title}}/>
      {this.props.acf.box_options.inline_subcommittees && this.props.sub_committees && this.props.sub_committees.length > 0 &&
       this.props.sub_committees.sort((a,b)=>a.menu_order - b.menu_order).map(sc => (
         <span key={sc.slug} className="text-left" dangerouslySetInnerHTML={{__html:" - "+ sc.title}}/>
       ))
      }
    </Box>
  );
  }
}
const mapStateToProps = ({panel})=>{
  return {panel};
};

const mapDispatchToProps = dispatch => {
  return {
    setPanelContent: (content, context) => dispatch({type:`SET_PANEL_CONTENT`, content:content, context:context }),
    openPanel: () => dispatch({type:`OPEN_PANEL`})
  };
};

export class OrgChart  extends React.Component {
  constructor(props){
    super(props);
    this.state={
      activeBoxSlug: undefined
    };
    this.onClickBox = this.onClickBox.bind(this);
  }

  onClickBox(box, open_in_panel=false){

    this.setState(
      {activeBoxSlug: box.slug}
      ,(state)=>{
        if(!open_in_panel){
          navigate(box.path);
        }else {
          this.props.setPanelContent(CommitteePage(box));
          this.props.openPanel();
        }
      }
    );
  }
  render(){
    return (
      <div className="text-center">
        {this.props.layout.map(
          (row, id) =>
            <BoxRow key={'orgchart-'+id} single={this.props.panel.isOpen || row.committees.length===1}>
              {row.committees
               .map(c=>
                    <MediaQuery minWidth={1200} key={c.slug} >
                      {(matches) => {
                        if(matches){
                          return <OrgChartBox key={c.slug} {...c} clickHandler={(box)=>this.onClickBox(c, true)}  active={this.state.activeBoxSlug===c.slug} />;

                        }else {
                          return <OrgChartBox key={c.slug} {...c} clickHandler={this.onClickBox}  active={this.state.activeBoxSlug===c.slug}/>;

                        }
                      }}
                    </MediaQuery>
                   )
              }
            </BoxRow>
        )}
      </div>
    );
  }
}
export const ConnectedOrgChart = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgChart);

export default (props)=>{
  return (
    <Layout pageContext={props.pageContext} aside={WidgetAside({contactBox:props.data.wordpressPage.acf.contact_box})} ancestors={props.data.wordpressPage.ancestors}>
      <div className="container px-0 mx-0">
        <PageTitle title={props.data.wordpressPage.title}/>
        <ConnectedOrgChart layout={props.data.wordpressPage.acf.layout.rows}/>
    {props.data.wordpressPage.display_sharing_toolbar &&
     <SocialShare className="mt-10" url={props.data.wordpressPage.path} context={props.pageContext.yoast} />
        }

      </div>
    </Layout>
  );
}
export const OrganisationChartQuery = graphql`
query OrganisationChartPage($id: String!) {
  allWordpressWpCommittee {
    edges {
      node {
        title
        content
      }
    }
  }
  wordpressPage(id: {eq: $id}) {
    title
    content
    template
    path
    display_sharing_toolbar

    ancestors {
      slug
      name:title
    }
    coms {
      wordpress_id
      title
      slug
      content
    }
    acf {
       contact_box{
          show_contact_box
          title
          text
          phone
          email
        }
      layout {
        rows {
          committees {
            id
            title
            slug
            path
            content
            wordpress_parent
            yoast: yoast_head
            member_groups {
              name
              members {
                id
                title
                slug
                featured_media{
                  localFile {
                    childImageSharp{
                      fixed(width: 90, height: 100, quality: 100){
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }

                acf{
                  company
                  position
                  other_titles
                  website {
                     title
                     url
                     target
                  }
                }
              }
            }
            sub_committees {
              wordpress_id
              title
              slug
              menu_order
              chairman {
                title
                acf {
                  company
                }
              }
              executive_director {
                title
                acf {
                  company
                }
              }
            }
            acf {
              template_options {
                use_content_from {
                  content
                  template
                }
                show_sub_committees
                show_members
                show_members_picture
                activate_member_profile_pages
                show_members_website
              }
              box_options {
                border_color
                inline_subcommittees
              }
            }
          }
        }
      }
    }
  }
}

`
