import React from 'react';
import ReactDom from 'react-dom';
import SecondaryPanel from '../panels';
export class ModalRoot extends React.Component {
  render(){
    return (
      <div id="modal-root" className="z-20">
      </div>
    );
  }
}


export class Modal extends React.Component {
  modalWrapper(){
    return(
      <div className="bg-panel-secondary absolute pin-y pin-r w-1/3">
          {this.props.children}
      </div>
    );
  }
  render(){
    return ReactDom.createPortal(
      this.modalWrapper(),
      document.getElementById('modal-root')
    );
  }
}
