import React from 'react';
import {
  ToolbarTopMenu
} from './menu';
export default () => (
  <div className="top-toolbar bg-grey-dark text-white text-2xs">
    <div className="container p-0 mx-auto flex flex-col lg:flex-row lg:justify-end">
      <ToolbarTopMenu/>
    </div>
  </div>
)
