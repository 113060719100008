import React from 'react';
import {Link} from '../../link';
import {navigate} from 'gatsby';
import {SubTitle, PBody, MetaInfo } from '../../typography';
import {ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';
import Location from '../parts/location';
import EventTimes from '../parts/time';
export const MobileEventPreview = (props) =>(
  <Link to={props.path} className="text-black">
  <article className="py-5">
    <div>
      <EventTimes {...props} />
      <SubTitle dangerouslySetInnerHTML={{__html:props.title}}/>
    </div>
    <div className="flex justify-between">
      <Location location={props.location}/>
      <ChevronRight width={25} height={25} className="fill-current text-grey ml-auto"/>
    </div>
  </article>
  </Link>
);
