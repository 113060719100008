import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

const LogoShortQuery = 
  graphql`
query {
  wordpressAcfOptions{
    options{
      logo_short {
        id
        title
        localFile{
          childImageSharp{
            fixed(width:43, height:43) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
}`;



export const LogoShort = () => (
  <StaticQuery
    query={LogoShortQuery}
    render={data=>{
      return (<Img fixed={data.wordpressAcfOptions.options.logo_short.localFile.childImageSharp.fixed}/>)
    }}
  />
);

