import React from 'react';
import {HorizontalPreview} from './previews';

export const LibraryItemsList = (props) => {
  return (
    <div>
    {props.items && props.items.length>0 &&
     props.items.map(i=><HorizontalPreview {...i} showThumbnail={true} key={i.slug}/>)

    }
    </div>
  );
};
