import React from 'react'
import {HTMLField} from './htmlfield'
import {InputField} from './input'
import {SelectInput} from './select'
import {SubmitButton} from './submit'
import {TextAreaInput} from './textarea'


export default  (props) =>{
  if (props.type === 'textarea'){
    return <TextAreaInput {...props}/>
  }else if (props.type === "select"){
    return <SelectInput {...props} />
  } else if (props.type==="html"){
    return <HTMLField {...props}/>
  }
  return  <InputField {...props}/>
}

export {HTMLField}
export {InputField}
export {SelectInput}
export {SubmitButton}
export {TextAreaInput}
