import React from 'react'
import {LargeButtonLabel} from '../../typography';
import classnames from 'classnames';

export class SubmitButton extends React.Component{
  constructor (props) {
    super(props);
    this.state = {
      hover: false
    };
  }
  render(){
    const className= classnames(
      'border p-button text-center align-bottom',
      {
        'border-grey-darkest': !this.state.hover,
        'border-brand bg-brand': this.state.hover && !this.props.disabled,
        'border-grey-light': this.props.disabled
      },
      this.props.className
    );
    const labelClassName= classnames({
      'text-black': this.state.hover && !this.props.disabled,
      'text-grey-light': this.props.disabled
    })
    return (
      <button
        className={className}
        onClick={this.props.onClickHandler}
        onMouseEnter={e=>{this.setState({hover:true});}}
        onMouseLeave={e=>{this.setState({hover:false});}}
        type='submit'
        value="Submit"
        disabled={this.props.disabled?this.props.disabled:false}
      >
        <LargeButtonLabel hover={this.state.hover} className={labelClassName} dangerouslySetInnerHTML={{__html:this.props.label}} />
      </button>
    );
  }
}

