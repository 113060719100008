import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import {Link} from '../link';
import {SlideDown} from 'react-slidedown';
import {debounce} from 'lodash';
import 'react-slidedown/lib/slidedown.css';



const mainNavQuery = graphql`
query mainNavQuery {
menu: allWordpressMenusMenusItems(filter:{slug:{eq:"main"}}){
  edges{
    node{
      items{
        wordpress_id
        title
        type:object
        url
        slug
        parent_id: menu_item_parent
        order:menu_order
        description
        classes
      }
    }
  }
}}
`;


const MenuItem = (props) => {
  return(
    <li onMouseEnter={props.onHoverHandler} className={`mr-6 hover:border-b-2 border-brand h-full flex items-center hover:text-brand text-black ${props.classes}`}>
      <Link className="menu page_menu_item_link uppercase no-underline text-2xs tracking-wide font-thin" to={props.slug} target={props.target} style={{color:'inherit'}} dangerouslySetInnerHTML={{__html:props.title}}/>
    </li>
  );
};

export class MainMenu extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showSubmenu: false,
      submenu: []
    };
    this.openSubmenu = this.openSubmenu.bind(this);
  }

  openSubmenu(menu, event) {
    this.setState({...this.state, showSubmenu:true, submenu:menu});
  }
  buildSubmenu(current_item, all_items){
    const menu= {
      title: current_item.title,
      description: current_item.description,
      items: all_items.filter(item=>item.parent_id===current_item.wordpress_id.toString()).sort((a,b)=>a.order - b.order)
    }
    return menu
  }
  render() {
    return (
      <StaticQuery
        query={mainNavQuery}

        render={data=> {
  const menu = data.menu.edges[0].node;
  return(
    <nav id="main-nav" className="main-navbar" onMouseLeave={(e)=>{this.setState({...this.state, showSubmenu:false});}}>
      <ul className="list-reset flex h-full">
        {menu.items.filter(item=>item.parent_id==="0").map((item, index)=> (
          <MenuItem {...item} onHoverHandler={(e)=>this.openSubmenu(
            this.buildSubmenu(item, menu.items)
            , e)} key={index}/>
        ))}
      </ul>
      <SlideDown className="my-dropdown-slidedown absolute pin-x z-20">
        {this.state.showSubmenu? <SubMenu parent_menu={this.state.submenu}/>:null}
      </SlideDown>
    </nav>
  );
}}/>
    );
  }
}


class SubMenuItem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      title: props.title,
      excerpt: false
    };
  }
  render (){
    return(
      <li onMouseEnter={this.props.onMouseEnterHandler} onMouseLeave={this.props.onMouseLeaveHandler} className="mb-3"><Link to={this.props.slug} className={`uppercase no-underline text-black-menu hover:text-brand ${this.props.classes}`} dangerouslySetInnerHTML={{__html:this.props.title}}/></li>
    );
  }
}

class SubMenu extends React.Component {
  constructor (props){
    super(props);
    this.state = {
      title: undefined,
      content: undefined
    };

    this.updateDescription = this.updateDescription.bind(this);
    this._updateDescription = this._updateDescription.bind(this);
    this.delayedUpdateCb = debounce(this._updateDescription, 100);
  }

  _updateDescription(title, description, e){
    description = description?description:undefined;
    this.setState({...this.state, title: title, content:description});
  }
  updateDescription(title, description, e) {
    e.persist();
    this.delayedUpdateCb(title, description, e);
  }
  render(){
    const items = this.props.parent_menu.items;
    const title = this.state.title?this.state.title:this.props.parent_menu.title;
    const content = this.state.content?this.state.content:this.props.parent_menu.description;
    return(
      <div className="bg-white border-b-black shadow-lg w-full  py-6">
        <div className="container mx-auto flex justify-between">
          <div className="submenu-description text-grey-dark max-w-sm">
            <p className="text-3xl mb-3 uppercase" dangerouslySetInnerHTML={{__html:title}}/>
      <div className="submenu__description__excerpt" dangerouslySetInnerHTML={{__html:content}} />
          </div>
          <nav className="submenu__menu">
            {items &&
             <ul className="list-reset">
               {items.map( (item,index)=>(
                 <SubMenuItem {...item} key={index} onMouseEnterHandler={(e)=>this.updateDescription(item.title, item.description, e)} onMouseLeaveHandler={(e)=>this.updateDescription(undefined, undefined, e)}/>
               ))
               }
             </ul>
            }
          </nav>
        </div>
      </div>
    );
  };
}
