import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle, WidgetAside} from '../components/common';
import Event from '../components/events/detail';
import {CeficShare as SocialShare} from '../components/social-shares';
export default (props) =>{
  const data = props.data.wordpressWpEvent;
  return(
    <Layout pageContext={props.pageContext} aside={WidgetAside()} ancestors={data.ancestors}>
      <div style={{maxWidth:1025}} className="mx-0 px-0">
        <div style={{maxWidth: 734}} className="mx-0 px-0">
          <Event {...data} context={props.PageContext} />
          <SocialShare  url={data.path} context={props.pageContext.yoast} className="my-10"/>
        </div>
      </div>
    </Layout>

  );
}
export const defaultEventQuery = graphql`
query DefaultEventById($id: String!) {
  wordpressWpEvent(id:{eq:$id}){
    title
    slug
    path
    wordpress_id
    content
    date(formatString: "MMMM Do YYYY")
    time
    end_date(formatString: "MMMM Do YYYY")
    end_time
    location
     ancestors {
      name
      slug
      path
   }
  }
}
`;
