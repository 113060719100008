import React from 'react';
import Moment from 'react-moment';
import {navigate} from 'gatsby';
import Img from 'gatsby-image';
import {SubTitle, PBody, MetaInfo, NewsTag } from '../../typography';
import {ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';


export const MobilePostPreview = (props) =>(
  <article onClick={e=>navigate(props.path)} className="mb-5">
    {!!props.featured_media &&
     <div className="mb-3">
       <Img fluid={props.featured_media.localFile.banner.fluid}/>
     </div>
    }
    <div>
      <SubTitle dangerouslySetInnerHTML={{__html:props.title}}/>
  <hr className="border-t-4 border-brand w-7 ml-0 my-7"/>
      {props.tags ? <NewsTag>{props.tags[0].name}</NewsTag> : ''}
      <PBody dangerouslySetInnerHTML={{__html:props.excerpt}}/>
    </div>
    <div className="flex justify-between">
      {!!props.date && 
       <MetaInfo><Moment format="DD/MM/YYYY">{props.date}</Moment></MetaInfo>
      }
      {!!!props.hide_chevron &&
       <ChevronRight width={25} height={25} className="fill-current text-grey"/>
      }
    </div>
  </article>
);
