import {graphql} from 'gatsby';
import React from 'react';
import Layout from '../components/layout.js';
import {PageTitle, WidgetAside} from '../components/common';
import {LibraryItemsSearchForm} from '../components/search';
import {LibraryItemsList} from '../components/library/list';
import debounce from 'lodash.debounce';
import {CeficShare as SocialShare} from '../components/social-shares';

import {GreyAside} from '../components/aside';


export class LibraryItemsFeed extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      items: [],
      byId: {},
      query: {},
      isLoading: false,
    };
    this.filterItems = this.filterItems.bind(this);
    this.endpoint =  `https://${process.env.GATSBY_WP_URL}/wp-json/wp/v2/library-item`;
    this.dropRemoteSearch = false;
  }

  doRemoteSearch = debounce((query) => {
    let searchArgs = {'per_page': [100]};
    if(query.textSearch && query.textSearch !== ''){
      searchArgs['search']= [query.textSearch];
    }
    if(query.filters && query.filters.hasOwnProperty('topics') && query.filters.topics.length){
      searchArgs['topics']= query.filters.topics;
    }
    if(query.filters && query.filters.hasOwnProperty('contentTypes') && query.filters.contentTypes.length){
      searchArgs['content-type']= query.filters.contentTypes;
    }
    const searchArgsSerialized = [];
    for(let k in searchArgs){
      if(searchArgs[k] && searchArgs[k].length)
        searchArgsSerialized.push(`${k}=${searchArgs[k].join(',')}`);
    }
    const params=searchArgsSerialized.join('&');
    this.setState(
      {isLoading: true},
      ()=>fetch(`${this.endpoint}?${params}`)
        .then(response=>response.json())
        .then(data => {
          const matches =  data.map(d=>d.id);
          const items = this.props.items.filter(e=>matches.includes(e.wordpress_id) );
          if(!this.dropRemoteSearch){
            this.setState({
              items: [...items]
            });
          }
        })
    );},250);

  doLocalSearch(query){
    //filter against categories locally
    let items = [...this.props.items];
    //apply topic filters
    if(query.filters.topics && query.filters.topics.length ){
      items = items.filter(i=>{
        const matches = query.filters.topics.filter(t=> i.topics.includes(t));
        return matches.length;
      });
    }
    //apply contentType filters
    if(query.filters.contentTypes && query.filters.contentTypes.length ){
      items = items.filter(i=>{
        const matches = query.filters.contentTypes.filter(t=> i.content_type.includes(t));
        return matches.length;
      });
    }
    return items;
  }
  filterItems(query){
    this.dropRemoteSearch=true;
    let items = [];
    // trigger a remote search if text changed
    if(query.textSearch!=='' && query.textSearch !== this.state.query.textSearch){
      this.dropRemoteSearch = false;
      items = this.doRemoteSearch(query) || [];
    }else {
      items = this.doLocalSearch(query);
    }
    return this.setState({
      items:[...items],
      query: query
    });
  }
  componentWillMount(){
    this.setState({
      items: this.props.items
    });
  }
  render(){

    return (
      <div>
        <div className="mb-5">
          <LibraryItemsSearchForm settings={this.props.searchSettings} onUpdateQuery={query=>this.filterItems(query)}/>
        </div>
        <LibraryItemsList items={[...this.state.items]}/>
      </div>
    );
  }
}

export default (props)=>{
  const page = props.data.page;
  const libraryItems = props.data.libraryItems.edges
        .map(e=>e.node)
         //filter collection by selected content type if any
        .filter(e=>{
          const ct_filters = page.acf.content_types;
          if(!ct_filters || ct_filters.length === 0){
            return true;
          }
          for(const ct of ct_filters){
            if(e.content_type.includes(ct)){
              return true;
            }
          }
          return false;
        })
        .filter(e=>{
          //filter collection by topics if any
          const topics_filters = page.acf.archive_topics;
          if(!topics_filters || topics_filters.length === 0) {
            return true;
          }
          for(const topic of topics_filters){
            if(e.topics.includes(topic)){
              return true;
            }
          }
          return false;
        });

  ;
  let libraryListSettings = {
    searchControl: {
      showTextSearch: page.acf.search_form.show_text_search,
      showFilters: page.acf.search_form.search_filters_settings.show_filters,
    },
    filtersSettings: {
      taxonomies: page.acf.search_form.search_filters_settings.show_taxonomies,
      restrict_topics_to: page.acf.archive_topics?page.acf.archive_topics:[],
      restrict_content_type_to: page.acf.content_types?page.acf.content_types:[]
    }
  };

  let defaultSearchArgs = {};
  if(page.acf.content_types){
    defaultSearchArgs['content-type']=page.acf.content_types;
  }
  if(page.acf.archive_topics){
    defaultSearchArgs['topics']=page.acf.archive_topics;
  }

  return(
    <Layout pageContext={props.pageContext}  aside={GreyAside({aside:page.aside})}  ancestors={page.ancestors}>
    <div style={{maxWidth:968}} className="container mx-0 px-0">
    <div style={{maxWidth: 910}} className="container mx-0 px-0">
      <PageTitle title={page.title} />
    <div className="wp_content mb-12" dangerouslySetInnerHTML={{__html: page.content}}/>
    </div>
      <LibraryItemsFeed
        items={libraryItems}
        searchSettings={libraryListSettings}
      />
      {page.display_sharing_toolbar &&
        <SocialShare  url={page.path} context={props.pageContext.yoast} className="my-10"/>
      }
    </div>

  </Layout>
  );
}



export const LibraryPage = graphql`
query libraryArchivePage3($id:String!){
  page:wordpressPage(id:{eq:$id}){
    title
    content
    path
    ancestors {
      slug
      path
      name:title
    }
    display_sharing_toolbar
 aside {
     ...AsideFragment
    }

    acf {
       archive_topics
       content_types
       search_form {
        show_text_search
        search_filters_settings {
          show_filters
          show_taxonomies
        }
      }
    }
  }
  libraryItems:allWordpressWpLibraryItem(sort:{fields:date, order:DESC}){
  edges{
    node{
      title
      excerpt
      content_type
      topics
      slug
      path
      date
      wordpress_id
      default_image {
       localFile{
        childImageSharp{
          fixed(width:97, height:138){
            ...GatsbyImageSharpFixed
          }
        }
      }

      }
      acf {
       document_type
       external_link
       file {
         mime_type
         alt
       }
      }
      featured_media {
        localFile {
          smallHThumb: childImageSharp{
             fixed(width:97, height:138){
                 ...GatsbyImageSharpFixed
             }
          }
        }
      }
    }
  }
}
}
`
