import React from 'react'
import {ConnectedHive} from './Hive';
import MediaQuery from 'react-responsive';

import './Beehive.css'

export const Beehive = ({data, typesColors, goalImages}) => {
  return (
    <div>
      {/*Desktop */}
      <MediaQuery minWidth={1300} >
        <div className='beehive-container mt-8' style={{height: Math.ceil(data.length/3)*411 + 151}}>
          {data.map((item, index) => {
            let b = false;
            let br = false;
            let tr = false;
            let style = {
              position: 'absolute',
              top: 302 * Math.floor(index/3)
            }
            if(index%3 === 0) {
              br = true;
              tr = true;
              style.left = 0
            }

            if(index%3 === 1) {
              style.right = 0
            }
            if(index%3 === 2) {
              style.left = 260
              style.top +=151;
            }
            if(index === 1) {
              tr = true
            }
            
            if(index + 3 >= data.length) {
              b = true;
            }
            if(index === data.length-1) {
              br=true;
            }
            return (
              <ConnectedHive 
                b={b} br={br} tr={tr} 
                style={style} 
                item={item}
                goalImages={goalImages}
                typesColors={typesColors}
              />
            )
          })}
        </div>
      </MediaQuery>
      {/*Tablet */}
      <MediaQuery maxWidth={1300} minWidth={768}>
        <div className='beehive-container mt-8' style={{height: Math.ceil(data.length/2)*411 + 151, width:619}}>
          {data.map((item, index) => {
            let b = false;
            let br = false;
            let tr = false;
            let style = {
              position: 'absolute',
              top: 302 * Math.floor(index/2)
            }
            if(index%2 === 0) {
              br = true;
              tr = true;
              style.left = 0
            }

            if(index%2 === 1) {
              style.left = 260
              style.top +=151;
            }

            
            if(index + 2 >= data.length) {
              b = true;
            }

            if(index === data.length-1) {
              br=true;
            }
            return (
              <ConnectedHive 
                b={b} br={br} tr={tr} 
                style={style} 
                item={item}
                goalImages={goalImages}
                external={true}
                typesColors={typesColors}
              />
            )
          })}
        </div>
      </MediaQuery>
      {/*Mobile */}
      <MediaQuery maxWidth={767} >
        <div className='beehive-container mt-8' style={{height: data.length*411, width: 359}}>
          {data.map((item, index) => {
            let b = false;
            let br = false;
            let tr = false;
            let style = {
              position: 'absolute',
              top: 302 * index
            }
            br = true;
            tr = true;
            style.left = 0
            

            if(index === (data.length-1)) {
              b=true;
            }
            return (
              <ConnectedHive 
                b={b} br={br} tr={tr} 
                style={style} 
                item={item}
                goalImages={goalImages}
                external={true}
                typesColors={typesColors}
              />
            )
          })}
        </div>
      </MediaQuery>
    </div>
  )
}
