import React, {Fragment} from 'react';
import {
  PageTitle
} from '../common';
import FlexibleContent from '../../components/flexible-builder'
import {NewsTag} from '../typography';



class Post extends React.Component {
  constructor(props){
    super(props);
  }
  render(){
    return(
    <Fragment>
      <PageTitle title={this.props.title}>
        {this.props.showTags &&
						<NewsTag className="mt-2" dangerouslySetInnerHTML={{ __html: this.props.tags.join(', ') }} />
        }
        </PageTitle>

      <div className="wp_content" dangerouslySetInnerHTML={{__html:this.props.content}}/>
      {!!this.props.widgets?.widgets?.length> 0 &&
       <FlexibleContent data={this.props.widgets.widgets}/>
      }
    </Fragment>
    );
  }
}

export default Post;
