// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-archive-event-js": () => import("./../../../src/templates/archive-event.js" /* webpackChunkName: "component---src-templates-archive-event-js" */),
  "component---src-templates-archive-jobs-js": () => import("./../../../src/templates/archive-jobs.js" /* webpackChunkName: "component---src-templates-archive-jobs-js" */),
  "component---src-templates-archive-news-js": () => import("./../../../src/templates/archive-news.js" /* webpackChunkName: "component---src-templates-archive-news-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-chemical-recycling-post-js": () => import("./../../../src/templates/chemical-recycling-post.js" /* webpackChunkName: "component---src-templates-chemical-recycling-post-js" */),
  "component---src-templates-committee-js": () => import("./../../../src/templates/committee.js" /* webpackChunkName: "component---src-templates-committee-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-rcaward-post-js": () => import("./../../../src/templates/rcaward-post.js" /* webpackChunkName: "component---src-templates-rcaward-post-js" */),
  "component---src-templates-sdg-js": () => import("./../../../src/templates/sdg.js" /* webpackChunkName: "component---src-templates-sdg-js" */),
  "component---src-templates-template-beehive-js": () => import("./../../../src/templates/template-beehive.js" /* webpackChunkName: "component---src-templates-template-beehive-js" */),
  "component---src-templates-template-ccan-category-grid-js": () => import("./../../../src/templates/template-ccan-category-grid.js" /* webpackChunkName: "component---src-templates-template-ccan-category-grid-js" */),
  "component---src-templates-template-interactive-map-detail-js": () => import("./../../../src/templates/template-interactive-map-detail.js" /* webpackChunkName: "component---src-templates-template-interactive-map-detail-js" */),
  "component---src-templates-template-interactive-map-js": () => import("./../../../src/templates/template-interactive-map.js" /* webpackChunkName: "component---src-templates-template-interactive-map-js" */),
  "component---src-templates-template-library-js": () => import("./../../../src/templates/template-library.js" /* webpackChunkName: "component---src-templates-template-library-js" */),
  "component---src-templates-template-media-corner-js": () => import("./../../../src/templates/template-media-corner.js" /* webpackChunkName: "component---src-templates-template-media-corner-js" */),
  "component---src-templates-template-organisation-chart-js": () => import("./../../../src/templates/template-organisation-chart.js" /* webpackChunkName: "component---src-templates-template-organisation-chart-js" */),
  "component---src-templates-template-rcawards-js": () => import("./../../../src/templates/template-rcawards.js" /* webpackChunkName: "component---src-templates-template-rcawards-js" */),
  "component---src-templates-template-recycling-category-grid-js": () => import("./../../../src/templates/template-recycling-category-grid.js" /* webpackChunkName: "component---src-templates-template-recycling-category-grid-js" */),
  "component---src-templates-template-routing-js": () => import("./../../../src/templates/template-routing.js" /* webpackChunkName: "component---src-templates-template-routing-js" */)
}

