import React from 'react';
import moment from 'moment';
const dateFormat = 'MMMM D, YYYY';
export const getTimesString = (date, time, end_date, end_time, separator, hideTimes) => {

  const  start_month= moment(date, dateFormat).month();
  const end_month = end_date?moment(end_date, dateFormat).month():false;
  if(!separator){
    separator='\u2014';
  }
  if(end_month && start_month === end_month){
    const date_obj = moment(date, dateFormat);
    return `${date_obj.format('MMMM')} ${date_obj.format('D')}-${moment(end_date, dateFormat).format('D')}, ${date_obj.format('YYYY')}`;
  }
  return `${date} ${time && !hideTimes?time:''} ${end_date?separator+' '+ end_date:''} ${end_date && end_time && !hideTimes?end_time:''}`;
};
  


export default({date, time, end_date, end_time, separator, ...props}) => (
  <p className="text-sm uppercase" dangerouslySetInnerHTML={{__html:getTimesString(date,time, end_date, end_time, separator,props.hideTimes?true:false)}}/>
)
