import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle, WidgetAside} from '../components/common';
import SupportingDocuments from '../components/library/supporting_documents';
import {CeficShare as SocialShare} from '../components/social-shares';
import FlexibleContent from '../components/flexible-builder';
import {withFilteredList} from '../components/posts/providers';
import {GridPostsList} from '../components/posts/list';


const initTaxonomy = (nodes, matchKey, order, placeholder) => {
  const taxonomy = nodes[0].taxonomy.slug;
  const terms  = nodes.map(n => {return {
    value: n.id,
    label: n.name
  }});
  return  {
    taxonomy: taxonomy,
    matchKey: matchKey,
    placeholder: placeholder,
    selected_term: null,
    order: order,
    terms: terms,
    display_filter: true
  };
}
export default ({data, pageContext}) =>{
  const acf = data.wordpressPage.acf;
  const contactBox = acf && acf.contact_box && acf.contact_box.show_contact_box?acf.contact_box:null;
  const grid_settings = data.wordpressPage.chemical_recycling_grid_settings;
  let filters =  [];
  if(grid_settings.company_filter.display && data.companies?.nodes?.length > 0){
    filters.push(initTaxonomy(data.companies.nodes, 'companies', 1, grid_settings.company_filter.placeholder));
  }
  if(grid_settings.category_filter.display && data.categories?.nodes?.length > 0){
    filters.push(initTaxonomy(data.categories.nodes.filter(n=>!n.exclude_from_main_archive), 'categories', 0, grid_settings.category_filter.placeholder));
  }

  const FilteredGridList = withFilteredList(
    GridPostsList
  );

  return(
    <Layout pageContext={pageContext} aside={WidgetAside({contactBox:contactBox})} ancestors={data.wordpressPage.ancestors}>
      <div style={{maxWidth:1080}} className=" container mx-0 px-0">
        <div style={{maxWidth: 910}} className="container mx-0 px-0">
          <PageTitle title={data.wordpressPage.title}/>
          <div className="wp_content" dangerouslySetInnerHTML={{__html:data.wordpressPage.content}} />
          {data.wordpressPage.chemical_recycling_grid_settings && data.wordpressPage.chemical_recycling_grid_settings.posts?.length > 0 &&
           <FilteredGridList
             filters={filters}
             posts={data.wordpressPage.chemical_recycling_grid_settings.posts
                    .filter(p=>{
                      //filter out posts that only have an category that is excluded IF we do display the category filter
                      return !grid_settings.category_filter.display || p.categories.filter(c=>!c.exclude_from_main_archive)?.length
                    })
                    .map(
               p => {
                 const tags_label = `${p.categories.map(c=>c.name).join(',')} ${!!p.companies.length?" - "+p.companies.map(c=>c.name).join(','):""}`
                 return {
                   ...p,
                   tags_label:tags_label
                 }
               }
             )}
             analyticsName={'crpost'}
           />
          }
          {data.wordpressPage.display_sharing_toolbar &&
           <SocialShare  url={pageContext.yoast.canonical} context={pageContext.yoast} className="my-10"/>
          }
        </div>

        {data.wordpressPage?.widgets?.widgets.length>0 &&
         <FlexibleContent data={data.wordpressPage.widgets.widgets}/>
        }

        {data.wordpressPage.supporting_documents &&
         <SupportingDocuments {...data.wordpressPage.supporting_documents}/>
        }
      </div>
    </Layout>
  );
}
export const defaultPagequery = graphql`
query ChemicalRecyclingGridPageById($id: String!) {
   categories:allWordpressWpTaxonomyChemicalRecycling(filter:{count:{gt:0}}){
    nodes {
      taxonomy {
        name
        slug
      }
      exclude_from_main_archive
      name
      id: wordpress_id
      count
    }
  }
  companies:allWordpressWpCompany(filter:{count:{gt:0}}){
    nodes {
      taxonomy{
        name
        slug
      }
      name
      id: wordpress_id
      count
    }
  }
  wordpressPage(id:{eq:$id}){
    title
    slug
    path
    wordpress_id
    wordpress_parent
    content
    excerpt
     chemical_recycling_grid_settings{
      restrict_to_category
      company_filter {
          display
          placeholder
      }
      category_filter {
          display
          placeholder
      }

      posts{
        slug
        path
        title
        companies{
          id: wordpress_id
          name
        }
        categories{
          id: wordpress_id
          name
exclude_from_main_archive
        }
        featured_media{
          localFile{
           verticalThumb:childImageSharp{
              fluid(maxWidth: 335,  maxHeight: 160, quality: 100){
                   ...GatsbyImageSharpFluid
              }
            }
          }
        }

      }
    }
display_sharing_toolbar
widgets{
      ...FlexbileContentBuilder
    }

     supporting_documents {
      title
      documents {
        title
        excerpt
        content_type
        topics
        slug
        date
        wordpress_id
        default_image {
          localFile {
            childImageSharp {
              fixed(width: 97, height: 138) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        acf {
          document_type
          external_link
          file {
            mime_type
            alt
          }
        }
        featured_media {
          localFile {
            smallHThumb: childImageSharp {
              fixed(width: 97, height: 138) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    ancestors {
      path
      name:title
    }
    acf {
      contact_box{
          show_contact_box
          title
          text
          phone
          email
        }
    }
  }
}
`;
