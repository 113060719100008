import React, {useEffect} from 'react';
import {Previewer} from 'pagedjs';
import './index.css';
import { globalHistory } from '@reach/router'

export const withPrintSupport = (WrappedComponent, handler) => {
  return class extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        previewInProgress: false
      }
      this.renderPrintPreview = this.renderPrintPreview.bind(this);
    }
    renderPrintPreview(e){
      var hash = window.location;
      const styles_href = Array.from(document.querySelectorAll('link[rel="stylesheet"]'))?.map(e=>e.href);
      const paged = new Previewer();
      const el = handler(this.props.handler_data);
      this.setState({previewInProgress: true});
		  paged.preview(
        el.innerHTML,
			  ['/styles/print.css', ...styles_href, `https://${process.env.GATSBY_WP_URL}/app/themes/cefic/dist/styles/blocks.css`],
        document.querySelector('#printpreview')
		  ).then((flow) => {
        const preview_el = document.querySelector('#printpreview');
        if (preview_el.childElementCount > 1){
          const c = preview_el.lastElementChild;
          preview_el.innerHTML = '';
          preview_el.append(c);
        }
		  });
    }


    shouldComponentUpdate(nextProps, nextState){
      if(nextProps.handler_data.wordpress_id !== this.props.handler_data.wordpress_id){
        return true;
      }
      return false;
    }

    componentDidMount(){
      const divtohide = document.querySelector('#___gatsby').classList.add("hideforprint");
      const preview_el = document.querySelector('#printpreview');
      this.renderPrintPreview();
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') {
          document.querySelector('#___gatsby')?.classList.remove('hideforprint');
          document.querySelector('#printpreview').innerHTML="";
        }
      });
    }

    componentDidUpdate(){
      this.renderPrintPreview();
    }

    render(){
      const {handler_data, ...otherprops} = this.props;
      return (<WrappedComponent {...otherprops} />);
    }
  }
}
