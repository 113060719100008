import React, {Fragment} from 'react';
import MediaQuery from 'react-responsive';
import moment from 'moment';
import {graphql, useStaticQuery} from 'gatsby';
import {MobileEventPreview, HorizontalEventPreview} from './previews';
import SelectMonth from './parts/filter';

export const AllEvents = (props) => {
  const data = useStaticQuery(graphql`
query{
      events:allWordpressWpEvent(sort:{fields:date,order:ASC}, filter:{status:{eq:"publish"}}){
      nodes{
          id
          yoast: yoast_head
          wordpress_id
          title
          content
          excerpt
          slug
          path
          date(formatString: "MMMM D, YYYY")
          month: date(formatString: "MMMM")
          iso_date: date(formatString:"YYYYMMDD")
          time
          end_date(formatString: "MMMM D, YYYY")
          end_time
          location
      }
    }}
  `);
  //  return data.events.edges.map(e=>e.node);

  const isotoday = moment().format('YYYYMMDD');
  let d = data.events.nodes.filter(n=>n.iso_date >= isotoday);
  return d;

};

export class EventsList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectedMonth: 'all',
    };
    this.onSelectMonth = this.onSelectMonth.bind(this);
  }
  onSelectMonth(selectedMonth){

    this.setState({selectedMonth:selectedMonth.value});
  }
  render(){
    let events = this.props.events;
    let months = [];
    events.map(e=>{if(!months.includes(e.month)){
      months.push( e.month);
    }});
    if(months.length){
      months = months.map(m=>{return {value:m,label:m};});
      months.unshift({value:'all', label:'All Events'});
    }
    if(this.state.selectedMonth !== 'all'){
      events = events.filter(e=>e.month===this.state.selectedMonth);
    }

    return (
      <Fragment>
        {months && months.length > 1 &&
         <div className="my-5 w-100 md:w-64">
         <SelectMonth months={months} value={this.state.selectedMonth} onChange={this.onSelectMonth}/>
         </div>
        }
        <MediaQuery minWidth={768} >
          {events.map(p => (
            <div className="border-b" key={p.slug}>
              <HorizontalEventPreview  {...p} openInPanel={this.props.openInPanel} />
            </div>
          ))}
        </MediaQuery>

        <MediaQuery maxWidth={767} >
          {events.map(p => (
            <div className="border-b" key={p.slug}>
              <MobileEventPreview {...p}/>
            </div>
          ))}
        </MediaQuery>
      </Fragment>
    );
  }
}
export default  (props) => {
  const all_events = AllEvents();
  return(
    <EventsList events={all_events} {...props}/>
  );
};
