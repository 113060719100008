import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {CeficShare} from '../social-shares'
import ScrollArea from 'react-scrollarea/src/js/ScrollAreaWithoutCss';
import '../../styles/scrollarea.css';
import {connect} from 'react-redux';
import {CSSTransition} from 'react-transition-group'

const mapStateToProps = ({panel}) => {
  return panel;
};
const mapDispatchToProps = dispatch => {
  return {
    close: ()=>dispatch({type:`CLOSE_PANEL`}),
  };
};

export const PrimaryPanel = (props) => (
  <div className="bg-white relative flex-grow">
    <div className=" sticky pin-t">
      <ScrollArea
        className={'scrollarea max-h-screen'}
        contentClassName={'scrollarea-content'}
        smoothScrolling
        speed={0.8}
        verticalContainerClassName={'scrollbar-container'}
        verticalContainerClassNameActive={'scrollbar-container--active'}
        verticalContainerClassNameHorizontal={'scrollbar-container--horizontal'}
        verticalContainerClassNameVertical={'scrollbar-container--vertical'}
        verticalScrollbarClassName={'scrollbar-primary'}>
        {props.children}
      </ScrollArea>
    </div>
  </div>
);

export class Panel extends React.Component {
  render(){
    return (
      <div className="bg-grey w-1/2">
        <div dangerouslySetInnerHTML={{__html:this.props.content}}/>
      </div>
    );
  }
}

class SecondaryPanelContent extends React.Component {

  render(){
    return (
      <div className="content px-16 pb-8 mb-12">
        {this.props.children}
      </div>
    );
  }
}
SecondaryPanelContent.contextTypes = {
  scrollArea: PropTypes.object
}
class SecondaryPanelComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
   // this.close = this.close.bind(this)
    this.scrollAreaRef = React.createRef()
  }


  componentDidUpdate(){
    if(this.scrollAreaRef.current){
      this.scrollAreaRef.current.scrollArea.refresh();
      this.scrollAreaRef.current.scrollArea.scrollTop();
    }
  }

  render(){
    const yoast = this.props.content&&this.props.content.props&&this.props.content.props.yoast?this.props.content.props.yoast:false;
    return (
      <aside  className="side-panel secondary-panel relative "  style={{maxWidth:720}}>
        <div className="side-panel__wrapper px-5 sticky pin-t"  >
          <div className="side-panel__content px-5 absolute" style={{width:720}}>
        <div className="sticky pin-t ">
          <div className=" flex justify-end pt-3 z-50 mb-7 pr-2">
            {yoast &&
             <CeficShare context={yoast} className="mr-5" url={this.props.content.props.path}/>
            }
          <button onClick={this.props.close}><CloseIcon width={14} height={14} /></button>
        </div>
          <ScrollArea
            ref={this.scrollAreaRef}
          className={'scrollarea max-h-screen'}
          contentClassName={'scrollarea-content'}
          smoothScrolling
          speed={0.8}
          verticalContainerClassName={'scrollbar-container'}
          verticalContainerClassNameActive={'scrollbar-container--active'}
          verticalContainerClassNameHorizontal={'scrollbar-container--horizontal'}
          verticalContainerClassNameVertical={'scrollbar-container--vertical'}
            verticalScrollbarClassName={'scrollbar'}
            stopScrollPropagation={false}
          >
          <SecondaryPanelContent>
            {this.props.content}
            {yoast &&
             <CeficShare context={yoast} className="mr-5 mt-10" url={this.props.content.props.path}/>
            }
          </SecondaryPanelContent>
      </ScrollArea>
      </div>
      </div>
      </div>
      </aside>

    );
  }
}


export const SecondaryPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryPanelComponent);
