import React from 'react';
import {Link} from '../../link';
import SearchableTypes from './searchable-types';
import {SubTitle, MetaInfo} from '../../typography';
import {ReactComponent as ChevronRight} from '../../../assets/icons/chevron-right.svg';
import {ReactComponent as DownloadIcon} from '../../../assets/icons/file-download.svg';
import {connect} from 'react-redux';
export class Item  extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover: false
    };
  }
  render(){
    return(
      <div className="border-b mb-5 hover:cursor-pointer search-item" onMouseEnter={()=>this.setState({hover:true})} onMouseLeave={()=>this.setState({hover:false})}>
        <Link onClick={e=>this.props.onClick(this.props)} to={this.props.path} className="hover:cursor-pointer text-grey hover:text-brand flex items-center mb-3">
          <div className="mr-auto">
            <SubTitle hover={this.state.hover} dangerouslySetInnerHTML={{__html:this.props.title}}/>
            <MetaInfo className="capitalize">{this.props.type_label}</MetaInfo>
          </div>
          <div className="ml-auto">
             <ChevronRight className="fill-current" height={25} width={25}/>
          </div>
        </Link>
      </div>
    );
  }
};

export class FileItem extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover: false
    };
  }
  render(){
    return(
      <div className="border-b mb-5" >
        <div className="flex items-center mb-3">
          <div className="mr-auto">
            <SubTitle dangerouslySetInnerHTML={{__html:this.props.title}}/>
            <MetaInfo className="capitalize">{this.props.type_label}</MetaInfo>
          </div>
          <Link onClick={e=>this.props.onClick(this.props)} url={this.props.path} className="ml-auto text-black hover:text-brand hover:cursor-pointer" >
             <DownloadIcon className="fill-current" height={25} width={25}/>
          </Link>
        </div>
      </div>
    );
  }
}
export class ExternalLinkItem extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover: false
    };
  }
  render(){
    return(
      <Link onClick={e=>this.props.onClick(this.props)} url={this.props.path} className="ml-auto text-black hover:text-brand hover:cursor-pointer" onMouseEnter={()=>{this.setState({hover:true});}} onMouseLeave={()=>{this.setState({hover:false});}}>
      <div className="border-b mb-5" >
        <div className="flex items-center mb-3">
          <div className="mr-auto">
            <SubTitle dangerouslySetInnerHTML={{__html:this.props.title}}/>
            <MetaInfo className="capitalize">{this.props.type_label}</MetaInfo>
          </div>
          <div className="flex items-center">
            {this.state.hover &&
             <span>Go to website</span>
            }
            <ChevronRight className="fill-current" height={25} width={25}/>
          </div>
        </div>
      </div>
      </Link>
    );
  }
}


export class SearchItem extends React.Component{
  renderFileItem(){
    return(<FileItem {...this.props} onClick={this.props.onClick}/> );
  }
  renderItem(){
    return(<Item {...this.props} onClick={this.props.onClick}/>);
  }
  renderExternalLink(){
    return(<ExternalLinkItem {...this.props} onClick={this.props.onClick}/>);
  }
  render(){
    if(this.props.type === 'library-item'){
      if(this.props.acf.document_type === 'file'){
        return this.renderFileItem();
      }
      else if(this.props.acf.document_type === 'link'){
        return this.renderExternalLink();
      }
    }
      return this.renderItem();
  }
}
const SearchResultItemComponent =  (props) => {
  const searchable_types = SearchableTypes();
  return (
    props.items.map((i,index) => {
      const matched_type = searchable_types.filter(t=>t.type===i.type);
      const type_label = matched_type&&matched_type.length?matched_type[0].label:null;
      return (
        <SearchItem
          {...i}
          type_label={type_label}
          key={index}
          onClick={e=>{
            props.updateSearchString(i);

          }}
        />
      );
    })
  );
};


const mapStateToProps = ({searchPanel}) => {
  return {searchPanel};
};
const mapDispatchToProps = dispatch => {
  return {
    updateSearchString: (item) => dispatch({type: 'CLICK_AFTER_SEARCH', item}),
  };
};

const ConnectedSearchResultItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultItemComponent);

export default ConnectedSearchResultItem;
