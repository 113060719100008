import React from 'react';
import {SearchField} from '../../forms/fields';
export default class GlobalSearchForm extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      searchString: ''
    };
  }
  
  render(){
    return(
      <SearchField className="w-3/4" placeholder="Type to search" onChangeHandler={value=>this.props.onUpdateTextSearch(value)} value={this.props.value}/>
    );
  }
}

