import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "../link";
import { SecondaryButton } from "../buttons.js";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";

import Img from "gatsby-image";

const useHomePageSlides = () => {
	const data = useStaticQuery(
		graphql`
			query {
				wordpressPage(is_front_page: { eq: true }) {
					carousel {
						title
						excerpt
						path
						button_label
						featured_media {
							localFile {
								childImageSharp {
									fluid(maxWidth: 500) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
			}
		`
	);
	return data.wordpressPage.carousel;
};

function withPagination(WrappedComponent, SlideComponent) {
	return class extends React.Component {
		constructor(props) {
			super(props);
			this.updateState = this.updateState.bind(this);
			this.onNext = this.onNext.bind(this);
			this.onPrev = this.onPrev.bind(this);
			this.state = {
				hasPrev: false,
				hasNext: false,
				cycle: true,
				collection: [],
				perPage: 1,
				pages: [],
				currentPage: 0,
			};
		}
		updateState(newState) {
			this.setState({
				...newState,
				hasNext:
					newState.pages.length > 1 &&
					(this.state.cycle ||
						(newState.pages.length > 1 &&
							newState.currentPage < newState.pages.length - 1)),
				hasPrev:
					newState.pages.length > 1 &&
					(this.state.cycle ||
						(newState.pages.length > 1 && newState.currentPage > 0)),
			});
		}

		onNext() {
			let nextIndex = this.state.currentPage + 1;
			if (nextIndex > this.state.pages.length - 1) {
				if (this.state.cycle) {
					nextIndex = 0;
				}
			}
			this.updateState({
				...this.state,
				currentPage: nextIndex,
			});
		}
		onPrev() {
			let nextIndex = this.state.currentPage - 1;
			if (nextIndex < 0) {
				if (this.state.cycle) {
					nextIndex = this.state.pages.length - 1;
				}
			}
			this.updateState({
				...this.state,
				currentPage: nextIndex,
			});
		}
		componentDidMount() {
			const _perPage = this.props.perPage
				? this.props.perPage
				: this.state.perPage;
			const _pages = Array.from(
				{ length: Math.ceil(this.props.slides.length / _perPage) },
				(v, i) => this.props.slides.slice(i * _perPage, i * _perPage + _perPage)
			);
			this.updateState({
				...this.state,
				cycle: this.props.cycle,
				collection: [...this.props.slides],
				perPage: _perPage,
				pages: [..._pages],
			});
		}

		render() {
			return (
				<WrappedComponent
					showNext={this.state.hasNext}
					showPrev={this.state.hasPrev}
					onNext={this.onNext}
					onPrev={this.onPrev}
					{...this.props}
				>
					{this.state.pages &&
						this.state.pages.length > 0 &&
						this.state.pages[this.state.currentPage].map((slide, index) => (
							<SlideComponent
								{...slide}
								slideIndex={this.state.currentPage}
								className={
									this.props.slide_className
										? this.props.slide_className
										: "caroussel__slide"
								}
								showNext={this.state.hasNext}
								showPrev={this.state.hasPrev}
								onNext={this.onNext}
								onPrev={this.onPrev}
								key={index}
							>
								{slide.title}
							</SlideComponent>
						))}
				</WrappedComponent>
			);
		}
	};
}

export const Slider = (props) => <div className="">{props.children}</div>;

export const HomePageSlide = (props) => (
	<div
		id={`${props.className}__${props.slideIndex}`}
		className={`lg:flex justify-content-between items-center ${props.className}`}
	>
		<div className="lg:mr-auto w-full">
			<h1
				className="font-serif tracking-02 leading-47 text-5xl font-normal mb-7"
				dangerouslySetInnerHTML={{ __html: props.title }}
			/>
			<div style={{ maxWidth: 400 }} className="lg:ml-auto">
				<hr
					className="border-brand mr-auto ml-0 mb-5"
					style={{ borderWidth: 2, width: 35 }}
				/>
				<div
					className="mb-7"
					dangerouslySetInnerHTML={{ __html: props.excerpt }}
				/>
				<div className="flex items-center">
					<Link to={`${props.path}`} className={`${props.className}__cta`}>
						<SecondaryButton label={`${props.button_label}`} />
					</Link>
					<div className="ml-auto">
						{props.showPrev && (
							<ChevronLeft
								onClick={props.onPrev}
								width={20}
								height={20}
								className={`text-black fill-current flex-no-shrink mr-5 hover:cursor-pointer hover:text-brand ${props.className}__prev`}
							/>
						)}
						{props.showNext && (
							<ChevronRight
								onClick={props.onNext}
								width={20}
								height={20}
								className={`text-black fill-current flex-no-shrink ml-5 hover:cursor-pointer hover:text-brand ${props.className}__next`}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
		{props.featured_media && props.featured_media.localFile && (
			<div className="w-full lg:w-1/2 lg:ml-3 flex-no-shrink mt-5">
				<Img fluid={props.featured_media.localFile.childImageSharp.fluid} />
			</div>
		)}
	</div>
);

const PaginatedSlider = withPagination(Slider, HomePageSlide);

export const HomePageCarousel = (props) => {
	const allSlides = useHomePageSlides();
	console.log(allSlides);
	return (
		<PaginatedSlider
			id="homepageCaroussel"
			className="homepage__caroussel"
			slide_className="homepage__caroussel__slide"
			slides={allSlides}
			cycle={true}
		/>
	);
};
