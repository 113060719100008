import React from 'react';
import {PBody} from '../../typography';
import {ReactComponent as Pin} from '../../../assets/icons/location-pin.svg';

export default ({location}) =>(
  <div className="flex items-center">
    <Pin className="text-brand fill-current text-brand mr-3"/>
    <PBody  className="m-0" dangerouslySetInnerHTML={{__html:location}}/>
  </div>
)
