import React, {Fragment} from 'react';
import PostPreview from './preview';
import MediaQuery from 'react-responsive';
import {HorizontalPostPreview, MobilePostPreview} from './previews';
import {VerticalPreview} from './preview';
import './post_lists.css';

export const LocalPostsList = (props) => (
  <Fragment>
    <MediaQuery minWidth={992} >
      {props.posts.map((p, index) => (
          <div className={props.analyticsName} id={props.analyticsName + '-' + p.slug.split('/').slice(-1)[0]} key={index}>
            <HorizontalPostPreview key={p.slug} {...p} openInPanel={props.openInPanel} showThumbnail={props.showTumbnail?true:props.showThumbnail}/>
          </div>
        ))}
      </MediaQuery>

    <MediaQuery maxWidth={991} >
      {props.posts.map((p, index) => (
          <div className={props.analyticsName} key={index} id={props.analyticsName + '-' + p.slug.split('/').slice(-1)[0]}>
            <MobilePostPreview key={p.slug} {...p}/>
          </div>
        ))}
      </MediaQuery>
    </Fragment>
);


export const GridPostsList = (props) => (
  <div className="post-grid">

    {props.posts.map(p => (

      <div className={props.analyticsName} id={props.analyticsName + '-' + p.slug.split('/').slice(-1)[0]} key={p.slug}>
        <VerticalPreview style={{margin:0}}key={p.slug} post={p}/>
      </div>
    ))}
  </div>
)
