export const BuildHeader = (title) =>{
	const logo = document.querySelector('.ceficLogo__logo')?.cloneNode(true);
  const tpl = `
<div class="holder">
${!!logo?logo.outerHTML:""}
<p class="header-title">${!!title?title:''}</p>
</div>
`;
  const node = document.createElement("div");
  node.classList.add('print-header');
  node.innerHTML=tpl;
  return node;
};


export const ReplaceImageByFigure = (el) => {
	const figures = el.querySelectorAll('figure');
	figures.forEach(f => {
		const img = f.querySelector('img');

		if(img){
			img.removeAttribute('loading'); // strip te loading=lazy attribute if it's there
			f.parentNode.replaceChild(img, f);
		}


	});
	el.querySelectorAll('img[loading="lazy"]').forEach(lazy_img=>{
		lazy_img.removeAttribute('loading');
	})
	return el;
};

export const defaultPreprocessor = (data) => {
	// data is what comes from graphql
	const content = data.content;

	//create div with content
	let contentDiv = document.createElement('div');
	contentDiv.innerHTML = content;
	contentDiv = ReplaceImageByFigure(contentDiv);

	//inject h1 + hr
	const titleDiv = document.querySelector('.page_title')?.cloneNode(true);
	if(!!titleDiv){
		contentDiv.prepend(titleDiv);
	}
	//inject header
	const parent = data?.ancestors?.[0]?.name;
	const header = BuildHeader(parent);
	contentDiv.prepend(header);
	return contentDiv;
};
