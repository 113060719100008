import React from 'react';
import {Link} from './link';
import {StaticQuery, graphql} from 'gatsby';
import classnames from 'classnames';
import {LargeButtonLabel, SmallButtonLabel} from './typography';


export class PrimaryButton extends React.Component{
  constructor (props) {
    super(props);
    this.state = {
      hover: false
    };
  }
  render(){
    const className= classnames(
      'border p-button text-center align-bottom',
      {
        'border-grey-darkest bg-white': !this.state.hover,
        'border-brand bg-brand': this.state.hover
      }
    );
    return (
      <button className={className} onClick={this.props.onClickHandler} onMouseEnter={e=>{this.setState({hover:true});}} onMouseLeave={e=>{this.setState({hover:false});}}>
        <LargeButtonLabel hover={this.state.hover?this.state.hover:false} dangerouslySetInnerHTML={{__html:this.props.label}} />
      </button>
    );
  }
}

export class SecondaryButton extends React.Component{

  constructor (props) {
    super(props);
    this.state = {
      hover: false
    };
  }
  render(){
    const className= classnames(
      'border-b p-button-small text-center align-bottom',
      {
        'border-b border-brand': !this.state.hover,
        'border border-brand text-brand': this.state.hover
      }
    );
    return (
      <button className={className} onClick={this.props.onClickHandler} onMouseEnter={e=>{this.setState({hover:true});}} onMouseLeave={e=>{this.setState({hover:false});}}>
        <SmallButtonLabel hover={this.state.hover?this.state.hover:false} dangerouslySetInnerHTML={{__html:this.props.label}} />
      </button>
    );
  }
}

