import React from 'react';
import {PSubTitle} from '../typography';
import {LibraryItemsList} from './list';


export default (props) => (
  <section className={props.className}>
    <PSubTitle className="mb-7" dangerouslySetInnerHTML={{__html:props.title}}/>
    <LibraryItemsList items={props.documents}/>
  </section>
);
