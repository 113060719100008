import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle, WidgetAside} from '../components/common';
import SupportingDocuments from '../components/library/supporting_documents';
import './template-beehive.css'

import {Beehive} from '../components/beehive/Beehive'

export default (props) =>{
  const data = props.data.page;
  const types = props.data.allWordpressWpSdgType.nodes;
  const typesColors = {}
  types.forEach(type => {
      typesColors[type.wordpress_id] = type.acf.color
  });
  const goals = props.data.allWordpressWpSdgGoal.nodes;
  const goalImages = {}
  goals.forEach(goal => {
    if(goal.acf && goal.acf.image){
      goalImages[goal.wordpress_id] = goal.acf.image.localFile.childImageSharp.fixed;
    }
  })
  const sdgs = props.data.allWordpressWpSdg.nodes;
  const hives = sdgs;
  const contactBox = data.acf.contact_box && data.acf.contact_box.show_contact_box?data.acf.contact_box:null;
  return(
    <Layout pageContext={props.pageContext} ancestors={data.ancestors} aside={WidgetAside({contactBox:contactBox})}>
      <div style={{maxWidth:910}} className="mx-0 px-0">
          <PageTitle title={data.title}/>
          <div className="wp_content" dangerouslySetInnerHTML={{__html:data.content}} />
          <div className='flex my-8 flex-wrap justify-center beehive-types'>
            {types.map((type)=>{
              return (
                <div style={{color: type.acf.color}} className='m-4 flex items-center'>
                  <span className="dot mr-2" style={{backgroundColor: type.acf.color}}></span> <p className='m-0' style={{whiteSpace: 'no-wrap'}}>{type.name}</p>
                </div>

              )
            })}
          </div>
        <Beehive data={hives} typesColors={typesColors} goalImages={goalImages}/>
        {data.supporting_documents &&
          <SupportingDocuments className="w-full mt-10" {...data.supporting_documents}/>
        }
      </div>
    </Layout>
  );
}
export const defaultBeehiveQuery = graphql`
query defaultBeehiveQueryd($id: String!) {
  page: wordpressPage(id:{eq:$id}){
    title
    slug
    wordpress_id
    content
    ancestors {
      slug
      name:title
    }
    supporting_documents {
      title
      documents {
        title
        date
        excerpt
        content_type
        topics
        slug
        wordpress_id
        default_image {
          localFile {
            childImageSharp {
              fixed(width: 97, height: 138) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        acf {
          document_type
          external_link
          file {
            mime_type
            alt
          }
        }
        featured_media {
          localFile {
            smallHThumb: childImageSharp {
              fixed(width: 97, height: 138) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    acf {
      contact_box{
        show_contact_box
        title
        text
        phone
        email
      }
    }
  }
  allWordpressWpSdgType(filter:{count:{gt:0}},sort:{fields:menu_order, order:ASC}) {
    nodes {
      wordpress_id
      name
      acf {
        color
      }
    }
  }

  allWordpressWpSdgGoal {
    nodes {
      wordpress_id
      acf {
        image{
          localFile{
            childImageSharp{
              fixed(width:150, height:150){
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
  allWordpressWpSdg(sort:{fields:menu_order, order: ASC}) {
    nodes {
      path
      title
      content
      yoast: yoast_head
      sdg_type {
        name
        acf {
          color
        }
      }
      sdg_goal
    }
  }
}
`;
