import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';


export default () => {
  const {options} = useStaticQuery(graphql`
   query SearchableTypes
    {
      options:wordpressAcfOptions {
       options {
         search_engine {
           types {
            label
            type
           }
         }
       }
    }
   }`);
  return options.options.search_engine.types;
}
