import React, {
  Fragment
} from 'react';
import TopToolbar from '../top-toolbar';
import {
  MainMenu
} from '../menu';
import MainMenuMobile from '../menu/main-menu-mobile';
import CeficLogo from '../cefic-logo';
import MediaQuery from 'react-responsive';
import {
  LogoShort
} from '../logos';
import {
  Link
} from '../link';
import {
  ReactComponent as SearchIcon
} from '../../assets/icons/search.svg';
import {
  ReactComponent as TogglerIcon
} from '../../assets/icons/menu.svg';
import {
  ReactComponent as CloseIcon
} from '../../assets/icons/close.svg';
import SearchButton, {
  SearchButtonComponent
} from '../search/globalSearch/button';
import GlobalSearch from '../search/globalSearch';
import {
  connect
} from 'react-redux';
//props to replace

const DesktopPageHeader = (props) => (
  <Fragment>
    <TopToolbar />
    <div className="navbar border-b  border-grey-lightest rounded-none ">
      <div className="container mx-auto flex justify-between px-0">
        <Link to={"/"}>
        <CeficLogo />
        </Link>
        <MainMenu/>
        <SearchButton/>
      </div>
    </div>
  </Fragment>
);

const MobileTopBar = (props) => (
  <div className="flex justify-between items-center px-5 flex-no-shrink border-b-2" style={{height:60}}>
<SearchButton/>
  <Link to={"/"}>
  <LogoShort/>
  </Link>
    <button onClick={props.onToggleMenu}>
      {!props.openMenu?
       <TogglerIcon height={20} width={25}/>
       :
       <CloseIcon height={20} width={25}/>
      }
       </button>
  </div>
);

class MobilePageHeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
    this.toggleMenuHandler = this.toggleMenuHandler.bind(this);
    this.onToggleScreen = this.onToggleScreen.bind(this);
  }
  toggleMenuHandler() {
    this.onToggleScreen({
      showMenu: !this.state.showMenu,
    });
    if (this.props.searchPanel.isOpen) {
      this.props.closeSearch();
    }
  }

  onToggleScreen(newState) {
    return this.setState({
        ...newState
      },
      (state) => {
        if (this.state.showMenu || this.props.searchPanel.isOpen) {
          document.body.classList.add('overflow-hidden');

        } else {
          document.body.classList.remove('overflow-hidden');
        }
      }
    );
  }
  componentWillUnmount() {
    document.body.classList.remove('overflow-hidden');
  }
  render() {
    return (
      <header className="sticky pin-t flex flex-col z-20 bg-white max-h-screen " ref={this.props.forwardref}>
        <MobileTopBar
          openMenu = {this.state.showMenu}
          onToggleMenu={this.toggleMenuHandler}

        />
        {this.state.showMenu &&
         <Fragment>
           <div className="bg-white w-screen flex flex-col flex-shrink overflow-y-scroll" style={{flexBasis:"100vh"}}>
             <MainMenuMobile />
           </div>
           <TopToolbar/>
         </Fragment>
        }
        {
          this.props.searchPanel.isOpen &&
            <div className="bg-white w-screen relative overflow-y-scroll px-4 mt-4" style={{flexBasis:"100vh"}}>
              <GlobalSearch variant="white"/>
            </div>
        }
    </header>
    );
  }
};
const mapStateToProps = ({
  searchPanel
}) => {
  return {
    searchPanel
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openSearch: () => dispatch({
      type: `OPEN_SEARCH`
    }),
    closeSearch: () => dispatch({
      type: `CLOSE_SEARCH`
    })
  };
};

const MobilePageHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobilePageHeaderComponent);

export default React.forwardRef((props, ref) => (
  <Fragment>
    <MediaQuery minWidth={992}>
      <header className="page-header relative z-30 bg-white" ref={ref}>
        <DesktopPageHeader {...props}/>
      </header>
    </MediaQuery>
    <MediaQuery maxWidth={991}>
		<header className="page-header">
    <MobilePageHeader forwardref={ref}/>
		</header>

    </MediaQuery>
  </Fragment>
));
