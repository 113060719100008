import React, {Fragment} from 'react';
import {navigate} from 'gatsby';
import Img from 'gatsby-image';
import Moment from 'react-moment';
import {Hr} from '../common';
import {ReactComponent as Chevron } from '../../assets/icons/chevron-right.svg';
import {SecondaryButton} from '../buttons';
import {Link} from '../link';
import {NewsTag} from '../typography';

const PostPreview = (props) => {
  const {post} = props;
  let featuredImage = false;
  if(post._embedded && post._embedded.hasOwnProperty('wp:featuredmedia')){
    const img = post._embedded['wp:featuredmedia'][0];
    featuredImage = img.media_details.sizes['medium'].source_url;
  }

  return (
    <article className="wp-block-cefic-blocks-post flex cursor-pointer hover:text-brand border border-grey-lightest" style={{height:164}}>
			{  !props.hasOwnProperty('hideThumbnail') && featuredImage  && (
				<div className="wp-block-cefic-blocks-post__header" >
          <img src={featuredImage} style={{height:'100%', width:217}} width={217} height={164} alt=''/>
				</div>
			) }
			<div className="wp-block-cefic-blocks-post__content flex flex-col justify-between px-6 py-3">
				<h1 className="wp-block-cefic-blocks-post__title font-serif text-2xl  font-normal" dangerouslySetInnerHTML={{__html:post.title}}/>
				<div
					className="wp-block-cefic-blocks-post__excerpt mb-auto mt-3 tracking-02 text-black"
					dangerouslySetInnerHTML={{__html: post.excerpt }}
				/>
        <Moment format="DD/MM/YYYY" className="wp-block-cefic-blocks-post__metas text-grey text-xs font-bold tracking-05">{post.date}</Moment>
			</div>
      <div className="ml-auto items-center flex">
        <Chevron className="fill-current mx-3"  height={25} width={25}/>
      </div>
		</article>
  );
};
export default PostPreview;

export const PostPreviewTitle = (props)=>(
  <Fragment>

    <h1
      className="font-serif text-2xl mb-7 leading-24"
      dangerouslySetInnerHTML={{__html:props.title}}
    />
    {props.children}
    <Hr className="mt-0"/>

  </Fragment>
);
export const VerticalPreview = (props) => {
  const {post} = props;
  const post_path = post.path?post.path:post.slug;
  let featuredImage = false;
  if(post.featured_media && post.featured_media.localFile && post.featured_media.localFile.verticalThumb){
    featuredImage = post.featured_media.localFile.verticalThumb;
  }
	let tags = post.tags_label;
	if(! post?.tags_label && Array.isArray(post.tags)){
		tags = post.tags.map(t=>t.name).join(', ');
	}
  const previewId = 'post-vertical-preview__'+props.post.slug.split("/").slice(-1)[0];
  return (
    <article style={props.style} className="post-vertical-preview__article wp-block-cefic-blocks-post flex flex-1 flex-col cursor-pointer hover:text-brand  mx-2"  id={previewId} onClick={e=>navigate(post_path)}>
      <div className="wp-block-cefic-blocks-post__header" style={{pointerEvents:"none"}}>
			  {  !props.hideThumbnail &&
           <div className="mb-2">
             {featuredImage ?
              <Img fluid={featuredImage.fluid}  alt=''/>
              :
              <svg width="100%"  className="fill-current text-grey-light" >
                <rect width="100%" height="100%" />
              </svg>
             }
           </div>
        }
        <PostPreviewTitle title={post.title}>
          {!!tags &&
			     <NewsTag className="mt-2" dangerouslySetInnerHTML={{__html:tags}} />

          }
        </PostPreviewTitle>
        {!!post.date &&
         <Moment format="DD/MM/YYYY" className="wp-block-cefic-blocks-post__metas text-grey text-xs font-bold tracking-05">{post.date}</Moment>
        }


      </div>
      {!!post.excerpt &&
       <div className="wp-block-cefic-blocks-post__content flex flex-col justify-between py-3" style={{pointerEvents:"none"}}>

				 <div
					 className="wp-block-cefic-blocks-post__excerpt mb-auto mt-3 tracking-02 text-black leading-21"
					 dangerouslySetInnerHTML={{__html: post.excerpt }} />
       </div>
      }

      <Link to={post_path} className="wp-block-cefic-blocks-post__footer mt-2 flex mb-5 post-vertical-preview__cta">
        <SecondaryButton label={'Read More +'}/>
      </Link>
		</article>

  );
};
