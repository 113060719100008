import React from 'react';
import classnames from 'classnames';
import {navigate} from 'gatsby';
import Img from 'gatsby-image';
import {
  MetaInfo,
  SubTitle,
  PBody,
  NewsTag
} from '../../typography';
import Moment from 'react-moment';
import {connect} from 'react-redux';
import {ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';
import Post from '../post';



const trimExcerpt = (excerpt, max_length=180, filler='...') => {
  excerpt = excerpt.replace(/\n/g, '')
  excerpt = excerpt.replace(/<br \/>/g, '')
  if(!!excerpt){
    const arr = excerpt.match(/<p>(.*)<\/p>/) ;
    let new_excerpt = arr.length>1?arr[1]:arr[0];
    if(new_excerpt.length > max_length){
      new_excerpt = new_excerpt.slice(0, max_length-filler.length)+filler;
    }
    return new_excerpt;
  }
  return excerpt;

};


const HorizontalPostThumbnail = (props) => {
  return (
    <div className="relative flex-no-shrink  " style={{width:217, height:164}}>
      {props.featured_media && props.featured_media.localFile && props.featured_media.localFile.horizontalThumb ?
       <Img fixed={props.featured_media.localFile.horizontalThumb.fixed}/>
       :
       <svg className="fill-current block text-grey-lightest flex-no-shrink" width="100%" height="100%">
         <rect width="100%" height="100%"/>
       </svg>
      }
      {props.hover &&
       <svg className="block absolute opacity-75 pin-y pin-l fill-current text-brand flex-no-shrink z-50" width='100%' height='164px'>
         <rect width="100%" height="100%"/>
       </svg>
      }
    </div>
  );
};


const mapStateToProps = ({panel})=>{
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setPanelContent: (content, path, context) => dispatch({type:`SET_PANEL_CONTENT`, content:content, path:path, context:context }),
    openPanel: () => dispatch({type:`OPEN_PANEL`})
  };
};

export class HorizontalPostPreview extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover:false
    };
    this.clickHandler = this.clickHandler.bind(this);
    this.renderPost = this.renderPost.bind(this);
  }
  clickHandler(){
    if(this.props.openInPanel){
      this.props.setPanelContent(this.renderPost(), this.props.path);
      this.props.openPanel();
    }else {
      navigate(this.props.path);
    }

  }
  renderPost(){
    return (<Post {...this.props} />);
  }
  render(){
    const classesChevron = classnames(
      'fill-current',
      {
        'text-grey': !this.state.hover,
        'text-brand': this.state.hover
      }
    );
    const componentId = 'post-preview__'+this.props.slug.split('/').filter(e=>e!=='').slice(-1)[0];
    const titleStyle = this.props.tags ? {marginBottom: 10} : {};
    return(
      <div id={componentId} className="post-preview relative flex items-top justify-center hover:cursor-pointer" onMouseEnter={e=>{this.setState({hover:true});}} onMouseLeave={e=>{this.setState({hover:false});}} onClick={this.clickHandler} style={{minHeight:164}} >
        {this.props.showThumbnail &&
         <HorizontalPostThumbnail hover={this.state.hover?this.state.hover:undefined} {...this.props}/>
        }
        <div className="px-15px py-2 flex flex-col jutify-between flex-grow" style={{pointerEvents:"none"}}>
          <div><SubTitle hover={this.state.hover?this.state.hover:undefined} dangerouslySetInnerHTML={{__html:this.props.title}} style={titleStyle}/></div>
          {this.props.tags ? <div><NewsTag>{this.props.tags.map(t=>t.name).join(', ')}</NewsTag></div> : ''}
          <div className="">
            <PBody dangerouslySetInnerHTML={{__html:trimExcerpt(this.props.excerpt).slice(0,218)}}/>
          </div>
          <div className="mt-auto">
            <MetaInfo> <Moment format="DD/MM/YYYY">{this.props.date}</Moment></MetaInfo>
          </div>
        </div>
        <div className="flex items-center">
          <ChevronRight width={25} height={25} className={classesChevron}/>
        </div>
      </div>
    );
  }
}

export const ConnectedHorizontalPostPreview = connect(
  mapStateToProps,
  mapDispatchToProps
)(HorizontalPostPreview);
