import React, { Fragment, useState, useReducer, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "../components/link";
import Layout from "../components/layout";
import GutenbergRenderer from "../gutenberg";
import { PageTitle } from "../components/common";
import { WidgetAside } from "../components/common";
import Post from "../components/posts/post";
import Img from "gatsby-image";
import { ReactComponent as ChevronRight } from "../assets/icons/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "../assets/icons/chevron-left.svg";
import classnames from "classnames";
import { PTitle } from "../components/typography";
import SupportingDocuments from "../components/library/supporting_documents";
import { CeficShare as SocialShare } from "../components/social-shares";
import { Transition } from "react-transition-group";

import FlexibleContent from "../components/flexible-builder";
import { GreyAside } from "../components/aside";
import { withPrintSupport } from "../components/printer";
import { PostTemplateHandler } from "../components/printer/handlers/post";

class PostBannerButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: false,
		};
	}

	render() {
		const { className, ...props } = this.props;
		const classNames = classnames("flex items-center " + className, {
			"cursor-pointer": this.state.hover,
		});
		return (
			<div
				className={classNames}
				styles={this.props.styles}
				onMouseEnter={(e) => this.setState({ hover: true })}
				onMouseLeave={(e) => this.setState({ hover: false })}
				onClick={this.props.onClick}
				{...props}
			>
				{this.props.variant === "prev" && (
					<Fragment>
						<ChevronLeft height={25} width={25} />
						{this.state.hover && (
							<span className="text-center" style={{ maxWidth: 70 }}>
								Previous
							</span>
						)}
					</Fragment>
				)}
				{this.props.variant === "next" && (
					<Fragment>
						{this.state.hover && (
							<span className="text-center" style={{ maxWidth: 70 }}>
								Next
							</span>
						)}
						<ChevronRight height={25} width={25} />
					</Fragment>
				)}
			</div>
		);
	}
}

const PostBanner = (props) => (
	<div className="relative flex items-center my-4">
		{props.onPrev && (
			<PostBannerButton
				variant="prev"
				className="pr-3"
				onClick={props.onPrev}
			/>
		)}
		<div className="flex-grow flex-row">
			{props.banner ? (
				<Img fluid={props.banner.fluid} />
			) : (
				<PTitle
					className="text-center mb-0"
					dangerouslySetInnerHTML={{ __html: props.alt }}
				/>
			)}
		</div>

		{props.onNext && (
			<PostBannerButton
				variant="next"
				className="pl-3"
				onClick={props.onNext}
			/>
		)}
	</div>
);

const updateReader = (state, action) => {
	switch (action.type) {
		case "next":
			if (state.nextPostId) {
				const newCurrent = state.allPosts.find(
					(p) => p.wordpress_id === state.nextPostId
				);
				return {
					...state,
					currentPostId: newCurrent.wordpress_id,
					nextPostId:
						state.orderedIds.indexOf(newCurrent.wordpress_id) + 1 <
						state.orderedIds.length
							? state.orderedIds[
									state.orderedIds.indexOf(newCurrent.wordpress_id) + 1
							  ]
							: null,
					prevPostId: state.currentPostId,
				};
			}
			return state;

		case "prev":
			if (state.prevPostId) {
				const newCurrent = state.allPosts.find(
					(p) => p.wordpress_id === state.prevPostId
				);
				return {
					...state,
					currentPostId: newCurrent.wordpress_id,
					nextPostId: state.currentPostId,
					prevPostId:
						state.orderedIds.indexOf(newCurrent.wordpress_id) - 1 > -1
							? state.orderedIds[
									state.orderedIds.indexOf(newCurrent.wordpress_id) - 1
							  ]
							: null,
				};
			}
			return state;
		default:
			throw new Error();
	}
};

const Content = (props) => {
	return (
		<>
			<Post {...props} />
		</>
	);
};

const ContentWithPrintSupport = withPrintSupport(Content, PostTemplateHandler);

export default (props) => {
	const post = props.data.wordpressPost;
	const allPosts = props.data.allPostsInCategory.nodes.map((p) => p);
	const orderedIds = allPosts.map((post) => post.wordpress_id);
	const initialState = {
		allPosts: allPosts,
		orderedIds: orderedIds,
		currentPostId: post.wordpress_id,
		nextPostId:
			orderedIds.indexOf(post.wordpress_id) + 1 < orderedIds.length
				? orderedIds[orderedIds.indexOf(post.wordpress_id) + 1]
				: null,
		prevPostId:
			orderedIds.indexOf(post.wordpress_id) - 1 > -1
				? orderedIds[orderedIds.indexOf(post.wordpress_id) - 1]
				: null,
	};

	const [state, dispatch] = useReducer(updateReader, initialState);

	const category = props.data.leafCategory;
	const currentPostCandidate = state.allPosts.find(
		(p) => p.wordpress_id === state.currentPostId
	);
	const currentPost = !!currentPostCandidate ? currentPostCandidate : post;
	const sorted_categories = [...currentPost.categories].sort(
		(a, b) => b.ancestors.length - a.ancestors.length
	);
	//sort categories based on their ancestors
	const ancestors = [
		{
			name: category.name,
			slug: category.path ? category.path : category.slug,
		},
	].concat(category.ancestors);
	const contactBox =
		currentPost.acf &&
		currentPost.acf.contact_box &&
		currentPost.acf.contact_box.show_contact_box
			? currentPost.acf.contact_box
			: null;
	const banner =
		currentPost.featured_media &&
		currentPost.featured_media.localFile &&
		currentPost.featured_media.localFile.banner
			? currentPost.featured_media.localFile.banner
			: false;
	const nextPost = () => {
		if (!state.nextPostId) {
			return;
		}
		changeUrl(state.nextPostId);
		setAnimationDirection("right");
		setInProp(false);
		setTimeout(() => {
			setInProp(true);
			dispatch({ type: "next" });
		}, 500);
	};
	const prevPost = () => {
		if (!state.prevPostId) {
			return;
		}
		changeUrl(state.prevPostId);
		setAnimationDirection("left");
		setInProp(false);
		setTimeout(() => {
			setInProp(true);
			dispatch({ type: "prev" });
		}, 500);
	};

	const changeUrl = (postId) => {
		const post = state.allPosts.find((p) => p.wordpress_id === postId);
		history.pushState("data to be passed", post.title, post.path);
		setPageContext({ yoast: post.yoast });
	};
	const [pageContext, setPageContext] = useState(props.pageContext);
	const [inProp, setInProp] = useState(true);
	const [animationDirection, setAnimationDirection] = useState("right");
	const duration = 300;

	const animationDefaultStyle = {
		transition: "right 500ms cubic-bezier(0.47, 0, 0.75, 0.72)",
		right: 0,
		position: "relative",
		width: "100%",
		opacity: 1,
	};

	const animationTransitionsStyles = {
		left: {
			entering: { right: "100%", opacity: 0 },
			entered: { right: "0%", opacity: 1 },
			exiting: { right: "-100%", opacity: 1 },
			exited: { right: "100%", opacity: 0 },
		},
		right: {
			entering: { right: "-100%", opacity: 0 },
			entered: { right: "0%", opacity: 1 },
			exiting: { right: "100%", opacity: 1 },
			exited: { right: "-100%", opacity: 0 },
		},
	};

	return (
		<Layout
			pageContext={pageContext}
			aside={GreyAside((props = { aside: currentPost.aside }))}
			ancestors={ancestors}
		>
			<div
				className="container mx-0 px-0 flex-row relative"
				style={{ maxWidth: 734 }}
			>
				<div className="container mx-0 px-0" style={{ maxWidth: 734 }}>
					<PostBanner
						banner={banner}
						alt={currentPost.title}
						onNext={!!state.nextPostId ? nextPost : null}
						onPrev={!!state.prevPostId ? prevPost : null}
					/>
					<Transition in={inProp} timeout={duration}>
						{(animationState) => (
							<div
								style={{
									...animationDefaultStyle,
									...animationTransitionsStyles[animationDirection][
										animationState
									],
								}}
							>
								<ContentWithPrintSupport
									handler_data={{
										...{ ...currentPost, ancestors, widgets: null },
									}}
									{...{ ...currentPost, widgets: null }}
								/>
							</div>
						)}
					</Transition>
				</div>
				<SocialShare
					printOptions={{ display: true }}
					className="mt-10"
					url={currentPost.path}
					context={currentPost.yoast}
				/>

				{currentPost?.widgets?.widgets.length > 0 && (
					<FlexibleContent data={currentPost.widgets.widgets} />
				)}

				{currentPost.supporting_documents && (
					<SupportingDocuments
						className="w-full mt-10"
						{...currentPost.supporting_documents}
					/>
				)}
			</div>
		</Layout>
	);
};

export const defaultPostQuery = graphql`
	query DefaultPostById($id: String!, $category_id: String!) {
		allPostsInCategory: allWordpressPost(
			filter: { categories: { elemMatch: { id: { eq: $category_id } } } }
			sort: { fields: menu_order, order: ASC }
		) {
			nodes {
				title
				path
				wordpress_id
				content
				aside {
					...AsideFragment
				}
				yoast: yoast_head

				featured_media {
					localFile {
						banner: childImageSharp {
							fluid(maxWidth: 734, maxHeight: 300) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				widgets {
					...FlexbileContentBuilder
				}
				supporting_documents {
					title
					documents {
						title
						excerpt
						content_type
						topics
						slug
						wordpress_id
						date
						default_image {
							localFile {
								childImageSharp {
									fixed(width: 97, height: 138) {
										...GatsbyImageSharpFixed
									}
								}
							}
						}
						acf {
							document_type
							external_link
							file {
								mime_type
								alt
							}
						}
						featured_media {
							localFile {
								smallHThumb: childImageSharp {
									fixed(width: 97, height: 138) {
										...GatsbyImageSharpFixed
									}
								}
							}
						}
					}
				}
				categories {
					slug
					name
					path
					ordered_posts {
						slug
						title
					}
					ancestors {
						slug
						path
						name
					}
				}
				acf {
					contact_box {
						show_contact_box
						title
						text
						phone
						email
					}
				}
			}
		}
		wordpressPost(id: { eq: $id }) {
			title
			path
			wordpress_id
			content
			yoast: yoast_head
			aside {
				...AsideFragment
			}
			featured_media {
				localFile {
					banner: childImageSharp {
						fluid(maxWidth: 734, maxHeight: 300) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
			widgets {
				...FlexbileContentBuilder
			}
			supporting_documents {
				title
				documents {
					title
					excerpt
					content_type
					topics
					slug
					wordpress_id
					date
					default_image {
						localFile {
							childImageSharp {
								fixed(width: 97, height: 138) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
					acf {
						document_type
						external_link
						file {
							mime_type
							alt
						}
					}
					featured_media {
						localFile {
							smallHThumb: childImageSharp {
								fixed(width: 97, height: 138) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
			categories {
				slug
				path
				name
				ordered_posts {
					slug
					title
				}
				ancestors {
					slug
					path
					name
				}
			}
			acf {
				contact_box {
					show_contact_box
					title
					text
					phone
					email
				}
			}
		}
		leafCategory: wordpressCategory(id: { eq: $category_id }) {
			name
			slug
			path
			ancestors {
				name
				slug
				path
			}
		}
	}
`;
