import React, {Fragment} from "react";
import debounce from 'lodash.debounce';
import {useStaticQuery, graphql} from "gatsby";
import SearchableTypes from './searchable-types';
import SearchableItems from './searchable-items';
import GlobalSearchForm from './form';
import ResultsList from './list';
import classnames from "classnames";
import ScrollArea from 'react-scrollarea/src/js/ScrollAreaWithoutCss';
import '../../../styles/scrollarea.css';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import {connect} from 'react-redux';

const SearchContext = React.createContext();


class GlobalSearch extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      items: [],
      isLoading: false
    };
    this.endpoint = `https://${process.env.GATSBY_WP_URL}/wp-json/wp/v2/search`;
    this.types = this.props.searchableTypes.map(t=>t.type);
    this.doRemoteSearch = this.doRemoteSearch.bind(this);
    this.scrollAreaRef = React.createRef();
  }

  doRemoteSearch = debounce((query) => {
    let searchArgs = {'per_page': [10]};
    if(query.textSearch && query.textSearch !== ''){
      searchArgs['search']= [query.textSearch];
    }
    if(this.types && this.types.length){
      searchArgs['subtype']=this.types;
    }
    const searchArgsSerialized = [];
    for(let k in searchArgs){
      if(searchArgs[k] && searchArgs[k].length)
        searchArgsSerialized.push(`${k}=${searchArgs[k].join(',')}`);
    }
    const params=searchArgsSerialized.join('&');
    this.setState(
      {isLoading: true},
      ()=>fetch(`${this.endpoint}?${params}`)
        .then(response=>response.json())
        .then(data => {
          let items = [];
          if(data && data.length){
            const matches =  data.map(d=>d.id);
            let by_wpid = {};
            const filtered_items = this.props.items
                  .filter(e=>matches.includes(e.wordpress_id))
                  .forEach(e=>{
                    by_wpid[e.wordpress_id.toString()]={...e.search, path:e.path};
                  });
            items = matches.map(e=>by_wpid[e.toString()]).filter(e=>e);
          }
          this.props.updateSearchItems(items)
        })
    );},250);
  
  textSearchUpdated(value){
    this.props.updateSearchString(value)
    if(value!=="" && value.length>=3){
      this.doRemoteSearch({textSearch:value});
    }else {
      this.setState({
        items:[]
      });
    }
  }
  componentDidUpdate(){
    if(this.scrollAreaRef.current){
      this.scrollAreaRef.current.scrollArea.refresh();
      this.scrollAreaRef.current.scrollArea.scrollTop();
    }
  }
  render(){
    const formClassName = classnames(
      'sticky pin-t',
      {"bg-white": this.props.variant && this.props.variant === 'white'}
    );
    return (
     
      <aside className="side-panel relative w-full ">
        <div className={formClassName}>
          <div className=" flex justify-around px-3 pt-3 z-50 mb-7">
            <GlobalSearchForm onUpdateTextSearch={value=>this.textSearchUpdated(value)} value={this.props.searchPanel.searchString} />
            <button onClick={this.props.close} className="ml-auto" onClick={this.props.onClose}><CloseIcon width={14} height={14} /></button>
          </div>
          <ScrollArea
            ref={this.scrollAreaRef}
            className={'scrollarea max-h-screen'}
            contentClassName={'scrollarea-content'}
            smoothScrolling
            speed={0.8}
            verticalContainerClassName={'scrollbar-container'}
            verticalContainerClassNameActive={'scrollbar-container--active'}
            verticalContainerClassNameHorizontal={'scrollbar-container--horizontal'}
            verticalContainerClassNameVertical={'scrollbar-container--vertical'}
            verticalScrollbarClassName={'scrollbar'}
            stopScrollPropagation={false}
          >
            <div className="content px-3 pb-8 mb-12">
              <ResultsList items={this.props.searchPanel.items || []}/>
            </div>
          </ScrollArea>
        </div>
      </aside>
    );
  }
}

const GlobalSearchComponent = (props) => {
  const items =  SearchableItems();
  const searchableTypes = SearchableTypes();
  
  return(
    <GlobalSearch {...props} searchableTypes={searchableTypes} items={items} onClose={ props.closeSearchPanel}/>
  );
};

const mapStateToProps = ({searchPanel}) => {
  return {searchPanel};
};
const mapDispatchToProps = dispatch => {
  return {
    closeSearchPanel: ()=>dispatch({type:`CLOSE_SEARCH`}),
    updateSearchString: (searchString) => dispatch({type: 'UPDATE_SEARCH_STRING', searchString}),
    updateSearchItems: (items) => dispatch({type: 'UPDATE_SEARCH_ITEMS', items })
  };
};

const ConnectedGlobalSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSearchComponent);

export default ConnectedGlobalSearch;
