import React from 'react'
import useOptions from './query'
import {
  ReactComponent as IosLogo
} from '../../assets/imgs/svg/appstore.svg'
import {
  ReactComponent as AndroidLogo
} from '../../assets/imgs/svg/google-play.svg'

const footerWidget = () => {
  const options = useOptions()
  return (
    <div className="flex flex-col justify-center">
			{ options?.links?.ios &&

				<a className="mb-4" href={options.links.ios} target="_blank" alt="Donwload Cefic News App on Apple Store" id="appdl_footer_ios">
				<IosLogo height="auto" width="150px"/>
				</a>
			}
		{ options?.links?.android &&

			<a href={options.links.android} target="_blank" alt="Download Cefic News App on Android Play Store" id="appdl_footer_android">
			<AndroidLogo  height="auto" width="150px" />
			</a>
		}
			</div>
  )
}

export default footerWidget
