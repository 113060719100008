export function buildIndex(collection, key){
  let index = {};
  const _key = key?key:'id';

  collection.map((item,id)=>{
    index[item[_key]] = id;
    return item;
  });
  return index;
};

export function  buildHierarchy(data){
  let hierarchy = {};
  data.map(d => {
    if(!hierarchy.hasOwnProperty(d.id)){
      hierarchy[d.id]=[];
    }
    if(d.parent && d.parent !== 0){
      if(!hierarchy.hasOwnProperty(d.parent)){
        hierarchy[d.parent]= [];
      }
      hierarchy[d.parent].push(d.id);
    }
    return d;
  });
  return hierarchy;
};


export function chunk(items, size){
  let res = [];
  let arr = [...items];
  while(arr.length){
    res.push(arr.splice(0,size));
  }
  return res;
};

export const trimExcerpt = (excerpt, max_length=180, filler='...') => {
  excerpt = excerpt.replace(/\n/g, '')
  excerpt = excerpt.replace(/<br \/>/g, '')
  const arr = excerpt.match(/<p>(.*)<\/p>/) || [];
  let new_excerpt = arr.length>1?arr[1]:arr[0];
  if(new_excerpt.length > max_length){
    new_excerpt = new_excerpt.slice(0, max_length-filler.length)+filler;
  }
  return new_excerpt;
};
