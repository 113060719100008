import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle} from '../components/common';
import {LocalPostsList as LPL} from '../components/posts/list';
import {WidgetAside} from '../components/common';
import RelatedCategories from '../components/RelatedCategories/wrapper';

export default ({data, pageContext}) =>{
  const category = data.wordpressCategory;
  const posts = data.allWordpressPost && data.allWordpressPost.edges ?data.allWordpressPost.edges.map(e=>{
    return {...e.node, slug: `${category.slug}/${e.node.slug}`};
  }): [];
  const parentCategory = category.ancestors && category.ancestors.length > 0?category.ancestors.slice(-1)[0]:false

  const related_categories = {
    display: category.acf && category.acf.related_categories && category.acf.related_categories.display?category.acf.related_categories.display:false,
    title: category.acf && category.acf.related_categories && category.acf.related_categories.title?category.acf.related_categories.title:'Related Categories'
  }
  //const contactBox = category.acf && category.acf.contact_box && category.acf.contact_box.show_contact_box?category.acf.contact_box:null;
  return(
    <Layout ancestors={category.ancestors} aside={WidgetAside()} pageContext={pageContext}>

      <div style={{maxWidth:968}} className="container mx-0 px-0">
        <div style={{maxWidth: 910}} className="container mx-0 px-0 ">
          <PageTitle title={category.title}/>
          { category.page_data?
            <div className="page-content" dangerouslySetInnerHTML={{__html:category.page_data.content}} />
            :
            <div className="page-content">
              <p>{category.description}</p>
            </div>
          }
        </div>
        <LPL posts={posts} openInPanel={false} showThumbnail/>
        {related_categories.display && parentCategory &&
         <RelatedCategories title={related_categories.title} exclude={[category]} rootCategory={parentCategory}/>
        }
      </div>
    </Layout>
  );
}
export const defaultPagequery = graphql`
query DefaultCategoryById($id: String!) {
  wordpressCategory(id:{eq:$id}){
    id
    title:name
    description
    slug
    path
    ancestors {
      slug
      path
      name
    }
    page_data {
      content
    }
    acf {
     related_categories {
       display
       title
     }
    }

  }
   allWordpressPost(sort: {order: ASC, fields: menu_order},filter:{status:{eq:"publish"}, categories:{elemMatch: {id:{eq:$id}}}}){
    edges{
      node{
        slug
        path
        title
        content
        excerpt
        date
        featured_media{
          localFile{
            verticalThumb:childImageSharp{
              fluid(maxWidth:240, maxHeight: 194){
                   ...GatsbyImageSharpFluid
              }
            }
            horizontalThumb:childImageSharp{
              fixed(width:217, height:164){
                   ...GatsbyImageSharpFixed
              }
           }
           banner:childImageSharp{
              fluid(maxWidth:674, maxHeight:300){
                  ...GatsbyImageSharpFluid
             }
            }
          }
        }
      }
    }
  }
}
`;
