import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle, WidgetAside} from '../components/common';
import {CeficShare as SocialShare} from '../components/social-shares';

export default (props) =>{
  const data = props.data.wordpressWpInteractiveMap;
  return(
    <Layout pageContext={props.pageContext} aside={WidgetAside({contactBox: {...data.contact_box}})} ancestors={data.ancestors}>
    <div style={{maxWidth:1025}} className="mx-0 px-0">
    <div style={{maxWidth: 734}} className="mx-0 px-0">
      <PageTitle title={data.title}/>
      <div className="wp_content" dangerouslySetInnerHTML={{__html:data.content}} />
      <SocialShare className="mt-10" url={data.path} context={props.pageContext.yoast} />
    </div>
      </div>
    </Layout>
  );
}
export const defaultInteractiveMapDetailQuery = graphql`
query DefaultInteractiveMapDetailById($id: String!) {
  wordpressWpInteractiveMap(id:{eq:$id}){
    path
    title
    slug
    wordpress_id
    content
    ancestors {
      name
      slug:path
    }
    contact_box {
      show_contact_box
      title
      text
      phone
      email
    }
  }
}
`;
