import React, {useEffect} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {useFormPages} from './allFormPages'
import {connect} from 'react-redux';
import {FormComponent} from '../../templates/template-form'

const mapStateToProps = ({panel})=>{
  return {panel};
};

const mapDispatchToProps = dispatch => {
  return {
    setPanelContent: (content, context) => dispatch({type:`SET_PANEL_CONTENT`, content:content, context:context }),
    openPanel: () => dispatch({type:`OPEN_PANEL`})
  };
};


const FormPagesInterceptor = (props) => {
  const formPages = useFormPages()
  useEffect(()=>{
    window.addEventListener('click', (e)=>{
      if(e.target.href && e.target.href.replace){
        const target_pathname  = e.target.href.replace(/^.*\/\/[^\/]+/, '')
        const match = formPages.find(page=>page.page.path===target_pathname)
        if(match){
          e.preventDefault();
          e.stopPropagation();
          props.setPanelContent(FormComponent({page:match.page, form:match.form }))
          props.openPanel()
        }
      }
    }, true)
  })
  return (
    <>
    {props.children}
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormPagesInterceptor)
