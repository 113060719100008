import React, {Fragment} from 'react';
import {MetaInfo, MetaInfoRegular} from '../typography';
import {Link} from '../link';
import {ReactComponent as ChevronRight} from '../../assets/icons/chevron-right.svg';
import classnames from 'classnames';
import Img from 'gatsby-image';


export class CommitteeMembersListItem extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover: false
    };
  }

  render(){
    if(this.props.show_link){
      return this.renderLink();
    }
    return(
      <div className="border-b mb-5 py-2" onMouseEnter={e=>this.setState({hover:true})} onMouseLeave={e=>this.setState({hover:false})}>
        {this.renderItem()}

      </div>
    );
  }
  renderItem(_titleClassName){
    const titleClassName = _titleClassName?_titleClassName:''
    return (
      <div className="flex">
        { this.props.show_thumbnail && this.props?.featured_media?.localFile?.childImageSharp?.fixed &&
          <div className="mr-4">
            <Img fixed={this.props.featured_media?.localFile.childImageSharp.fixed}  className="with-blue-filter"/>
          </div>
        }
        
      <div>
        <p className={titleClassName} dangerouslySetInnerHTML={{__html:this.props.title}}/>
        {this.props.acf.position && this.props.acf.position!== "" &&
         <div>
           <MetaInfoRegular dangerouslySetInnerHTML={{__html:this.props.acf.position}}/>
         </div>
        }
        {this.props.acf.company && this.props.acf.company !== '' && 
         <div className="mb-0">
           <MetaInfo dangerouslySetInnerHTML={{__html:this.props.acf.company}}/>
         </div>
        }
        {this.props.acf.other_titles && this.props.acf.other_titles !== '' && 
         <div>
           <MetaInfoRegular dangerouslySetInnerHTML={{__html:this.props.acf.other_titles}}/>
         </div>
        }
      </div>
      </div>)
  }
  renderLink(){
    const wrapperClassName= classnames(
      "border-b mb-5 py-2 flex items-center text-black ",
      {"cursor-pointer border-brand": this.state.hover},
      {"border-grey": !this.state.hover},
    );
    const titleClassName = classnames(
      "font-serif text-2base mb-0",
      {"text-brand": this.state.hover}
    );
    const chevronClassName = classnames(
      "fill-current",
      {"text-brand": this.state.hover}
    );

    return(
      <Link to={`${this.props.base_link}${this.props.slug}/`} className={wrapperClassName} onMouseEnter={e=>this.setState({hover:true})} onMouseLeave={e=>this.setState({hover:false})}>
        {this.renderItem(titleClassName)}
        <div className="ml-auto">
          <ChevronRight className={chevronClassName}/>
        </div>

      </Link>
    )
  }
}

export const CommitteeMembersGroup = (props) => {
  return (
    <Fragment>
      { props.name && props.name!=='' &&
        <p className="font-serif text-2xl" dangerouslySetInnerHTML={{__html:props.name}}/>
      }
      <div className="mb-10">
        {props.members.map(m=><CommitteeMembersListItem show_thumbnail={props.show_thumbnail} show_link={props.show_link} base_link={props.committee_slug} show_website={props.show_website} {...m} key={m.slug}/>)}
      </div>
    </Fragment>
  );
};

export const CommitteeBoard = (props) => {
  return(
    <div className="border-b border-grey mb-5">
      <p className="font-serif text-lg" dangerouslySetInnerHTML={{__html:props.title}}/>
      {props.chairman &&
       <div className="flex mb-1">
         <span className="text-bold mr-2">Chairman:</span>
         <span>{props.chairman.title}</span>
         {props.chairman.acf.company !== '' &&
          <span>, {props.chairman.acf.company}</span>
         }
       </div>
      
      }
      {props.executive_director &&
       <div className="flex mb-1">
         <span className="text-bold mr-2">Executive Director:</span>
         <span>{props.executive_director.title}</span>
         {props.executive_director.acf.company !== '' &&
          <span>, {props.executive_director.acf.company}</span>
         }
       </div>
      }
    </div>
  );
}
