import React from 'react'
import {FieldWrapper} from './wrapper'
import {Field} from 'formik'

export const TextAreaInput = (props) => {
  return(
    <FieldWrapper {...props}>
      <Field
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        onChange={props.onChange}
        rows={6}
        component="textarea"
        className="appearance-none bg-transparent border-none w-full  text-grey-darker mr-3  leading-tight focus:outline-none"
      />
    </FieldWrapper>
  )
}
