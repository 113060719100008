import React, {Fragment} from 'react';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';
import {ReactComponent as ChevronUp} from '../../assets/icons/chevron-up.svg';
import {StaticQuery, graphql} from 'gatsby';

import classnames from 'classnames';
import {Link} from "../link";
const mainNavQuery = graphql`
query  {
menu: allWordpressMenusMenusItems(filter:{slug:{eq:"main"}}){
  edges{
    node{
      items{
        wordpress_id
        title
        type:object
        url
        slug
        parent_id: menu_item_parent
        order:menu_order
        description
        classes
      }
    }
  }
}}
`;

class MainMenuMobileItem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen: false,
      isActive: false,
    };
  }
  componentDidMount(){
    const location = document.location.pathname.split('/').filter(i=>i!=='');
    let isOpen = this.state.isOpen;
    let isActive = this.state.isActive;
    if(location.includes(this.props.slug)){
      isOpen = true;
    }
    this.setState({isOpen: isOpen, isActive: document.location.pathname === `/${this.props.slug}`});

  }
  render () {
    const children = this.props.allItems?this.props.allItems.filter(i=>parseInt(i.parent_id) === this.props.wordpress_id):[];
    const classes = classnames([
      'flex flex-wrap px-7  justify-end items-center', //base classes
      {
        'border-b border-grey-lightest text-sm py-5':  this.props.variant !== 'leaf',
        "px-5": children.length > 0,
        "px-16": children.length === 0 || this.props.variant === 'leaf',
        'text-xs mt-1 py-2':  this.props.variant === 'leaf',
        'text-brand' : this.state.isOpen || this.state.isActive
      }
    ]);
    const linkClasses = classnames([
      "uppercase text-right",
      {
      'text-black-menu': !(this.state.isOpen || this.state.isActive),
      'text-brand': this.state.isOpen || this.state.isActive
      },
      this.props.classes
    ]);

    return (
      <Fragment>
      <div className={classes}>
        <Link  to={this.props.slug} className={linkClasses} dangerouslySetInnerHTML={{__html:this.props.title}}/>
        {children.length > 0 &&
         <button className="ml-5 focus:border-0" onClick={e=>this.setState({isOpen:!this.state.isOpen})} style={{color:'inherit'}}>
         {
           this.state.isOpen ?
             <ChevronUp width={20} height={20} className="fill-current"/>
             :
           <ChevronDown width={20} height={20}/>
         }
         </button>
        }
      </div>
      {children.length >0 && this.state.isOpen &&
       <div className="w-100 border-b ">
          {children.map(c=>(<MainMenuMobileItem variant="leaf" key={c.slug} {...c} allItems={this.props.items}/>))}
       </div>
      }
      </Fragment>
    );
  }
}

export class MainMenuMobile extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return(
      <nav>
        {this.props.items.length && this.props.items.filter(i=>parseInt(i.parent_id)=== 0).map(i=>(<MainMenuMobileItem key={i.slug} {...i} allItems={this.props.items} />))}
      <hr className="absolute border-brand border-t-4 pin-r" style={{transform:'translateY(-250%)', width:57}}/>
      </nav>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={mainNavQuery}
    render= {
      data =>{
        return(<MainMenuMobile items={data.menu.edges[0].node.items}/>);
      }
    }
  />
);
