import React, {Fragment} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import PageMenu from '../components/menu/page-menu';
import PostList from '../components/posts/list';
import {PostSlider} from '../components/posts/slider';
import {MobilePostPreview} from '../components/posts/previews';
import {PageTitle} from '../components/common';
import MediaQuery from 'react-responsive';
import ContactBox from '../components/common/contactbox';
import classnames from 'classnames';
import {CeficShare} from '../components/social-shares';
import FlexibleContent from '../components/flexible-builder';


class NavAside  extends React.Component{
  constructor(props){
    super(props);
    this.state={
      stickyMenu: false,
      stickyContactBox: false
    };

    this.menuRef = React.createRef();
    this.contactBoxRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);

  }
  updateDimensions(){
    let state = {...this.state};
    if (this.props.contactBox){
      const availableHeight = Math.min(this.wrapperRef.current.clientHeight, window.innerHeight) ;
      const menuHeight =this.menuRef.current.clientHeight;
      let contactBoxHeight = this.contactBoxRef.current.clientHeight;

      if(availableHeight > (menuHeight + contactBoxHeight)){
        state = {
          stickyMenu: true,
          stickyContactBox: true
        };
      }

    }
    this.setState({...state});
  }
  componentDidMount(){

    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.updateDimensions);
  }
  render(){
    const menuClasses = classnames(
      'bg-white',
      {
        'lg:sticky lg:pin-t':this.state.stickyMenu
      }
    );
    const boxClasses = classnames(
      'bg-white border-t',
      {
        'lg:pin-b lg:sticky': this.state.stickyContactBox
      }
    );
    return(
      <div className="relative h-full flex flex-col justify-between" ref={this.wrapperRef}>
        <div className={menuClasses} ref={this.menuRef}>
          <PageMenu menu={this.props.menu}/>
        </div>
        {this.props.contactBox &&
         <ContactBox className={boxClasses} {...this.props.contactBox} ref={this.contactBoxRef}/>
        }
      </div>
    );
  }
}


const get_post_slug = (post) => {
  if(post.primary_category){
    return `${post.primary_category.slug}/${post.slug}`;
  }
  return `posts/${post.slug}`;
}
export default (props) =>{
  const data = props.data.wordpressPage;
  const sliderPosts = props.data.sliderPosts?props.data.sliderPosts.edges.map(e=>{return {...e.node, slug:get_post_slug(e.node)};}):undefined;
  const subitems = props.data.wordpressPage.subpages;
  let subNav = false;
  const feed_post_types = data.acf.post_types;
  const feed_categories = data.acf.categories;
  if(subitems && subitems.length){
    const menu = {items:subitems.map((item) => ({
      title: item.name,
      slug: item.slug,
      path: item.path,
    }))};
    const contactBox = data.acf.contact_box && data.acf.contact_box.show_contact_box?data.acf.contact_box:null;
    subNav = React.createElement(NavAside,{menu:menu, contactBox:contactBox});
  }
  return(
    <Layout pageContext={props.pageContext} aside={subNav} ancestors={data.ancestors}>
      <div style={{maxWidth:1080}} className="container mx-0 px-0">
        <div style={{maxWidth: 910}} className="container mx-0 px-0">
          <PageTitle title={data.title}/>
          { data.content?
            <div className="page-content"  dangerouslySetInnerHTML={{__html:data.content}} />
            :
            <div className="page-content" >
              { data.category && data.category.description!=="" &&
                <p>{data.category.description}</p>
              }
            </div>
          }
    { data.display_sharing_toolbar &&
      <CeficShare url={props.data.wordpressPage.path} context={props.pageContext.yoast} className="mt-10"/>
    }
        </div>
        {sliderPosts && sliderPosts.length>0 &&
         <Fragment>
           <MediaQuery minWidth={769}>
             <PostSlider posts={sliderPosts} showThumbnail={true}/>
           </MediaQuery>
           <MediaQuery maxWidth={768} >
             {sliderPosts.map(p => (
               <MobilePostPreview {...p}/>
             ))}
           </MediaQuery>
         </Fragment>
        }
    {data?.widgets?.widgets?.length > 0 &&
     <FlexibleContent data={data.widgets.widgets}/>
    }

      </div>
    </Layout>
  );
}
export const RoutingPagequery = graphql`
query RoutingPageById($id: String!, $category_id: String!) {
  wordpressPage:wordpressPage(id:{eq:$id}){
    slug
    title
    content
    path
    display_sharing_toolbar
    ancestors {
      slug
      name:title
      path
    }
widgets{
        ...FlexbileContentBuilder

}

    acf {
      post_types
      categories{
        term_id
        taxonomy
      }
      contact_box{
          show_contact_box
          title
          text
          phone
          email
        }
    }
    category{
... on Node {
      ... on wordpress__CATEGORY{
      name
      description
      wordpress_id
      }
      ... on wordpress__wp_ccan_category{
      name
      description
      wordpress_id
      }

    }
}
    subpages {
      name
      slug
      path
    }
  }

  sliderPosts: allWordpressPost(filter:{categories:{elemMatch: {id:{eq:$category_id}}}},, sort:{fields:menu_order}){
    edges{
      node{
        id
        slug
        title
        content
        excerpt
        date
        path
        primary_category{
          wordpress_id
          slug
        }
        featured_media{
          localFile{
            verticalThumb:childImageSharp{
              fluid(maxWidth:240, maxHeight: 194){
                   ...GatsbyImageSharpFluid
              }
            }
            horizontalThumb:childImageSharp{
              fixed(width:217, height: 164){
                   ...GatsbyImageSharpFixed
              }
           }
           banner:childImageSharp{
              fluid(maxWidth:674, maxHeight:300){
                  ...GatsbyImageSharpFluid
             }
            }
          }
        }
      }
    }
  }

}
`;
