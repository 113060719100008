import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { CheckboxInput } from "../../forms/fields";

class ContentTypesFiltersComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contentTypes: [],
			byId: {},
		};
	}
	UNSAFE_componentWillMount() {
		const allContentTypes = this.props.contentTypes.map((t) => {
			t.checked = this.props.selected.includes(t.wordpress_id);
			return t;
		});
		let byId = {};
		allContentTypes.map((t, index) => {
			byId[t.wordpress_id] = index;
		});
		this.setState({
			contentTypes: allContentTypes,
			byId: { ...byId },
		});
	}
	toogleFilter(id) {
		const filters = this.state.contentTypes.map((t) => {
			if (t.wordpress_id === id) {
				t.checked = !t.checked;
			}
			return t;
		});
		this.setState(
			{
				contentTypes: filters,
			},
			this.props.updateHandler(
				this.state.contentTypes
					.filter((t) => t.checked)
					.map((t) => t.wordpress_id)
			)
		);
	}
	getById(id) {
		return this.state.contentTypes[this.state.byId[id]];
	}
	render() {
		return (
			<div>
				{this.state.contentTypes.map((c) => (
					<CheckboxInput
						{...c}
						key={"content-type-" + c.wordpress_id}
						onChange={(e) => this.toogleFilter(c.wordpress_id)}
					>
						<span dangerouslySetInnerHTML={{ __html: c.name }} />
					</CheckboxInput>
				))}
			</div>
		);
	}
}

export const ContentTypesFilters = (props) => (
	<StaticQuery
		query={graphql`
			query allNonEmptyContentTypes {
				allWordpressWpContentType(filter: { count: { gt: 0 } }) {
					edges {
						node {
							wordpress_id
							name
							slug
							count
						}
					}
				}
			}
		`}
		render={(data) => {
			let filters = data.allWordpressWpContentType.edges
				.map((e) => e.node)
				.filter((e) => {
					if (props.restrict_to && props.restrict_to.length) {
						return props.restrict_to.includes(e.wordpress_id);
					}
					return true;
				})
				.sort((a, b) =>
					a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
				);

			return (
				<ContentTypesFiltersComponent
					contentTypes={filters}
					selected={props.selected}
					updateHandler={props.updateHandler}
				/>
			);
		}}
	/>
);
