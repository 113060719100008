import React from 'react'
import {
  useStaticQuery,
  graphql
} from "gatsby"

const useNewsAppOptions = () => {
  const {
    options
  } = useStaticQuery(
    graphql `
		query {
			options:wordpressAcfOptions {
				options {
					news_app_popup {
						is_active
            topbar_title
            delay_before_showing_again
            popup_title
            popup_content
						links {
							ios
							android
						}
					}
				}
			}
		}
`
  )
  return options?.options?.news_app_popup
}

export default useNewsAppOptions;
