import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
//import './transition.css';
import Select from 'react-select';

export default class DropDown extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showItems: true
    };
  }
  render(){
    return(
      <div onMouseLeave={e=>{this.setState({showItems:false});}} onMouseEnter={e=>{this.setState({showItems:true});}}>
        <h1 >{this.props.title}</h1>
        <div className="relative overflow-hidden">
          <ReactCSSTransitionGroup
            transitionName="slide-down"
            transitionEnter={false}
            transitionLeave={false}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
            transitionAppearTimeout={2000}
            transitionAppear={true}
          >
             <ul key={this.props.title}>
               {this.props.items.map((i, index)=>(<li key={index}>{i}</li>))}
             </ul>
          </ReactCSSTransitionGroup>
        </div>
      </div>
    );
  }
}

const selectStyles = {
  control: (styles,{isFocused}) => ({
    ...styles,
    borderRadius:0,
    // This line disable the blue border
    boxShadow: "0 0 0 0.6px #D6D7D7",
    borderColor:  "#F47B20" ,
    borderTop:0,
    borderLeft: 0,
    borderRight: 0,
    '&:hover': {
      borderColor: isFocused ? "#F47B20" : null,
      
    }
  }),
  indicatorSeparator: style =>({display:null}),
  menu: styles => ({...styles, borderRadius:0, marginTop:0}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      backgroundColor: isFocused?'#EAEAEB':null,
      display:isSelected?'none':null,
    };
  }
};

export const SelectDropDown = ({isSearchable, isClearable, options, onChange, ...otherprops}) => {
  return (
    <div className="my-5 w-full md:w-64 md:mr-5">
    <Select
      className="cefic-select-container"
      classNamePrefix="cefic-select"
      isSearchable={isSearchable}
      isClearable={isClearable}
      options={options}
      onChange={onChange}
      styles={selectStyles}
      
      {...otherprops}
      />
    </div>
)
  
}
