import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import {Link} from '../link';



const MenuItem = (props) => {
  return(
    <li className="mr-6 text-white mb-2">
      <Link className="menu uppercase no-underline text-2xs tracking-wide  hover:font-bold" to={props.slug} target={props.target} url={props.url} style={{color:'inherit'}}>{props.title}</Link>
    </li>
  );
};

export const ToolbarBottomMenu = () => (
  <StaticQuery query={
    graphql`
query ToolbarBottomMenuQuery {
menu: allWordpressMenusMenusItems(filter:{slug:{eq:"bottom_toolbar_menu"}}){
  edges{
    node{
      items{
        wordpress_id
        title
        type:object
        url
        slug
        order:menu_order
      }
    }
  }
}}
`} render={data=> {
  const menu = data.menu.edges[0].node;
  return(
    <nav id="toobar-bottom-nav" className="bottom-navbar flex-1">
      <ul className="list-reset lg:flex justify-around">
        {menu.items.map((menu, index)=> (
          <MenuItem {...menu} key={index}/>
        ))}
      </ul>
    </nav>
  );
}}/>
);
