import React, {
  useState,
  useEffect,
} from 'react'
import useOptions from './query'
import {
  ReactComponent as IosLogo
} from '../../assets/imgs/svg/appstore.svg'
import {
  ReactComponent as AndroidLogo
} from '../../assets/imgs/svg/google-play.svg'
import {
  ReactComponent as AppPopupLogo
} from '../../assets/imgs/svg/app-popup.svg'
import {
  ReactComponent as CloseIcon
} from '../../assets/icons/close.svg'

import './popup.css'

const PopupComponent = ({
  options,
  closeHandler
}) => {

  return (
    <div
      id="news_app__popup"
      className="border shadow-lg bg-white fixed overflow-y-visible z-10 rounded lg"
    >
      <div className="absolute"
           style={{
             top:"0",
             right:"50%",
             transform: "translate(50%,-50%)"
           }}>
        <AppPopupLogo width="100px" height="100px"/>
      </div>
      <button className="absolute cursor-pointer"
           style={
             {
               top: 16,
               right: 16
             }
           }
              onClick={()=>closeHandler()}
      >
        <CloseIcon/>
      </button>
      <div className="px-8 pt-16 pb-24 text-center">
        <h2 className="font-serif text-brand mb-5"> { options.popup_title }</h2>
        <p className="uppercase text-black font-medium mb-5" dangerouslySetInnerHTML={{__html: options.popup_content}}/>
        <div className="flex flex-column justify-center">
          <div className="flex flex-col justify-center">
			      { options?.links?.ios &&
				      <a className="mb-4" href={options.links.ios} target="_blank" alt="Donwload Cefic News App on Apple Store" id="appdl_popup_ios">
				        <IosLogo height="auto" width="150px"/>
				      </a>
			      }
		        { options?.links?.android &&
			        <a href={options.links.android} target="_blank" alt="Download Cefic News App on Android Play Store" id="appdl_popup_android">
			          <AndroidLogo  height="auto" width="150px" />
			        </a>
		        }
			    </div>
        </div>
      </div>
		</div>
  )
}

const Popup = () => {
  const options = useOptions()
  const [display, setDisplay] = useState(false)

  const localstorageKey = 'newsapp_popup';

  useEffect(()=>{
    const popupInfo = localStorage.getItem(localstorageKey);
    if(popupInfo){
      try {
        const initDate = JSON.parse(popupInfo)?.closedAt
        const targetDate = new Date(initDate + 24*60*60*1000*options?.delay_before_showing_again??0) // closedAt + n days
        setDisplay(targetDate < new Date())
        return
      }catch {
        setDisplay(true)
      }
    }else {
      setDisplay(true)
    }
  }, [])

  const onClose = ()=>{
    setDisplay(false);
    localStorage.setItem(localstorageKey, JSON.stringify({closedAt: Date.now()}))
  }

  return (
    <>
      {
        display &&
          <PopupComponent
             options={{...options}}
             closeHandler= {onClose}
          />
      }
    </>
  )
}

export default Popup;
