import React, {useState} from "react";
import {PSubTitle, SubSubTitle, PBody, MetaInfo} from '../../typography';
import classnames from 'classnames';
import Img from "gatsby-image"
import {ReactComponent as Download } from '../../../assets/icons/file-download.svg';
import {ReactComponent as  ChevronRight } from '../../../assets/icons/chevron-right.svg';
import Moment from 'react-moment';
import {Link } from '../../link';



const SupportingDocumentThumbnail = ({featured_media, default_image, hover}) => {
  let thumbnail = featured_media?.localFile?.smallHThumb;

  return (
    <div className="relative flex-no-shrink  " style={{width:97, height:138}}>
      {!!thumbnail ?
       <Img fixed={thumbnail.fixed} style={{margin:'0 auto'}}/>
       :
       <svg className="fill-current block text-grey-lightest flex-no-shrink" width="100%" height="100%">
         <rect width="100%" height="100%"/>
       </svg>
      }
      {hover &&
       <svg className="block absolute opacity-75 pin-y pin-l fill-current text-brand flex-no-shrink z-50" width='100%' height='100%'>
         <rect width="100%" height="100%"/>
       </svg>
      }
    </div>
  );

}

const SupportingDocumentItem = ({title, description, type, date, path, is_download, is_external, featured_media, tagline_mode}) => {
  const [state, setState] = useState({
    hover:false
  });
  const classesTitle = classnames(
    {
      'text-grey-darkest': !state.hover,
      'text-brand': state.hover
    }
  )
  const classesChevron = classnames(
    'fill-current',
    {
      'text-grey-darkest': !state.hover,
      'text-brand': state.hover
    }
  );

  const className=classnames(
    `supporting-document__item  relative flex items-top justify-center  border-b`,
    {'hover:cursor-pointer': state.hover}
  );

  // disable description, the markup is kept because the decision to remove them might be reverted in the future, see issue #390

  description = false;
  return (
    <Link to={path}>
      <div className={className} onMouseEnter={()=>setState({hover:true})} onMouseLeave={()=>setState({hover:false})}  style={{maxWidth:735}}>
      <SupportingDocumentThumbnail
        featured_media={featured_media}
      />
      <div className="px-15px py-2 flex flex-col jutify-between overflow-auto flex-grow">
        <div>
          <SubSubTitle hover={state.hover?state.hover:undefined} dangerouslySetInnerHTML={{__html:title}} />
        </div>
        {!!description &&
         <div className="font-sans font-light text-base text-black tracking-02 leading-21"  dangerouslySetInnerHTML={{__html:description}} />
        }
        {tagline_mode ==="type" && !!type &&
         <div className="mt-auto">
           <MetaInfo>{type}</MetaInfo>
         </div>
        }
        { tagline_mode==="date" && !!date &&
          <div className="mt-auto">
            <MetaInfo> <Moment format="DD/MM/YYYY">{date}</Moment></MetaInfo>
          </div>

        }

      </div>
      <div className="flex items-center ml-3">

        {is_download &&

         <Download width={20} height={20} className={`${state.hover?'text-brand':''} fill-current text-grey-darkest hover:text-brand`}/>

        }
        {!is_download && is_external &&
         <>
           {state.hover &&
            <span>Go to website</span>
           }
           <ChevronRight width={20} height={20} className={classesChevron}/>
         </>
        }
        {!is_download && !is_external &&


           <ChevronRight width={20} height={20} className={classesChevron}/>

    }
      </div>


    </div>

   </Link>
  );
};


const SupportingDocuments = (props) => {
  const classes = `mt-10 supporting-document`
  return (
    <section className={classes}>
      {props?.title &&
       <PSubTitle className="mb-7" dangerouslySetInnerHTML={{__html:props.title}}/>
      }

    {
      props?.items?.length>0  && props?.items?.map(item=>{
        return (
          <SupportingDocumentItem {...item} tagline_mode={props.options.tagline_mode} key={item.slug}/>
        )
      }
      )

    }
    </section>

  );
};

export default SupportingDocuments;
