import React from 'react'
import useOptions from './query'
import {
  ReactComponent as IosLogo
} from '../../assets/imgs/svg/appstore-small.svg'
import {
  ReactComponent as AndroidLogo
} from '../../assets/imgs/svg/android-small.svg'

const topbarWidget = () => {
  const options = useOptions()
  return (
    <div className="flex items-center justify-center">
      { options?.topbar_title &&
        <span className="uppercase font-medium text-white mr-4">{options.topbar_title}</span>
      }
			{ options?.links?.ios &&

				<a className="mr-4 leading-none" href={options.links.ios} target="_blank" alt="Donwload Cefic News App on Apple Store" id="appdl_topbar_ios">
				<IosLogo height="16px" width="16px"/>
				</a>
			}
		{ options?.links?.android &&

			<a className="leading-none" href={options.links.android} target="_blank" alt="Download Cefic News App on Android Play Store" id="appdl_topbar_android">
			<AndroidLogo  height="16px" width="16px" />
			</a>
		}
			</div>
  )
}

export default topbarWidget
