import React from 'react';
import {graphql} from 'gatsby';
import {WidgetAside} from '../components/common';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import {MetaInfo, PBody, PageTitle as TitleHeading} from '../components/typography';
import {PageTitle} from '../components/common';
import {CommitteeMembersGroup} from '../components/committees/';
import {CeficShare} from '../components/social-shares';
const PeopleProfileHeader = (props) => (
  <div className="flex mb-10">
    {props.thumbnail &&
     <Img fixed={props.thumbnail.fixed} className="mr-10"/>
    }
    <div className="mt-auto">
      <PageTitle title={props.title}/>
      {props.acf.position && props.acf.position !== '' &&
      <div>
        <MetaInfo>{props.acf.position}</MetaInfo>
       </div>
      }
      {props.acf.company && props.acf.company !== '' &&
      <div>
        <MetaInfo>{props.acf.company}</MetaInfo>
       </div>
      }
    </div>
  </div>
);


export default (props) => {
  const committee = {...props.data.active_committee};
  const ancestors = props.pageContext.ancestors;
  let committee_members = [];
  committee.member_groups.map(mg => {committee_members = committee_members.concat(mg.members);});
  const thumbnail =  props.data.profile.featured_media && props.data.profile.featured_media.localFile?props.data.profile.featured_media.localFile.childImageSharp:false;
  return(
    <Layout aside={WidgetAside()} ancestors={ancestors} pageContext={props.pageContext}>
      <div style={{maxWidth:1025}} className="">
        <div style={{maxWidth: 734}} className="mb-20">
          <PeopleProfileHeader {...props.data.profile} thumbnail={thumbnail}/>
          <div dangerouslySetInnerHTML={{__html:props.data.profile.content}}/>
          <CeficShare className="mt-10" url={props.data.profile.path} context={props.pageContext.yoast}/>
        </div>
        <aside>
          <div className="mb-5">
            <TitleHeading>Other Members of {committee.title}</TitleHeading>
          </div>
          <CommitteeMembersGroup
    show_link={true}
    show_thumbnail = { committee?.acf?.template_options?.show_members_picture}
            committee_slug={committee.path}
            members={committee_members.filter(m=>m.wordpress_id!==props.data.profile.wordpress_id)}
          />

        </aside>
      </div>
    </Layout>
  );
}

export const PeopleProfileQuery = graphql`
query PeopleProfileQuery($wordpress_id:Int!, $committee_id:String!){
  profile:wordpressWpPeople(wordpress_id:{eq:$wordpress_id}){
    wordpress_id
    title
    content
    path
    featured_media{
      localFile{
        childImageSharp{
          fixed(width:272, height:303){
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    acf {
      company
    }
  }
  active_committee:wordpressWpCommittee(id:{eq:$committee_id}){
    title
    slug
    path
    acf {
      template_options {
        show_members_picture
      }
    }
    member_groups{
      members {
        wordpress_id
        title
        slug
        path
        featured_media{
          localFile {
            childImageSharp{
              fixed(width: 90, height: 100, quality: 100){
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        acf{
          company
          position
          other_titles
        }
      }
    }
  }
}
`
