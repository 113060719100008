import React from 'react'
import classnames from 'classnames'
import style from './style.css'
export const FieldWrapper = (props) => {
  let error_message = false
  if(props.error){
    error_message = props.error
  }else if(props.status){
    error_message = props.status
  }
  const className = classnames(
    props.className,
    'formfield',
    {'has-errors': error_message && props.touched}
  );
  return (
    <div className="formfield__wrapper">
      <div className={className}>
        {props.label && <label>{props.label}</label>}
        <div className="formfield__field">
          {props.children}
        </div>
      </div>
      {error_message && props.touched && (
        <div className="formfield__error text-red font-bold">{error_message}</div>
      )}
    </div>
  )
}
