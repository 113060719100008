import React, { Component } from 'react'
import he from 'he'
import NewsSelect from './filter';

import {LocalPostsList as LPL} from '../posts/list';

export class NewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTag: '',
      tags: []
    }
    this.handleTagChange = this.handleTagChange.bind(this);
  }
  componentDidMount() {
    let tags = new Set();
    this.props.posts.forEach((post) => {
      if(!post.tags) return;
			post.tags.forEach((tag)=> {
				tags.add(he.decode(tag.name))
			})
    });
    tags = [...tags].sort()
    tags = ['No Filter', ...tags]
    this.setState({
      tags: tags
        .map((tag) => ({value: tag, label: tag}))
    })
  }
  handleTagChange(selectedTag) {
    this.setState({ selectedTag });
  }
  render() {
    const {posts, ...rest} = this.props
    const postsFiltered = posts.filter((post) => {
      if(this.state.selectedTag === '' || this.state.selectedTag.value === 'No Filter') return true;
      if(!post.tags) return false;
			return post.tags.find(t => t.name === this.state.selectedTag.value);
    })
    return (
      <div>
        <div className="my-5 w-100 md:w-64 px-15px">
          <NewsSelect
            value={this.state.selectedTag}
            onChange={this.handleTagChange}
            tags={this.state.tags}
          />
        </div>
        <LPL posts={postsFiltered} {...rest}/>
      </div>
    )
  }
}

export default NewList
