import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

export const AddressWidget = () => (
  <StaticQuery query = {
    graphql`
      query AddressWidgetOptions {
        wordpressAcfOptions {
          options {
           logo_tag_line
           postal_address
           logo_long{
            localFile{
              childImageSharp{
                fixed(width:100){
                   ...GatsbyImageSharpFixed
                }
              }
            }
          }
         }
       }
      }
     `
  } render={data => (
    <div className="mb-5 text-center lg:text-left flex-1">
      <Img fixed={data.wordpressAcfOptions.options.logo_long.localFile.childImageSharp.fixed}/>
      <p className="text-grey font-bold text-xs mb-2">{data.wordpressAcfOptions.options.logo_tag_line}</p>
      <p className="text-grey text-xssuu">{data.wordpressAcfOptions.options.postal_address}</p>
    </div>
  )}/>

);
