import React, {Fragment} from 'react';
import MediaQuery from 'react-responsive';
import {graphql, useStaticQuery} from 'gatsby';
import {MobileJobPreview, HorizontalJobPreview} from './previews';

export const AllJobs = (props) => {
  const data = useStaticQuery(graphql`
query{
      jobs:allWordpressWpJobs(filter:{status:{eq:"publish"}}){
      edges{
        node{
          title
          content
          excerpt
          slug
          path
          yoast: yoast_head
          deadline(formatString: "MMMM Do YYYY")
        }
      }
    }}
  `);
  return data.jobs.edges.map(e=>e.node);
};

export default  (props) => {
  const jobs = AllJobs();
  return(
    <Fragment>
      <MediaQuery minWidth={768} >
        {jobs.map(p => (
          <div className="border-b job" key={p.slug}>
            <HorizontalJobPreview  {...p} openInPanel={props.openInPanel} showThumbnail={props.showTumbnail?true:props.showThumbnail}/>
          </div>
        ))}
      </MediaQuery>

      <MediaQuery maxWidth={767} >
        {jobs.map(p => (
          <div className="border-b job" key={p.slug}>
          <MobileJobPreview {...p}/>
          </div>
        ))}
      </MediaQuery>
    </Fragment>
  );
};
