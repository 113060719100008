import React, { useRef, useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import { PageTitle } from "../components/common";
import { CeficShare as SocialShare } from "../components/social-shares";
import FlexibleContent from "../components/flexible-builder";
import { withFilteredList } from "../components/posts/providers";
import { GridPostsList } from "../components/posts/list";
import { GreyAside } from "../components/aside";
import { InteractiveMapComponent } from "../components/interactivemap/map";

export default ({ data, pageContext }) => {
	const [selectedCountry, setSelectedCountry] = useState(null);
	const gridRef = useRef(null);

	let filters = [];
	const gql_filters = [];

	if (data.wordpressPage?.rca_grid_settings?.outcome_filter?.show_filter) {
		gql_filters.push({
			f_key: "outcome",
			selected_term:
				data.wordpressPage?.rca_grid_settings?.outcome_filter?.default_value ??
				null,
			placeholder:
				data.wordpressPage?.rca_grid_settings?.outcome_filter
					?.select_outcome_placeholder ?? "Select...",
		});
	}
	if (data.wordpressPage?.rca_grid_settings?.category_filter?.show_filter) {
		gql_filters.push({
			f_key: "categories",
			placeholder:
				data.wordpressPage?.rca_grid_settings?.category_filter
					?.select_category_placeholder ?? "Select...",
		});
	}

	gql_filters.push({
		f_key: "countries",
		placeholder:
			data.wordpressPage?.rca_grid_settings?.select_country_placeholder ??
			"Select...",
	});
	filters = gql_filters
		.map(({ f_key, placeholder, selected_term = null }, idx) => {
			const nodes = data?.[f_key]?.nodes;
			const tax = nodes?.[0]?.taxonomy?.slug;
			const matchKey = f_key;
			if (nodes?.length > 0 && !!tax) {
				return {
					taxonomy: tax,
					matchKey: matchKey,
					placeholder: placeholder,
					//					placeholder: grid_settings?.[f_key]?.placeholder,
					selected_term,
					order: idx,
					terms: nodes.map((n) => {
						return { value: n.id, label: n.name };
					}),
					display_filter: true,
				};
			}
		})
		.filter((f) => !!f && f?.display_filter);

	let posts = data?.posts?.nodes ?? [];
	let valid_countries = new Set();

	const editions =
		data.wordpressPage?.rca_grid_settings?.edition_to_display ?? [];
	if (editions.length) {
		posts = posts.filter((p) =>
			p.taxonomy_rca_edition?.some((e) => editions.includes(e))
		);
	}
	const cats_to_display =
		data.wordpressPage?.rca_grid_settings?.categories_to_display;
	if (cats_to_display?.length) {
		posts = posts.filter((p) => {
			const p_cats = p.categories.map((c) => c.id);
			for (const c_id of p_cats) {
				if (cats_to_display.includes(c_id)) {
					p.countries.forEach((c) => {
						valid_countries.add(c.id);
					});
					return true;
				}
			}
			return false;
		});
	}

	const map_countries = data.countries?.nodes
		?.filter((c) => valid_countries.has(c.id))
		.map((c) => ({
			country_code: c.acf.country_code,
			tooltip_text: c.name,
			id: c.id,
		}));

	const onMapSelect = (term) => {
		gridRef.current.onChangeFilter(filters[0], {
			value: term.id,
			label: term.tooltip_text,
		});
	};

	const onGridSelectFilter = (taxonomy, term) => {
		if (taxonomy.taxonomy === "taxonomy-country") {
			//update map
			if (term) {
				const match = map_countries.find((c) => c.id === term.value);
				if (match) {
					setSelectedCountry(match.country_code);
				} else {
					setSelectedCountry(null);
				}
			} else {
				setSelectedCountry(null);
			}
		}
	};

	const FilteredGridList = withFilteredList(GridPostsList);

	return (
		<Layout
			pageContext={pageContext}
			aside={GreyAside({ aside: data.wordpressPage.aside })}
			ancestors={data.wordpressPage.ancestors}
		>
			<div style={{ maxWidth: 1080 }} className=" container mx-0 px-0">
				<div style={{ maxWidth: 910 }} className="container mx-0 px-0">
					<PageTitle title={data.wordpressPage.title} />
					<div
						className="wp_content"
						dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
					/>
					{data.wordpressPage?.rca_grid_settings?.display_map && (
						<InteractiveMapComponent
							src={data.wordpressPage.rca_grid_settings.map.source_url}
							active_countries={selectedCountry ? [selectedCountry] : []}
							clickable_regions={map_countries}
							open_in_panel={false}
							onClickHandler={onMapSelect}
						/>
					)}
					{posts.length > 0 && (
						<FilteredGridList
							ref={gridRef}
							filters={filters}
							onSelectTerm={onGridSelectFilter}
							posts={posts.map((p) => {
								const tags_label = `${
									p.outcome?.map((o) => o.name)?.length
										? `<span style="color:#3BB273"> ${p.outcome
												?.map((o) => o.name)
												.join(", ")}</span>  - `
										: ""
								}${
									!!p.categories.length
										? `${p.categories.map((c) => c.name).join(",")} - `
										: ""
								}${p.countries.map((c) => c.name).join(",")} `;
								return {
									...p,
									tags_label: tags_label,
								};
							})}
							analyticsName={"rca_post"}
						/>
					)}

					{data.wordpressPage.display_sharing_toolbar && (
						<SocialShare
							url={pageContext.yoast.canonical}
							context={pageContext.yoast}
							className="my-10"
						/>
					)}
				</div>

				{data.wordpressPage?.widgets?.widgets.length > 0 && (
					<FlexibleContent data={data.wordpressPage.widgets.widgets} />
				)}
			</div>
		</Layout>
	);
};
export const defaultPagequery = graphql`
	query RCAwardsGridPageById($id: String!) {
		categories: allWordpressWpTaxonomyRcaCategory(
			filter: { count: { gt: 0 } }
			sort: { fields: name, order: ASC }
		) {
			nodes {
				taxonomy {
					name
					slug
				}
				name
				count
				id: wordpress_id
			}
		}
		countries: allWordpressWpTaxonomyCountry(
			filter: { count: { gt: 0 } }
			sort: { fields: name, order: ASC }
		) {
			nodes {
				taxonomy {
					name
					slug
				}
				name
				id: wordpress_id
				acf {
					country_code
				}
			}
		}
		outcome: allWordpressWpTaxonomyRcaOutcome(
			filter: { count: { gt: 0 } }
			sort: { fields: name, order: ASC }
		) {
			nodes {
				taxonomy {
					name
					slug
				}
				name
				count
				id: wordpress_id
			}
		}
		posts: allWordpressWpRcCaseStudy {
			nodes {
				__typename
				title
				slug
				path
				featured_media {
					localFile {
						verticalThumb: childImageSharp {
							fluid(maxWidth: 335, maxHeight: 160, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				taxonomy_rca_edition

				categories: rca_category {
					id: wordpress_id
					name
				}
				outcome: rca_outcome {
					id: wordpress_id
					name
				}
				countries: country {
					id: wordpress_id
					name
					acf {
						country_code
					}
				}
			}
		}
		wordpressPage(id: { eq: $id }) {
			title
			slug
			path
			wordpress_id
			wordpress_parent
			content
			excerpt
			template
			display_sharing_toolbar
			widgets {
				...FlexbileContentBuilder
			}
			aside {
				...AsideFragment
			}
			rca_grid_settings {
				display_map
				edition_to_display
				map {
					id
					source_url
				}
				categories_to_display
				category_filter {
					show_filter
					select_category_placeholder
				}
				outcome_filter {
					select_outcome_placeholder
					show_filter
					default_value
				}
				select_country_placeholder
			}

			ancestors {
				path
				name: title
			}
		}
	}
`;
