import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle, WidgetAside} from '../components/common';
import SupportingDocuments from '../components/library/supporting_documents';
import {CeficShare as SocialShare} from '../components/social-shares';
import {InteractiveMap} from '../components/interactivemap/map';
import MediaQuery from 'react-responsive';
import {Link} from '../components/link';
import FlexibleContent from '../components/flexible-builder';
import {SelectDropDown} from '../components/dropdown';
const RegionDropdown = ({regions}) => {
  const onSelectRegion = (region) => {
    if(!!document){
      const el = document.querySelector(`svg g#${region.value}`);
      if(el){
        const clickEvent = new Event('click');
        el.dispatchEvent(clickEvent);
      }
    }
  }
  const options = regions.map(e=>{return{value: e.country_code, label: e.title}});
  return (
    <SelectDropDown
      isSearchable={true}
      options = {options}
      onChange={onSelectRegion}
    />
  )
}
const InteractiveMapComponent = ({map, regions}) => (
  <div className="mb-5">
    {map.display_dropdown &&
     <RegionDropdown regions={regions}/>
    }
    <div className="mx-auto" style={{maxWidth: `${!!map.width?map.width:100}%`}}>
    <MediaQuery minWidth={992}>

      <InteractiveMap src={map.img.source_url} clickable_regions={regions} open_in_panel={true} />
    </MediaQuery>
    <MediaQuery maxWidth={991}>
      <InteractiveMap src={map.img.source_url} clickable_regions={regions} open_in_panel={false}/>
  </MediaQuery>
    </div>
  </div>
)

export default ({data, pageContext}) =>{
  const d = data.wordpressWpInteractiveMap;
  const contactBox = d.contact_box && d.contact_box.show_contact_box?d.contact_box:null;
  return(
    <Layout pageContext={pageContext} aside={WidgetAside({contactBox:contactBox})} ancestors={d.ancestors}>
      <div style={{maxWidth:1080}} className="container mx-0 px-0">
        <div style={{maxWidth: 910}} className="container mx-0 px-0">
          <PageTitle title={d.title}/>
          {d.interactive_map.map_location === "above" &&
           <InteractiveMapComponent
             map={d.interactive_map}
             regions={data.regions.nodes}
           />
          }

          <div className="wp_content" dangerouslySetInnerHTML={{__html:d.content}} />
          {d.interactive_map.map_location === "below" &&
           <InteractiveMapComponent
             map={d.interactive_map}
             regions={data.regions.nodes}
           />
          }
          {d.display_sharing_toolbar  &&
           <SocialShare  url={d.path} context={pageContext.yoast} className="my-10"/>
          }
        </div>
        {d.widgets?.widgets.length>0 &&
         <FlexibleContent data={d.widgets.widgets}/>
        }

        {d.supporting_documents &&
         <SupportingDocuments {...d.supporting_documents}/>
        }
      </div>
    </Layout>
  );
}
export const InteractiveMapPagequery = graphql`
query InteractiveMapById($id: String!, $regionsIds: [String]) {
  regions: allWordpressWpInteractiveMap(filter: {id: {in: $regionsIds}}, sort:{fields:title, order:[ASC]} ){
nodes{
id
country_code
tooltip_text
path
content
title
yoast:yoast_head


  }
}
  wordpressWpInteractiveMap(id:{eq:$id}){
    title
    slug
    path
    wordpress_id
    wordpress_parent
    content
    excerpt
    widgets{
      ...FlexbileContentBuilder
    }
    ancestors {
      slug:path
      name
    }

    interactive_map{
     map_location
     display_dropdown
     width
     img{
      source_url
     }
     clickable_regions{
        country_code
        id
      }
    }
    display_sharing_toolbar
     supporting_documents {
      title
      documents {
        title
        excerpt
        content_type
        topics
        slug
        date
        wordpress_id
        default_image {
          localFile {
            childImageSharp {
              fixed(width: 97, height: 138) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        acf {
          document_type
          external_link
          file {
            mime_type
            alt
          }
        }
        featured_media {
          localFile {
            smallHThumb: childImageSharp {
              fixed(width: 97, height: 138) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }

      contact_box{
          show_contact_box
          title
          text
          phone
          email
        }

  }
}
`;
