import React from 'react';
import Parser from 'html-react-parser';
import Paragraph from './core/paragraph';

var nameToComponentMap = new Map();
nameToComponentMap.set("core/paragraph", Paragraph);


export default ({blockName, data})=>{
  const props = JSON.parse(data);
  if(nameToComponentMap.has(blockName)){
    const Component = nameToComponentMap.get(blockName);
    return <Component {...props}/>;
  }else {
    const parsedContent = Parser("<p>unknown block "+ blockName +"</p>");
    return <React.Fragment>{parsedContent}</React.Fragment>;
  }
};
