import React from 'react';
import {
  graphql
} from 'gatsby';
import Layout from '../components/layout';
import {
  PageTitle
} from '../components/common';
import MediaQuery from 'react-responsive';
import NewsList from '../components/news/list';
import {
  GreyAside
} from '../components/aside';
import NewsAppPopup from '../components/NewsApp/popup';

const NewsArchive = (props) => {
  const page = props.data.page;
  const news = props.data.news && props.data.news.edges ? props.data.news
    .edges.map(e => e.node) : [];
  return (
    <Layout aside={GreyAside({aside:page.aside})}  pageContext={props.pageContext} ancestors={page.ancestors}>
      <div style={{maxWidth:968}} className="container mx-0 px-0">
        <div style={{maxWidth: 910}} className="container mx-0 px-0">
          <PageTitle title={page.title}/>
          {page.content && page.content!== '' &&
           <div className="page-content" dangerouslySetInnerHTML={{__html:page.content}}/>
          }
          <NewsAppPopup/>
        </div>
        <NewsList posts={news} openInPanel={true} showThumbnail={false} analyticsName='news-article'/>
      </div>

    </Layout>
  );
};

export default NewsArchive;

export const NewsArchiveQuery = graphql`
query NewsArchiveQuery($id: String) {
  page: wordpressPage(id:{eq:$id}){
    title
    content
    ancestors {
      name:title
      path
    }
aside {
     ...AsideFragment
    }

    acf {
      contact_box{
        show_contact_box
        title
        text
        phone
        email
      }
    }
  }
  news:allWordpressWpNews(sort:{fields:date, order:DESC}){
    edges{
      node{
        title
        excerpt
        slug
        path
        content
        date
        yoast: yoast_head
        tags {
          name
        }
        contact_box{
          show_contact_box
          title
          text
          phone
          email
        }
         widgets{
          ...FlexbileContentBuilder
        }

      }
    }
  }
}
`;
