import React from 'react';
import {
  StaticQuery,
  graphql
} from 'gatsby';
import addToMailChimp from '../../../plugins/gatsby-digitag-mailchimp';
import {
  MailInput,
  CheckboxInput,
  SubmitButton
} from '../forms/fields';

export default class NewsletterOptin extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        gdpr_consent: false,
        show_gdpr_error: false,
        show_error: false,
        error_msg: '',
        success: false

      };
      this._emailChanged = this._emailChanged.bind(this);
      this._consentChanged = this._consentChanged.bind(this);
      this._handleSubmit = this._handleSubmit.bind(this);
      this._reset_errors = this._reset_errors.bind(this);
    }
    _emailChanged(e) {
      this._reset_errors();
      this.setState({
        email: e.target.value
      });
    }
    _consentChanged(e) {
      this._reset_errors();
      this.setState({
        gdpr_consent: e.target.checked
      });
    }
    _reset_errors() {
      this.setState({
        show_error: false,
        error_msg: ''
      });
    }
    _handleSubmit(e) {
      e.preventDefault();

      if (!this.state.gdpr_consent) {
        this.setState({
          show_gdpr_error: true
        });
        return;
      }
      let gdpr_fields = {};
      if (this.state.gdpr_consent) {
        gdpr_fields['gdpr[9085]'] = 'Y';

        addToMailChimp(this.state.email, {}, gdpr_fields)
          .then(data => {
            if (data.result === 'error') {
              this.setState({
                show_error: true,
                error_msg: data.msg
              });
            } else {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event': 'newsletter_subscription_success'
              });
              this.setState({
                success: true
              });
            }
            //store data in state
            //handle errors
          })
          .catch(() => {
            //should never be called since mailchimpt always returns 200
            //error handled above
          });
      } else {
        this.setState({
          'show_gdpr_error': true
        });
      }

    }
    render() {
        return (
            <StaticQuery
        query={graphql`
 query MailchimpSettings{
   wordpressAcfOptions {
     options {
       mailchimp{
         title
         consent{
           checkbox_text
           error_message
         }
         submit_button_text
         thank_you_message
         reset_form_text
       }
     }
  }
}
`} render={data =>{
  const mc=data.wordpressAcfOptions.options.mailchimp;
  return (
    <>
    { mc?.title &&
      <p className="uppercase text-left font-medium mb-2 ">{mc?.title}</p>
    }
          <form  id={this.props.id} className="text-left mx-auto my-5 flex-no-grow"  onSubmit={this._handleSubmit}>
      {!this.state.success ?
        <div className="">
          <MailInput id="email" name="EMAIL" value={this.state.email} type="email" required onChange={this._emailChanged} placeholder="email address" className="mb-4 w-full" required/>
          <CheckboxInput id="gdpr[9085]" name="gdpr[9085]" onChange={this._consentChanged}>
            <span className="text-sm text-black" dangerouslySetInnerHTML={{__html:mc.consent.checkbox_text}}/>
          </CheckboxInput>
          { this.state.show_gdpr_error && !this.state.gdpr_consent &&
            <p className="text-red text-xs italic" dangerouslySetInnerHTML={{__html:mc.consent.error_message}}/>
          }
          { this.state.show_error && this.state.error_msg &&
            <div className="text-red text-xs italic" dangerouslySetInnerHTML={{__html:this.state.error_msg}}/>
          }

          <div className="text-center my-4">
            <SubmitButton className="w-full" label={mc.submit_button_text}/>
          </div>
       </div>
       :
       <div>
         <div dangerouslySetInnerHTML={{__html:mc.thank_you_message}}/>
         <button onClick={e=>this.setState({success:false})} dangerouslySetInnerHTML={{__html:mc.reset_form_text}}/>
       </div>
      }
    </form>
    </>
  );
}}/>
    );
  }
}
