import React from 'react';
import  {ReactComponent as Logo} from '../assets/imgs/svg/logo.svg';
export default ({caption}) => (
  <div className="ceficLogo flex content-start items-center mr-5">
    <div className="ceficLogo__logo" style={{height:109, width:134}}>
      <Logo height="100%" width="100%"/>
    </div>
    {caption &&
     <div className="ceficLogo--caption text-xs font-light text-black-menu tracking-wide" style={{maxWidth:163}}>
       <span>{caption}</span>
     </div>
  }
  </div>
)
