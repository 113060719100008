import React, { Fragment } from "react";
import { LoadingIndicator } from "../loaders";
import Select, { components } from "react-select";

export const withPostList = (ComponentToWrap) => {
	return class PostList extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				isLoading: true,
				posts: [],
				per_page: 10,
				total_pages: 0,
				current_page: 0,
			};
		}
		componentDidMount() {
			const categories =
				this.props.categories && this.props.categories.length
					? this.props.categories.map((c) => c.term_id).join(",")
					: "";
			const taxonomy = "category";
			const post_types = this.props.postTypes
				? this.props.postTypes.join(",")
				: "post";
			fetch(
				`https://${process.env.GATSBY_WP_URL}/wp-json/digitag/v1/posts?_embed&post_type=${post_types}&categories=${categories}&taxonomy=${taxonomy}`
			)
				.then((response) => response.json())
				.then((data) => {
					const normalized = data.map((d) => ({
						...d,
						title: d.title.rendered,
						excerpt: d.excerpt.rendered,
						content: d.content.rendered,
					}));
					this.setState({ posts: normalized, isLoading: false });
				});
		}
		render() {
			return (
				<Fragment>
					{this.state.isLoading ? (
						<LoadingIndicator />
					) : (
						<ComponentToWrap posts={this.state.posts} />
					)}
				</Fragment>
			);
		}
	};
};

const selectStyles = {
	control: (styles, { isFocused }) => ({
		...styles,
		borderRadius: 0,
		// This line disable the blue border
		boxShadow: "0 0 0 0.6px #D6D7D7",
		borderColor: "#F47B20",
		borderTop: 0,
		borderLeft: 0,
		borderRight: 0,
		"&:hover": {
			borderColor: isFocused ? "#F47B20" : null,
		},
	}),
	indicatorSeparator: (style) => ({ display: null }),
	menu: (styles) => ({ ...styles, borderRadius: 0, marginTop: 0 }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isFocused ? "#EAEAEB" : null,
			display: isSelected ? "none" : null,
		};
	},
};

const Option = (props) => {
	return (
		<components.Option {...props}>
			<span dangerouslySetInnerHTML={{ __html: props.label }} />
		</components.Option>
	);
};
const SingleValue = (props) => {
	return (
		<components.SingleValue {...props}>
			<span dangerouslySetInnerHTML={{ __html: props.data.label }} />
		</components.SingleValue>
	);
};

const TagFilter = ({ tags, value, onChange, ...otherprops }) => (
	<Select
		className="cefic-select-container"
		classNamePrefix="cefic-select"
		isSearchable={true}
		isClearable={true}
		options={tags}
		onChange={onChange}
		styles={selectStyles}
		components={{ Option, SingleValue }}
		value={value}
		{...otherprops}
	/>
);

export const withFilteredList = (WrappedComponent) => {
	return class FilteredPostList extends React.Component {
		constructor(props) {
			super(props);
			const { posts, filters, ...otherprops } = props;
			this.otherprops = otherprops;
			this.state = {
				posts: [],
				filteredPosts: [],
				isLoading: true,

				filters: [],
				/* filter is of the form:
          {
          taxonomy: string,
          display_filter: bool,
          selected_term: String,
          placeholder: String,
          matchKey: string, the attribute in post that contains the taxonomy
          terms: [{
          value: String, ##should be node id
          label: String
          }]
          }
          */
			};
		}
		componentDidMount() {
			//check if we have args in view
			const posts = this.props.posts || [];
			//let filters = this.reduceFilters(posts, this.props.filters);
			let filters = this.reduceFilters(this.props.filters).sort((f1, f2) => {
				return f1.order - f2.order;
			});
			filters = this.getSearchParams(filters);
			this.setState({
				posts: posts,
				filteredPosts: this.filterPosts(filters),
				filters: filters,
			});
		}

		getSearchParams(filters) {
			const params = new URLSearchParams(window.location.search);
			let selected_filters = {};
			filters = filters.map((f) => {
				const match = params.get(f.matchKey);
				if (match && f.terms.find((t) => t.value === Number(match))) {
					f["selected_term"] = Number(match);
				}
				return f;
			});
			return filters;
		}
		reduceFilters(filters) {
			let new_filters = [];
			filters?.forEach((filter) => {
				let known_terms = [];
				this.props.posts.forEach((p) => {
					if (!!p[filter.matchKey] && p[filter.matchKey].length) {
						p[filter.matchKey].forEach((f) => {
							if (!known_terms.includes(f.id)) {
								known_terms.push(f.id);
							}
						});
					}
				});
				new_filters.push({
					...filter,
					terms: filter.terms
						.filter((f) => known_terms.includes(f.value))
						.sort((t1, t2) => t2.label < t1.label),
				});
			});
			return new_filters;
		}
		filterPosts(filters) {
			let has_active_term = false;
			filters.forEach((f) => {
				if (!!f.selected_term) {
					has_active_term = true;
				}
			});
			if (!has_active_term) {
				return this.props.posts;
			}

			return this.props.posts.filter((p) => {
				let keep = true;
				filters.forEach((f) => {
					if (!!f.selected_term) {
						keep =
							keep && !!p[f.matchKey].find((t) => t.id === f.selected_term);
					} else {
						keep = keep && true;
					}
				});
				return keep;
			});
		}
		onChangeFilter(taxonomy, selectedTerm) {
			//update current taxonomy active term
			const filters = this.state.filters.map((f) => {
				if (f.matchKey === taxonomy.matchKey) {
					return {
						...f,
						selected_term: !!selectedTerm ? selectedTerm.value : null,
					};
				}
				return f;
			});
			//reapply ALL filters to original post list
			this.setState({
				filteredPosts: this.filterPosts(filters),
				filters: [...filters],
			});
			//deal with the url
			const sp = new URLSearchParams(window.location.search);
			filters.forEach((f) => {
				if (f.selected_term) {
					sp.set(f.matchKey, f.selected_term);
				} else {
					sp.delete(f.matchKey);
				}
			});

			const new_path = `${window.location.origin}${window.location.pathname}${
				!!sp.toString() ? "?" + sp.toString() : ""
			}`;
			history.pushState({}, "", new_path);
			if (this.props.onSelectTerm) {
				this.props.onSelectTerm(
					{
						taxonomy: taxonomy.taxonomy,
						matchKey: taxonomy.matchKey,
					},
					selectedTerm
				);
			}
		}

		render() {
			return (
				<>
					<div className="flex flex-wrap">
						{this.state.filters.map((tax) => {
							if (tax?.display_filter && tax?.terms?.length > 0) {
								return (
									<div
										className="my-5 w-full md:w-64 md:mr-5"
										key={tax.taxonomy}
									>
										<TagFilter
											tags={tax.terms}
											onChange={(v) => {
												this.onChangeFilter(tax, v);
											}}
											placeholder={tax.placeholder}
											defaultValue={tax.terms.find(
												(t) => t.value === tax.selected_term
											)}
											value={tax.terms.find(
												(t) => t.value === tax.selected_term
											)}
										/>
									</div>
								);
							}
						})}
					</div>
					<WrappedComponent
						posts={this.state.filteredPosts}
						{...this.otherprops}
					/>
				</>
			);
		}
	};
};
