import React from 'react';
import classnames from 'classnames';
import {navigate} from 'gatsby';
import Img from 'gatsby-image';
import {
  MetaInfo,
  SubTitle,
  PBody
} from '../../typography';

import {connect} from 'react-redux';
import {ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';
import Job from '../detail';



const trimExcerpt = (excerpt, max_length=180, filler='...') => {
  excerpt = excerpt.replace(/\n/g, '')
  excerpt = excerpt.replace(/<br \/>/g, '')
  const arr = excerpt.match(/<p>(.*)<\/p>/) ;
  let new_excerpt = arr.length>1?arr[1]:arr[0];
  if(new_excerpt.length > max_length){
    new_excerpt = new_excerpt.slice(0, max_length-filler.length)+filler;
  }
  return new_excerpt;
};



const mapStateToProps = ({panel})=>{
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setPanelContent: (content, path) => dispatch({type:`SET_PANEL_CONTENT`, content:content, path:path }),
    openPanel: () => dispatch({type:`OPEN_PANEL`})
  };
};

export class HorizontalJobPreview extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover:false
    };
    this.clickHandler = this.clickHandler.bind(this);
    this.renderPost = this.renderPost.bind(this);
  }
  clickHandler(){
    if(this.props.openInPanel){
      this.props.setPanelContent(this.renderPost(), this.props.path);
      this.props.openPanel();
    }else {
      navigate(this.props.path);
    }

  }
  renderPost(){

    return (<Job {...this.props} />);
  }
  render(){

    const classesChevron = classnames(
      'fill-current',
      {
        'text-grey': !this.state.hover,
        'text-brand': this.state.hover
      }
    );
    return(
      <div className="relative flex items-top justify-center hover:cursor-pointer" onMouseEnter={e=>{this.setState({hover:true});}} onMouseLeave={e=>{this.setState({hover:false});}} onClick={this.clickHandler} style={{minHeight:164}} >
        <div className="pr-15px py-2 flex flex-col jutify-between flex-grow">
          <div><SubTitle hover={this.state.hover?this.state.hover:undefined} dangerouslySetInnerHTML={{__html:this.props.title}}/></div>
          <div className="mb-2">
            <MetaInfo >Deadline {this.props.deadline} </MetaInfo>
          </div>
          <div className="">
            <PBody dangerouslySetInnerHTML={{__html:trimExcerpt(this.props.excerpt).slice(0,218)}}/>
          </div>
        </div>
        <div className="flex items-center">
          <ChevronRight width={25} height={25} className={classesChevron}/>
        </div>
      </div>
    );
  }
}

export const ConnectedHorizontalJobPreview = connect(
  mapStateToProps,
  mapDispatchToProps
)(HorizontalJobPreview);


