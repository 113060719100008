import React, { Fragment } from "react";
import PageHeader from "./page-header";
import PageFooter from "./page-footer";
import BreadCrumbs from "./breadcrumb";
import "../styles/wp_style.css";
import "../styles/blocks.style.build.css";
import "../styles/main.css";
import "../styles/transitions.css";
import "./layout.css";
import "../styles/wp_components.css";
import { CSSTransition } from "react-transition-group";
import FormInPanel from "./panels/formPanels";
import { ModalRoot } from "./modals";
import { connect } from "react-redux";
import { PrimaryPanel, SecondaryPanel } from "./panels";
import { SDGPanel } from "./beehive/SDGPanel";
import MediaQuery from "react-responsive";
import GlobalSearch from "./search/globalSearch";
import Yoast from "./yoast";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import Aside from "./aside";

const FirstChild = (props) => {
	const childrenArray = React.Children.toArray(props.children);
	return childrenArray[0] || null;
};

const mapStateToProps = ({ panel, searchPanel, sdgPanel }) => {
	return { panel, searchPanel, sdgPanel };
};
const mapDispatchToProps = (dispatch) => {
	return {
		closePanel: () => dispatch({ type: `CLOSE_PANEL` }),
		openPanel: () => dispatch({ type: `OPEN_PANEL` }),
	};
};

class Layout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			panelHeight: 0,
			openSidePanel: false,
			holdPanel: false,
			holdSearchPanel: false,
			holdSDGPanel: false,
		};

		this.setHeaderHeight = (element) => {
			if (element) {
				this.panelHeight = element.clientHeight;
				this.updateDimensions();
			}
		};
		this.sidepanel = React.createRef();
		this.toggleSidePanel = this.toggleSidePanel.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this);
	}

	updateDimensions() {
		const screen_width = window.innerWidth;
		const breadcrumbs = document.getElementById("breadcrumbs");
		const breadcrumbs_width = !!breadcrumbs
			? breadcrumbs.getBoundingClientRect().width
			: 0;

		const margin = Math.max(
			0,
			(screen_width - Math.min(1200, screen_width)) / 2 - breadcrumbs_width
		);
		this.setState({
			...this.state,
			panelHeight: window.innerHeight - this.panelHeight,
			contentMarginLeft: screen_width < 1200 ? 0 : margin,
		});
	}
	componentDidMount() {
		//make sure secondary panel is closed on first load
		this.props.closePanel();
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions);
		//handle interactive svgs
		this.activateInteractiveSVG();
	}
	activateInteractiveSVG() {
		if (window && document) {
			const els = document.querySelectorAll("svg .interactive");
			!!els &&
				Array.from(els).map((el) => {
					const target_url = el.dataset.target_url;
					const target_opens_in_new_window =
						el.dataset.target_opens_in_new_window === "true" ? true : false;
					if (!!target_url) {
						el.addEventListener("click", () => {
							if (target_opens_in_new_window) {
								var win = window.open(target_url, "_blank");
								if (win) {
									win.focus();
								}
							} else {
								window.location.assign(target_url);
							}
						});
					}
				});
		}
	}
	componentDidUpdate() {
		this.activateInteractiveSVG();
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}
	toggleSidePanel() {
		this.setState({ ...this.state, openSidePanel: !this.state.openSidePanel });
	}
	render() {
		return (
			<div className="page-layout font-sans font-light tracking-02 leading-normal">
				<Helmet>
					<body className="wp-embed-responsive" />
					<link
						rel="stylesheet"
						type="text/css"
						href={`https://${process.env.GATSBY_WP_URL}/app/themes/cefic/dist/styles/blocks.css`}
					/>
				</Helmet>
				{this.props.pageContext && this.props.pageContext.yoast && (
					<Yoast {...this.props.pageContext} />
				)}
				<PageHeader ref={this.setHeaderHeight} />
				<div className="relative lg:flex">
					<ModalRoot />
					{this.props.ancestors && this.props.ancestors.length > 0 && (
						<BreadCrumbs items={this.props.ancestors} />
					)}
					<MediaQuery minWidth={992}>
						<>
							{(this.props.searchPanel.isOpen ||
								this.state.holdSearchPanel) && (
								<CSSTransition
									in={this.props.searchPanel.isOpen}
									classNames="slidesearch"
									timeout={500}
									appear
									onEnter={(x) => {
										this.setState({ holdSearchPanel: true });
									}}
									onExited={(x) => {
										this.setState({ holdSearchPanel: false });
									}}
								>
									<div
										className="lg:absolute lg:pin-t  bg-panel-secondary h-full pin-r w-1/2  z-50"
										style={{ maxWidth: 720 }}
									>
										<div className="breadcrumb-wrapper lg:flex lg-absolute lg:h-full ">
											<GlobalSearch />
										</div>
									</div>
								</CSSTransition>
							)}
							{(this.props.sdgPanel.isOpen || this.state.holdSDGPanel) && (
								<CSSTransition
									in={this.props.sdgPanel.isOpen}
									classNames="slidesearch"
									timeout={500}
									appear
									onEnter={(x) => {
										this.setState({ holdSDGPanel: true });
									}}
									onExited={(x) => {
										this.setState({ holdSDGPanel: false });
									}}
								>
									<div
										className="lg:absolute lg:pin-t  bg-panel-secondary h-full pin-r w-1/2  z-50"
										style={{ maxWidth: 720 }}
									>
										<div className="breadcrumb-wrapper lg:flex lg-absolute lg:h-full ">
											<SDGPanel sdg={this.props.sdgPanel.item} />
										</div>
									</div>
								</CSSTransition>
							)}
							<main
								className="flex flex-1 container mx-auto py-8 mr-auto overflow-x-auto"
								style={{ marginLeft: this.state.contentMarginLeft }}
							>
								{this.props.children}
							</main>
							<Fragment>
								{(this.props.panel.isOpen || this.state.holdPanel) && (
									<CSSTransition
										in={this.props.panel.isOpen}
										onEnter={(x) => {
											this.setState({ holdPanel: true });
										}}
										onExited={(x) => {
											this.setState({ holdPanel: false });
										}}
										timeout={500}
										classNames="slidepanel"
										appear
									>
										<SecondaryPanel />
									</CSSTransition>
								)}
							</Fragment>
						</>
					</MediaQuery>

					<MediaQuery maxWidth={991}>
						<>
							<main className="container py-4">
								<div className="container mx-0 px-0">{this.props.children}</div>
							</main>
						</>
					</MediaQuery>
					{!this.props.panel.isOpen &&
						!this.state.holdPanel &&
						this.props.aside && <Aside>{this.props.aside}</Aside>}
				</div>
				<PageFooter />
			</div>
		);
	}
}
const ConnectedLayout = connect(mapStateToProps, mapDispatchToProps)(Layout);

export default (props) => (
	<>
		<MediaQuery minWidth={768}>
			<FormInPanel>
				<ConnectedLayout {...props} />
			</FormInPanel>
		</MediaQuery>
		<MediaQuery maxWidth={767}>
			<ConnectedLayout {...props} />
		</MediaQuery>
	</>
);
