import React, { Fragment } from "react";
import { PageTitle as PageH1 } from "../typography";
import ContactBox from "./contactbox";

export const Hr = (props) => (
	<hr className="border-t-4 border-brand w-7 ml-0 mb-7 mt-3" />
);

export const PageTitle = (props) => (
	<div className="page_title">
		<PageH1 dangerouslySetInnerHTML={{ __html: props.title }} />
		{props.children}
		<Hr />
	</div>
);

export const WidgetAside = (props = {}) => (
	<div className="w-full  bg-panel-secondary h-full px-15px pt-10">
		<div style={{ maxWidth: 350 }} className="mx-auto">
			{props.contactBox && (
				<ContactBox className="border mb-10" {...props.contactBox} />
			)}
		</div>
	</div>
);
