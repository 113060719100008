import React, {Fragment} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import PageMenu from '../components/menu/page-menu';
import PostList from '../components/posts/list';
import {PostSlider} from '../components/posts/slider';
import {MobilePostPreview} from '../components/posts/previews';
import {PageTitle} from '../components/common';
import MediaQuery from 'react-responsive';
import ContactBox from '../components/common/contactbox';
import {CeficShare} from '../components/social-shares';

const NavAside = ({menu, contactBox, ...props}) => {
  return(
    <div className="flex flex-col justify-between h-full">
      <PageMenu menu={menu}/>
      {contactBox &&
       <ContactBox className="border-t sticky pin-b" {...contactBox}/>
      }
    </div>
  );
}


const get_post_slug = (post) => {
  if(post.primary_category){
    return `${post.primary_category.slug}/${post.slug}`;
  }
  return `${post.slug}`;
}
export default (props) =>{
  const data = props.data.wordpressPage;
  const sliderPosts = props.data.sliderPosts?props.data.sliderPosts.edges.map(e=>{return {...e.node, slug:get_post_slug(e.node)};}):undefined;
  const subitems = props.data.wordpressPage.subpages;
  let subNav = false;
  const feed_post_types = data.acf.post_types;
  const feed_categories = data.acf.categories;

  if(subitems && subitems.length){
    const menu = {items:subitems.map((item) => ({
      title: item.name,
      slug: item.slug,
      path: item.path
    }))};
    const contactBox = data.acf.contact_box && data.acf.contact_box.show_contact_box?data.acf.contact_box:null;
    subNav = NavAside({menu:menu, contactBox:contactBox});
  }
  return(
    <Layout pageContext={props.pageContext} aside={subNav} ancestors={data.ancestors}>
      <div style={{maxWidth:1080}} className="container mx-0 px-0">
        <div className="container mx-0 px-0">
          <PageTitle title={data.title}/>
          { data.content?
            <div className="page-content"  dangerouslySetInnerHTML={{__html:data.content}} />
            :
            <div className="page-content" >
              { data.category && data.category.description!=="" &&
                <p>{data.category.description}</p>
              }
            </div>
          }
    {data.display_sharing_toolbar &&
     <CeficShare context={props.pageContext.yoast} className="justify-end my-10 " url={data.path}/>
    }
        </div>
        {sliderPosts && sliderPosts.length &&
         <Fragment>
           <MediaQuery minWidth={769} >
             <PostSlider posts={sliderPosts} showThumbnail={true}/>
           </MediaQuery>
           <MediaQuery maxWidth={768}>
             {sliderPosts.map(p => (
               <MobilePostPreview {...p}/>
             ))}
           </MediaQuery>
         </Fragment>
        }

      </div>
    </Layout>
  );
}
export const RoutingPagequery = graphql`
query MediaCornerPageById($id: String!) {
  wordpressPage:wordpressPage(id:{eq:$id}){
    slug
    title
    content
    path
    display_sharing_toolbar
    ancestors {
      slug
      name:title
    }
    acf {
      post_types
      categories{
        term_id
        taxonomy
      }
      contact_box{
          show_contact_box
          title
          text
          phone
          email
        }
    }
    category{
      ... on wordpress__CATEGORY{
      name
      description
      wordpress_id
      }
      ... on wordpress__wp_ccan_category{
      name
      description
      wordpress_id
      }

    }
    subpages {
      name
      slug
      path
    }
  }

  sliderPosts: allWordpressWpNews(sort:{fields:date, order:DESC}){
    edges{
      node{
        id
        slug
        path
        title
        content
        excerpt
        date
        featured_media{
          localFile{
            verticalThumb:childImageSharp{
              fluid(maxWidth:240, maxHeight: 194){
                   ...GatsbyImageSharpFluid
              }
            }
            horizontalThumb:childImageSharp{
              fixed(width:217, height: 164){
                   ...GatsbyImageSharpFixed
              }
           }
           banner:childImageSharp{
              fluid(maxWidth:674, maxHeight:300){
                  ...GatsbyImageSharpFluid
             }
            }
          }
        }
      }
    }
  }

}
`;
