import React from 'react';
import {ReactComponent as Chevron } from '../../../assets/icons/chevron-right.svg';



const TocEntry = ({label, href, isLast}) => {
	return (
    <li className={`page-menu__item p-2 border ${!isLast?'border-b-0':''} last:border-b-1 border-grey-lighter hover:text-brand  flex items-center`} >
      <a className="page-menu__item__link no-underline text-2xs tracking-wide flex flex-1 text-sm items-center" href={`${href[0]!=='#'?'#':''}${href}`}  style={{color:'inherit'}}>
        <Chevron className="fill-current mr-4" style={{minWidth:25}} height={25} width={25}/>
        <span className="" dangerouslySetInnerHTML={{__html:label}}/>

      </a>
    </li>

	)
}

const TocWidget  = (props) => (
		<div>
		{props.title&&<p>{props.title}</p>}


  {
		props.entries?.length &&
			<ul className="list-reset">
			  {props.entries.map( (entry, id)=>(<TocEntry {...entry} key={id} isLast={props.entries.length===id+1}/>))}
			</ul>
	  }
	</div>
);

export default TocWidget;
