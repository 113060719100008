import React from 'react';
import {Link} from '../link';
import {ReactComponent as Chevron } from '../../assets/icons/chevron-right.svg';


const MenuItem = (props) => {
  return(
    <li className="page-menu__item p-3 border-b border-grey-lighter hover:text-brand  flex items-center" style={{minHeight:60}}>
      <Link className="page-menu__item__link uppercase no-underline text-2xs tracking-wide flex flex-1 px-3 text-sm items-center" to={props.path}  style={{color:'inherit'}}>

        <span className="ml-auto text-right" dangerouslySetInnerHTML={{__html:props.title}}/>
        <Chevron className="fill-current ml-3" style={{minWidth:25}} height={25} width={25}/>
      </Link>
    </li>
  );
};

export default  (props) => {
  return(
    <nav className="relative page-menu">
      <ul className="list-reset ">
        {props.menu.items.map((item, index)=> {
          return (
            <MenuItem {...item} key={index} />
          );
        })}
      </ul>
      <hr className="absolute border-brand border-t-4 pin-r" style={{transform:'translateY(-250%)', width:57}}/>
      <div style={{width:3, height:30, transform:"translateX(-50%)"}} className="absolute bg-brand pin-t"></div>
    </nav>
  );
};
