import React from 'react'
import Select from 'react-select'
import classnames from 'classnames'
import {FieldWrapper} from './wrapper'


export class SelectInput extends React.Component{
  handleChange = value => {
    this.props.onChange( value)
  }
  handleBlur = value => {
    this.props.onBlur(this.props.id, true)
  }
render() {
  const choices = this.props.choices.map(choice => {
    return {
      value: choice.value,
      label: choice.text,
      isSelected: choice.isSelected
    }

  })
  const className = classnames(
    "border-b border-b-2 border-black ",
    this.props.className
  );

  const selectStyles = {
    control: (styles,{isFocused}) => ({
      ...styles,

      // This line disable the blue border
      boxShadow: "0 0 0 0",
      border: 0
    }),
    indicatorSeparator: style =>({display:null}),
    menu: styles => ({...styles, borderRadius:0, marginTop:0}),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isFocused?'#EAEAEB':null,
        display:isSelected?'none':null,
      };
    }
  };
  return(
    <FieldWrapper {...this.props}>
      <Select
        className="select cefic-select-container"
        classNamePrefix="select"
        isSearchable={false}
        options={choices}
        styles={selectStyles}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={choices.find(c=>c.value===this.props.value)}
      />
    </FieldWrapper>
  )
}
}
