import React from 'react';
import {navigate} from 'gatsby';
import {SubTitle, PBody, MetaInfo } from '../../typography';
import {ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';

export const MobileJobPreview = (props) =>(
  <article onClick={e=>navigate(props.path)} className="mb-5">
    <div>
      <SubTitle dangerouslySetInnerHTML={{__html:props.title}}/>
      <div className="mb-4">
        <MetaInfo >Deadline {props.deadline} </MetaInfo>
      </div>
      <PBody dangerouslySetInnerHTML={{__html:props.excerpt}}/>
    </div>
    <div className="flex justify-between">
      <ChevronRight width={25} height={25} className="fill-current text-grey ml-auto"/>
    </div>
  </article>
);
