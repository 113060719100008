import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { CheckboxInput } from "../../forms/fields";

class TopicsFiltersComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			topics: [],
			byId: {},
			byParentId: {},
		};
		this.renderTopicsGroup = this.renderTopicsGroup.bind(this);
	}
	UNSAFE_componentWillMount() {
		let allTopics = this.props.topics.map((t) => {
			t.checked = this.props.selected.includes(t.wordpress_id);
			return t;
		});
		let byId = {};
		let byParentId = { 0: [] }; //the array will hold either categories with no parent, of categories with parent in the case the parent itself was excluded
		allTopics = allTopics.map((t, index) => {
			byId[t.wordpress_id] = index;
			if (
				!allTopics?.find((topic) => topic.wordpress_id === t.wordpress_parent)
			) {
				t.wordpress_parent = 0;
			}
			if (!byParentId.hasOwnProperty(t.wordpress_parent)) {
				byParentId[t.wordpress_parent] = [];
			}
			byParentId[t.wordpress_parent] = byParentId[t.wordpress_parent].concat([
				index,
			]);
			return t;
		});
		this.setState({
			topics: allTopics,
			byId: { ...byId },
			byParentId: { ...byParentId },
		});
	}
	toogleFilter(id) {
		const filters = this.state.topics.map((t) => {
			if (t.wordpress_id === id) {
				t.checked = !t.checked;
			}
			return t;
		});
		this.setState(
			{
				topics: filters,
			},
			this.props.updateHandler(
				this.state.topics.filter((t) => t.checked).map((t) => t.wordpress_id)
			)
		);
	}
	getById(id) {
		return this.state.topics[this.state.byId[id]];
	}
	getChildrenById(id) {
		if (this.state.byParentId.hasOwnProperty(id)) {
			return this.state.byParentId[id].map((i) => this.state.topics[i]);
		}
		return [];
	}
	renderTopicsGroup(root_id) {
		const root = this.getById(root_id);
		const directChildren = this.getChildrenById(root_id);
		return (
			<div key={root.slug}>
				<CheckboxInput {...root} onChange={(e) => this.toogleFilter(root_id)}>
					<span dangerouslySetInnerHTML={{ __html: root.name }} />
				</CheckboxInput>
				{directChildren && directChildren.length > 0 && (
					<div className="ml-5">
						{directChildren.map((c) => this.renderTopicsGroup(c.wordpress_id))}
					</div>
				)}
			</div>
		);
	}
	render() {
		const root_topics = this.state.byParentId[0]?.map(
			(i) => this.state.topics[i]
		);
		return (
			<div>
				{root_topics.map((t) => this.renderTopicsGroup(t.wordpress_id))}
			</div>
		);
	}
}
export const TopicsFilters = (props) => (
	<StaticQuery
		query={graphql`
			query allNonEmptyTopics {
				allWordpressWpTopics(filter: { count: { gt: 0 } }) {
					edges {
						node {
							wordpress_id
							name
							slug
							wordpress_parent
							count
						}
					}
				}
			}
		`}
		render={(data) => {
			const topics = data.allWordpressWpTopics.edges
				.map((e) => e.node)
				.filter((e) => {
					if (props.restrict_to && props.restrict_to.length) {
						return props.restrict_to.includes(e.wordpress_id);
					}
					return true;
				})
				.sort((a, b) =>
					a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
				);
			return (
				<TopicsFiltersComponent
					topics={topics}
					selected={props.selected}
					updateHandler={props.updateHandler}
				/>
			);
		}}
	/>
);
