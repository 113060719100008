import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle, WidgetAside} from '../components/common';
import Job from '../components/jobs/detail';
import {CeficShare as SocialShare} from '../components/social-shares';

export default (props) =>{
  const data = props.data.wordpressWpJobs;
  const blocks = data.editor_blocks;
  return(
    <Layout pageContext={props.pageContext} aside={WidgetAside()} ancestors={data.ancestors}>
      <div style={{maxWidth:1025}} className="mx-0 px-0">
        <div style={{maxWidth: 734}} className="mx-0 px-0">
          <Job {...data} />
          <SocialShare  url={props.pageContext.yoast.canonical} context={props.pageContext.yoast} />
        </div>
      </div>
    </Layout>

  );
}
export const defaultJobQuery = graphql`
query DefaultJobById($id: String!) {
  wordpressWpJobs(id:{eq:$id}){
    title
    slug
    path
    wordpress_id
    content
    deadline(formatString: "MMMM Do YYYY")
     ancestors {
      name:title
      slug
      path
   }
  }
}
`;
