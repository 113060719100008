import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
query SearchableItemsQuery{
    pages:allSitePage{
    edges{
      node{
        path
        context
        {
          id
          wordpress_id
          search {
            wordpress_id
            title
            excerpt
            type
          }
        }
      }
    }
  }
  libraryItems: allWordpressWpLibraryItem{
    edges{
      node{
        wordpress_id
        title
        excerpt
        type
        path
        acf{
          document_type
          file {
            mime_type
            type
            link
          }
        }
      }
    }
  }
}
`);
  const normalizedItems = data.pages.edges.map(e=>({...e.node.context, path:e.node.path})).filter(e=>!!e.search);
  const normalizedLibraryItems = data.libraryItems.edges.filter(e=>e.node.acf.document_type!=="content").map(i=>{
    const e = i.node;
    return {wordpress_id:e.wordpress_id, search:{...e}, path:e.path};
  });
  const items= normalizedItems.concat(normalizedLibraryItems);
  return items;
}
