import React from 'react'
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import ScrollArea from 'react-scrollarea/src/js/ScrollAreaWithoutCss';
import {PageTitle} from '../common';
import {connect} from 'react-redux';
import {CeficShare} from '../social-shares'

import '../../styles/scrollarea.css';
import './SDGPanel.css'


const mapStateToProps = ({sdgPanel}) => {
  return sdgPanel;
};
const mapDispatchToProps = dispatch => {
  return {
    close: ()=>dispatch({type:`CLOSE_SDG`}),
  };
};

export const SDGPanelComponent = (props) => {
  const yoast = props.item && props.item.yoast ? props.item.yoast : false;
  return (
    <aside className="side-panel  relative px-5 secondary-panel flex-grow w-1/2 flex-no-shrink"  style={{maxWidth:720}}>
      <div className="sticky pin-t ">
        <div className=" flex justify-end pt-3 z-50 mb-7">
          {yoast &&
           <CeficShare context={yoast} className="mr-5" url={props.item.path}/>
          }

          <button onClick={props.close}><CloseIcon width={14} height={14} /></button>
        </div>
        <ScrollArea
          className={'scrollarea max-h-screen'}
          contentClassName={'scrollarea-content'}
          smoothScrolling
          speed={0.8}
          verticalContainerClassName={'scrollbar-container'}
          verticalContainerClassNameActive={'scrollbar-container--active'}
          verticalContainerClassNameHorizontal={'scrollbar-container--horizontal'}
          verticalContainerClassNameVertical={'scrollbar-container--vertical'}
          verticalScrollbarClassName={'scrollbar'}
          stopScrollPropagation={false}
        >
          <div className="content px-16 pb-8 mb-12">
            <PageTitle title={props.item.title}/>
            <div className='flex my-8 flex-wrap'>
              {props.item.sdg_type.map((type)=>{
                return (
                  <div style={{color: type.acf.color}} className='m-2 ml-0 flex items-center'>
                    <span className="dot mr-2 " style={{backgroundColor: type.acf.color}}></span> {type.name}
                  </div>

                )
              })}
            </div>
            <div className="wp_content sdg" dangerouslySetInnerHTML={{__html:props.item.content}} />
            {yoast &&
             <CeficShare context={yoast} className="mr-5 mt-10" url={props.item.path}/>
            }
          </div>
        </ScrollArea>
      </div>
    </aside>
  )
}

export const SDGPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(SDGPanelComponent);
