import React from "react";
import { graphql } from "gatsby";
import { Link } from "../components/link";
import Layout from "../components/layout";
import GutenbergRenderer from "../gutenberg";
import {
	HomeSectionTitle,
	SubTitle,
	PBody,
	SpanTag,
	MetaInfoRegular,
} from "../components/typography";
import { PrimaryButton, SecondaryButton } from "../components/buttons";
import Img from "gatsby-image";
import MediaQuery from "react-responsive";
import { ReactComponent as DownloadIcon } from "../assets/icons/file-download.svg";
import { ReactComponent as ChevronRight } from "../assets/icons/chevron-right.svg";
import Moment from "react-moment";
import { VerticalPreview } from "../components/posts/preview";
import EventSlider from "../components/events/homefeed";
import classnames from "classnames";
import { HomePageCarousel } from "../components/home";

const HomePageSection = (props) => (
	<section
		id={props.id}
		className={classnames(["home-section", props.className])}
	>
		{props.title && (
			<HomeSectionTitle dangerouslySetInnerHTML={{ __html: props.title }} />
		)}
		<div className="relative mb-5 mt-4 pt-2 border-t">
			{props.showLeftBand && (
				<div
					className="absolute pin-t pin-l"
					style={{ transform: "translateY(-50%)" }}
				>
					<hr
						className="border-brand mx-0 mr-auto"
						style={{ borderWidth: 2, width: 140 }}
					/>
				</div>
			)}
			{props.showMiddleBand && (
				<div
					className="absolute pin-t hidden lg:visible"
					style={{ transform: "translateX(-50%)", top: -25, left: "50%" }}
				>
					<hr
						className="border-brand "
						style={{ borderTopWidth: 40, width: 4 }}
					/>
				</div>
			)}
		</div>
		{props.children}
		{props.button && (
			<div className="w-100 text-center my-home-section">
				<Link
					id={props.id + "__cta"}
					to={props.button.url}
					url={props.button.url}
					target={props.button.target}
				>
					<PrimaryButton label={props.button.title} />
				</Link>
			</div>
		)}
	</section>
);
/*const HomePageCarouselItem = (props) => (
  <>
  <div className="lg:mr-auto">
    <h1 className="font-serif tracking-02 leading-47 text-5xl font-normal mb-7" dangerouslySetInnerHTML={{__html:props.title}}></h1>
    <div style={{maxWidth: 400 }} className="lg:ml-auto">
      <hr className="border-brand mr-auto ml-0 mb-5" style={{borderWidth:2, width:35}}/>
      <div className="mb-7" dangerouslySetInnerHTML={{__html:props.excerpt}}/>
      <Link to={props.slug}>
        <SecondaryButton  label="READ MORE +"/>
      </Link>
    </div>
  </div>
  {props.featured_media && props.featured_media.localFile &&
   <div className="w-full lg:w-1/2 lg:ml-3 flex-no-shrink mt-5">
     <Img fluid={props.featured_media.localFile.childImageSharp.fluid}/>
   </div>
  }
  </>
)
*/
const HomeTopNews = (props) => (
	<section className=" mb-home-section">
		<HomePageCarousel />
	</section>
);

const Section3Item = (props) => {
	const componentId = "home__section_3__item__" + props.index;
	return (
		<div
			style={props.style}
			className="home__section_3__item mx-auto mb-5 bg-white"
			id={componentId}
		>
			<div className="my-7">
				<SubTitle dangerouslySetInnerHTML={{ __html: props.title }} />
				<hr
					className="mt-7 mb-4 border border-brand mx-0 mr-auto"
					style={{ width: 30 }}
				/>
				<SpanTag dangerouslySetInnerHTML={{ __html: props.tag }} />
				{props.image && props.image.localFile && (
					<div>
						<MediaQuery minWidth={768}>
							<Img
								className="mt-5"
								fixed={props.image.localFile.childImageSharp.fixed}
							/>
						</MediaQuery>
						<MediaQuery maxWidth={767}>
							<Img
								className="mt-5"
								fluid={props.image.localFile.childImageSharp.fluid}
							/>
						</MediaQuery>
					</div>
				)}
				<PBody
					className="my-7"
					dangerouslySetInnerHTML={{ __html: props.excerpt }}
				/>
			</div>
			{props.button && (
				<div className="w-100 mx-auto">
					<Link
						to={props.button.url}
						url={props.button.url}
						target={props.button.target}
						id={componentId + "__cta"}
						className="home__section_3__item__cta"
					>
						<SecondaryButton label={props.button.title} />
					</Link>
				</div>
			)}
		</div>
	);
};

const DocumentLink = (props) => {
	const documentId =
		props.baseId +
		"__" +
		props.link
			.split("/")
			.filter((e) => e !== "")
			.slice(-1)[0];
	return (
		<div
			className={classnames(props.className, "flex items-center mb-5 pr-7")}
			id={documentId}
		>
			<div>
				<SubTitle dangerouslySetInnerHTML={{ __html: props.title }} />

				<MetaInfoRegular>
					<Moment format="DD/MM/YYYY">{props.date}</Moment>
				</MetaInfoRegular>
			</div>
			<div className="ml-auto">
				{props.acf.document_type === "file" && (
					<Link
						target="_blank"
						url={props.link}
						id={documentId + "__cta"}
						className={props.className + "__cta"}
					>
						<DownloadIcon className="text-fill" width={25} height={25} />
					</Link>
				)}
			</div>
		</div>
	);
};

export default ({ data, pageContext }) => {
	const top_news = data.wordpressPage.top_news;
	const news_section = data.wordpressPage.news_section;
	const section_3 = data.wordpressPage.section_3;
	const position_papers = data.wordpressPage.position_papers;
	const follow_us = data.wordpressPage.follow_us;
	const section_4 = data.wordpressPage.section_4;

	const section_events = data.wordpressPage.events;
	return (
		<Layout pageContext={pageContext}>
			<div>
				{top_news && top_news.length > 0 && <HomeTopNews items={top_news} />}
				<div className="relative">
					<div className="absolute w-full h-full ">
						<MediaQuery minWidth={1200}>
							<div className="w-1/2 border-r h-full" />
						</MediaQuery>
					</div>
					<div className="relative">
						{news_section && (
							<HomePageSection
								id="homepage_section__news"
								showMiddleBand
								showLeftBand
								{...news_section}
							>
								<div className="lg:flex">
									{news_section.big_news && (
										<div
											id="homepage_section__featured_news"
											className="flex-auto"
											style={{ flexBasis: "50%" }}
										>
											<VerticalPreview post={news_section.big_news} />
										</div>
									)}
									{news_section.small_news && (
										<div
											className="flex-auto"
											style={{ flexBasis: "50%" }}
											id="homepage_section__small_news"
										>
											<div className="lg:flex">
												{news_section.small_news.map((sm) => (
													<VerticalPreview post={sm} key={sm.slug} />
												))}
											</div>
										</div>
									)}
								</div>
							</HomePageSection>
						)}

						{section_3 && (
							<HomePageSection
								id="homepage_section__section_3"
								showMiddleBand
								showLeftBand
								{...section_3}
							>
								<div className="flex flex-wrap justify-between">
									{section_3.preview.map((sp, index) => (
										<Section3Item
											style={{ maxWidth: 512 }}
											{...sp}
											index={index}
											key={"section_3__item__" + index}
										/>
									))}
								</div>
							</HomePageSection>
						)}
						{section_events.display_events && (
							<HomePageSection
								id="homepage_section__events"
								showMiddleBand
								showLeftBand
								{...section_events}
							>
								<div className="mt-2">
									<EventSlider />
								</div>
							</HomePageSection>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
};
export const defaultPagequery = graphql`
	query HomePageQuery($id: String!) {
		wordpressPage(id: { eq: $id }) {
			title
			slug
			top_news {
				button_label
			}
			news_section {
				title
				button {
					title
					url
					target
				}
				big_news {
					id
					slug
					path
					title
					content
					excerpt
					date
					featured_media {
						localFile {
							verticalThumb: childImageSharp {
								fluid(maxWidth: 600) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
				small_news {
					id
					slug
					path
					title

					content
					excerpt
					date
					featured_media {
						localFile {
							verticalThumb: childImageSharp {
								fluid(maxWidth: 240, maxHeight: 194, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
			section_3 {
				title
				button {
					url
					title
					target
				}
				preview {
					title
					tag
					excerpt
					button {
						url
						title
						target
					}
					image {
						localFile {
							childImageSharp {
								fluid(maxHeight: 325, maxWidth: 512, quality: 100) {
									...GatsbyImageSharpFluid
								}
								fixed(height: 325, width: 512, quality: 100) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
			follow_us {
				title
			}
			position_papers {
				title
				button {
					title
					url
					target
				}
				position_papers {
					wordpress_id
					title
					link: path
					date
					acf {
						document_type
						external_link
						file {
							mime_type
							alt
						}
					}
				}
			}
			section_4 {
				title
				video_url
				content {
					title
					excerpt
				}
				button {
					url
					target
					title
				}
			}
			events {
				display_events
				title
				button {
					url
					title
					target
				}
			}
		}
	}
`;
