import React, { useState } from 'react'
import Img from 'gatsby-image/withIEPolyfill';
import {connect} from 'react-redux';
import { navigate } from "gatsby"
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';

import './hive.css'

export const Hive = ({tr, br, b, style, item, typesColors, goalImages, openPanel, external}) => {
  let segments = [true, true, true, false, false, false];
  if(b) {
    segments[3] = true;
  }
  if(br) {
    segments[4] = true;
  }
  if(tr) {
    segments[5] = true;
  }
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    if(external) {
      navigate(item.path)
    }else {
      openPanel(item)
    }
  }
  return (
    <div className={`hive-container ${hover ? 'hovered' : ''}`} style={style} onClick={handleClick}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        viewBox="0 0 308.7 269.9" style={{display: 'inline-block'}}>
        <line className="st0" style={{visibility: segments[5] ? 'visible' : 'hidden'}} x1="5.7" y1="134.9" x2="80" y2="5.1"/>
        <line className="st0" style={{visibility: segments[0] ? 'visible' : 'hidden'}} x1="228.7" y1="5.1" x2="80" y2="5.1"/>
        <line className="st0" style={{visibility: segments[1] ? 'visible' : 'hidden'}} x1="303" y1="134.9" x2="228.7" y2="5.1"/>
        <line className="st0" style={{visibility: segments[2] ? 'visible' : 'hidden'}} x1="228.7" y1="264.8" x2="303" y2="134.9"/>
        <line className="st0" style={{visibility: segments[3] ? 'visible' : 'hidden'}} x1="80" y1="264.8" x2="228.7" y2="264.8"/>
        <line className="st0" style={{visibility: segments[4] ? 'visible' : 'hidden'}} x1="5.7" y1="134.9" x2="80" y2="264.8"/>
        <polygon className='background' onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}
        points="228.4,264.3 302.4,134.9 228.4,5.6 80.3,5.6 6.3,134.9 80.3,264.3 "/>

        <foreignObject width="190" height="269.9" x='59' y='0' onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
          <div className='flex flex-col justify-center h-full'>
            <h1 className='hive-title'>{item.title}</h1>
            <div className='flex justify-center mt-4'>
              {item.sdg_type.map(type => (
                <span className='hive-dot' style={{backgroundColor: type.acf.color}}></span>
              ))}
            </div>
            <div className='flex justify-center flex-wrap items-center mt-2'>
              {item.sdg_goal.map((goal, index)=> {
                  if(item.sdg_goal.length > 6 && index == 5) {
                    return (
                      <div className='m-1 last-image-container' style={{backgroundImage: `url(${goalImages[goal]?.src})`}}>
                        <div className='plus-icon'>
                          <PlusIcon />
                        </div>
                      </div>
                    )
                  }
                  if(index>5) {
                    return ''
                  }
                  return (
                    <div className='m-1' style={{height:40, width:40}}>
                      <img src={goalImages[goal]?.src} style={{height: 40, width: 40}} />
                    </div>
                  )
              })}
            </div>
          </div>
        </foreignObject>
      </svg>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    openPanel: (item) => dispatch({type:`OPEN_SDG`, item: item})
  };
};

const mapStateToProps = ({panel})=>{
  return {};
};

export const ConnectedHive = connect(
  mapStateToProps,
  mapDispatchToProps
)(Hive);
