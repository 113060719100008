import React from 'react'
import {Provider} from 'react-redux';
import {createStore as reduxCreateStore} from 'redux';
import createStore from './src/state/createStore';
import {Context as ResponsiveContext} from 'react-responsive';


export const store = createStore();


export default ({element}) => {
  return (
    <Provider store={store}>
      {typeof window !== 'undefined' ?
       element
      :
       <ResponsiveContext.Provider value={{width:1440}}>
       {element}
       </ResponsiveContext.Provider>
      }
    </Provider>
  )
}
