import React from 'react';
import Select from 'react-select';

const selectStyles = {
  control: (styles,{isFocused}) => ({
    ...styles,
    borderRadius:0,
    // This line disable the blue border
    boxShadow: "0 0 0 0.6px #D6D7D7",
   
    borderColor:  "#F47B20" ,
    borderTop:0,
    borderLeft: 0,
    borderRight: 0,
    '&:hover': {
      borderColor: isFocused ? "#F47B20" : null,
     
    }
  }),
  indicatorSeparator: style =>({display:null}),
  menu: styles => ({...styles, borderRadius:0, marginTop:0}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      backgroundColor: isFocused?'#EAEAEB':null,
      display:isSelected?'none':null,
    };
  }
};
export default ({months, value, onChange}) => (
    <Select
      className="cefic-select-container"
      classNamePrefix="cefic-select"
      isSearchable={false}
      defaultValue={months[0]}
      options={months}
      onChange={onChange}
      styles={selectStyles}
    />
);
