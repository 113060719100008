import React from "react";
import { withPostList } from "./providers";
import { chunk, buildIndex } from "../../utils";
import { VerticalPreview } from "./preview";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";

export class PostSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			postsById: [],
			currentPage: 0,
			totalPages: 0,
			perPage: [],
			posts: [],
		};
		this.perPage = 2;
		this.next = this.next.bind(this);
		this.prev = this.prev.bind(this);
		this.containerRef = React.createRef();
	}

	componentDidMount() {
		if (this.containerRef.current) {
			this.perPage = Math.floor(
				(this.containerRef.current.clientWidth - 100) / 235
			);
		}

		const chunked = chunk(
			this.props.posts.map((i) => i.id),
			this.perPage
		);
		const byId = buildIndex(this.props.posts);
		this.setState({
			posts: [...this.props.posts],
			postsById: byId,
			totalPages: chunked.length,
			perPage: [...chunked],
		});
	}
	prev() {
		let currentPage = this.state.currentPage;
		if (currentPage - 1 >= 0) {
			currentPage = currentPage - 1;
		} else {
			currentPage = Math.max(this.state.totalPages - 1, 0);
		}
		this.setState({ currentPage: currentPage });
	}
	next() {
		let currentPage = this.state.currentPage;
		if (currentPage + 1 >= this.state.totalPages) {
			currentPage = 0;
		} else {
			currentPage = currentPage + 1;
		}
		this.setState({ currentPage: currentPage });
	}
	render() {
		const slide = this.state.perPage.length
			? this.state.perPage[this.state.currentPage].map(
					(i) => this.state.posts[this.state.postsById[i]]
			  )
			: [];
		return (
			<div ref={this.containerRef} className="w-full">
				<div className="flex items-center flex-no-wrap flex-shrink">
					<ChevronLeft
						onClick={this.prev}
						width={25}
						height={25}
						className="text-black fill-current flex-no-shrink mr-5"
					/>
					<div className="justify-center overflow-x-hidden">
						<div className="flex ">
							{slide &&
								slide.map((post) => (
									<VerticalPreview
										post={post}
										style={{ width: 235 }}
										key={post.slug}
										hideThumbnail={!this.props.showThumbnail}
									/>
								))}
						</div>
					</div>
					<ChevronRight
						onClick={this.next}
						width={25}
						height={25}
						className="text-black fill-current flex-no-shrink ml-5"
					/>
				</div>
			</div>
		);
	}
}

const PostSliderList = withPostList(PostSlider);

export default PostSliderList;
