import React,{Fragment} from "react";
import {ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import {ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import GlobalSearch from './index';
import {connect} from 'react-redux';
import {Modal} from '../../modals';
const mapStateToProps = ({searchPanel})=>{
  return {searchPanel};
};

const mapDispatchToProps = dispatch => {
  return {
    openSearch: ()=> dispatch({type:`OPEN_SEARCH`}),
    closeSearch: ()=> dispatch({type: `CLOSE_SEARCH`})
  };
};

const innerGlobal = (props) => (
  <GlobalSearch/>
);

const SearchModal = (props) =>(
  <Modal>
    <GlobalSearch/>
  </Modal>
);

export const SearchButtonComponent = (props) => (
  <div className="flex items-center h-100 hover:cursor-pointer">
    <SearchIcon height={25} width={25} className="h-full" />
  </div>
);

class ConnectedSearchButton extends React.Component{
  constructor(props){
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }
  onClickHandler(){
    if(!this.props.searchPanel.isOpen){
      this.props.openSearch();
    }else{
      this.props.closeSearch();
    }
 
  }
  render(){
    return (
      <Fragment>
        <button onClick={this.onClickHandler} className="focus:outline-none " id='search-button'>
      {this.props.searchPanel.isOpen?
       <CloseIcon/>
       :
       <SearchButtonComponent/>
      }
        
      </button>
      </Fragment>
    );
  }
}

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSearchButton);
