import React, {Fragment} from 'react';
import {
  PageTitle
} from '../common';
import {getTimesString} from './parts/time';
import {MetaInfo} from '../typography';

class Event extends React.Component {
  constructor(props){
    super(props);
  }
  render(){
    return(
      <Fragment>
        <PageTitle title={this.props.title} />
        <div className="mb-4">
          <div>
            <MetaInfo>{getTimesString(this.props.date, this.props.time, this.props.end_date, this.props.end_time)}</MetaInfo>
          </div>
          <div>
            <MetaInfo >{this.props.location} </MetaInfo>
          </div>
        </div>
        <div className="wp_content" dangerouslySetInnerHTML={{__html:this.props.content}}/>
      </Fragment>
    );
  }
}
export default Event;
