import axios from 'axios'


const axiosConfig = {
  baseURL:`${process.env.GATSBY_WP_PROTOCOL}://${process.env.GATSBY_WP_URL}/wp-json/gf/`,
  methdod: 'get',
}

export const submit = (id, values) => {
  return axios({
    ...axiosConfig,
    method: 'post',
    url: `/v2/forms/${id}/submissions`,
    data: values
  })
}

export const getForm = (id)=>{
  return axios({
    ...axiosConfig,
    url: `/v2/forms/${id}`
  })
}

export const getAllForms = () => {
  return axios({
    ...axiosConfig,
    method: 'get',
    url: `/v2/forms`
  })
}
