import React from 'react';
import {ContentTypesFilters} from './content_types';
import {TopicsFilters} from './topics';
import {SearchField} from '../../forms/fields';
import {SecondaryButton}  from '../../buttons';
import {connect} from 'react-redux';
import {SecondaryPanel} from '../../panels';
import {ReactComponent as FilterIcon} from '../../../assets/icons/filter.svg';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';

export const LibraryItemsFilters = (props) =>{
return(
  <div className="flex flex-col lg:flex-row justify-around">
    {props.showContentTypes && 
    <div>
      <h3>Content types</h3>
      <ContentTypesFilters restrict_to={props.allContentTypes} selected={props.selectedContentTypes} updateHandler={types=>{props.onUpdateSelectedTypes(types);}}/>
     </div>
    }
    {props.showTopics && 
    <div className='mt-4 lg:ml-16 lg:mt-0'>
      <h3>Topics</h3>
      <TopicsFilters restrict_to={props.allTopics} selected={props.selectedTopics} updateHandler={types=>{props.onUpdateSelectedTopics(types);}}/>
     </div>
    }
  </div>
);
};


const mapStateToProps = ({panel})=>{
  return {panel:panel};
};
const mapDispatchToProps = dispatch => {
  return  {
    setPanelContent: (content) => dispatch({type:`SET_PANEL_CONTENT`, content:content}),
    openPanel: () => dispatch({type: `OPEN_PANEL`}),
    closePanel: () => dispatch({type:`CLOSE_PANEL`})
  };
};

class LibraryItemsSearchFormComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      filters:{
        topics: [],
        contentTypes: []
      },
      textSearch: '',
      filtersMobileOpen: false,
    };
    this.toggleFilters = this.toggleFilters.bind(this);
    this.updateTopics = this.updateTopics.bind(this);
    this.updateContentTypes = this.updateContentTypes.bind(this);
    this.updateTextQuery = this.updateTextQuery.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
    this.toggleFiltersMobile = this.toggleFiltersMobile.bind(this)
  }
  updateQuery(){
    this.props.onUpdateQuery({...this.state});
  }
  updateTopics(selectedTopics){
    this.setState({
      filters:{
        ...this.state.filters,
        topics: selectedTopics
      }
    }, this.updateQuery);
  }
  updateContentTypes(selectedTypes){
    this.setState({
      filters: {
        ...this.state.filters,
        contentTypes: selectedTypes
      }
    }, this.updateQuery);
  }
  updateTextQuery(value){
    this.setState({
      textSearch: value
    }, this.updateQuery);
  }
  toggleFilters(){
    if(this.props.panel.isOpen) {
      this.props.closePanel();
    }else {
      const filterSettings = this.props.settings.filtersSettings;
      const panelProps = {
        showTopics: !filterSettings.taxonomies || filterSettings.taxonomies.length === 0?true:filterSettings.taxonomies.includes('topics'),
        allTopics: filterSettings.restrict_topics_to,
        selectedTopics: this.state.filters.topics,
        onUpdateSelectedTopics: this.updateTopics,
        showContentTypes: !filterSettings.taxonomies || filterSettings.taxonomies.length === 0?true:filterSettings.taxonomies.includes('content-type'),
        allContentTypes: filterSettings.restrict_content_type_to,
        selectedContentTypes: this.state.filters.contentTypes,
        onUpdateSelectedTypes: this.updateContentTypes
      };
      this.props.setPanelContent(LibraryItemsFilters(panelProps));
      this.props.openPanel();
    }
  }
  toggleFiltersMobile() {
    this.setState((prevState) => ({filtersMobileOpen: !prevState.filtersMobileOpen}))
  }
  render(){
    return (
      <div className="flex justify-between">
        {this.props.settings===undefined || this.props.settings.searchControl.showTextSearch && 
        <div>
          <SearchField placeholder="type to search" onChangeHandler={v=>{this.updateTextQuery(v);}}/>
        </div>
        }
        {this.props.settings===undefined ||this.props.settings.searchControl.showFilters && 
        <div>
          <div className="hidden lg:block">
            <SecondaryButton label="FILTERS" onClickHandler={this.toggleFilters}/>
          </div>
          <div className='absolute ' style={{width: 56, right: 0}}>
            <button className='fixed lg:hidden bg-brand cursor-pointer z-100' style={{height: 56, width: 56, zIndex: 18}} onClick={this.toggleFiltersMobile}>
              <FilterIcon style={{height: 36, width: 36, margin: 10, color: 'white'}}/>
            </button>
          </div>
          {this.state.filtersMobileOpen &&
            <div className='w-full h-full fixed bg-panel-secondary p-8' style={{zIndex: 19, left: 0, top: 60, overflow: 'auto'}}>
                <button className='absolute text-grey cursor-pointer' style={{top:10, right:10}} onClick={this.toggleFiltersMobile}>Apply</button>
                {LibraryItemsFilters({
                  showTopics: !this.props.settings.filtersSettings.taxonomies || this.props.settings.filtersSettings.taxonomies.length === 0?true:this.props.settings.filtersSettings.taxonomies.includes('topics'),
                  allTopics: this.props.settings.filtersSettings.restrict_topics_to,
                  selectedTopics: this.state.filters.topics,
                  onUpdateSelectedTopics: this.updateTopics,
                  showContentTypes: !this.props.settings.filtersSettings.taxonomies || this.props.settings.filtersSettings.taxonomies.length === 0?true:this.props.settings.filtersSettings.taxonomies.includes('content-type'),
                  allContentTypes: this.props.settings.filtersSettings.restrict_content_type_to,
                  selectedContentTypes: this.state.filters.contentTypes,
                  onUpdateSelectedTypes: this.updateContentTypes
                })}
              </div>
          }
         </div>
        }
      </div>
    );
  }
};
export const LibraryItemsSearchForm = connect (
  mapStateToProps,
  mapDispatchToProps
)(LibraryItemsSearchFormComponent);
