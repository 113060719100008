import React from 'react';
import classnames from 'classnames';

import {
	getColorClassName,
} from '../utils.js';


export default (props) => {
  const {
		align,
		content,
		dropCap,
		backgroundColor,
		textColor,
		customBackgroundColor,
		customTextColor,
		fontSize,
		customFontSize,
	//	direction,
	} = props;

  const textClass = getColorClassName( 'color', textColor );
	const backgroundClass = getColorClassName( 'background-color', backgroundColor );
	const fontSizeClass = fontSize && `is-${ fontSize }-text`;

  const className = classnames( {
		'has-text-color': textColor || customTextColor,
		'has-background': backgroundColor || customBackgroundColor,
		'has-drop-cap': dropCap,
		[ fontSizeClass ]: fontSizeClass,
		[ textClass ]: textClass,
		[ backgroundClass ]: backgroundClass,
	} );

  const styles = {
		backgroundColor: backgroundClass ? undefined : customBackgroundColor,
		color: textClass ? undefined : customTextColor,
		fontSize: fontSizeClass ? undefined : customFontSize,
		textAlign: align,
	};
  return (
    <p style={styles} className={className?className:undefined} dangerouslySetInnerHTML={{__html:content}}/>
  );
}

