import React, { useEffect, useState, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { navigate } from "gatsby";
import Post from "../posts/post";
import TooltipStyles from "./tooltip.css";

const mapStateToProps = ({ panel }) => {
	return { active_countries: [], panel_props: { ...panel } };
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPanelContent: (content, path, context) =>
			dispatch({
				type: `SET_PANEL_CONTENT`,
				content: content,
				path: path,
				context: context,
			}),
		openPanel: () => dispatch({ type: `OPEN_PANEL` }),
	};
};

export const InteractiveMapComponent = (props) => {
	const [svg, setSvg] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isErrored, setIsErrored] = useState(false);
	const [tooltip, setTooltip] = useState(false);
	const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
	const [active_countries, setActiveCountries] = useState(
		props.active_countries
	);
	const regions = props.clickable_regions;
	useEffect(() => {
		fetch(props.src)
			.then((response) => response.text())
			.then(setSvg)
			.catch(setIsErrored)
			.then(() => setIsLoaded(true))
			.then(() => {
				const svgmap = document.getElementById("svg_map");
				active_countries.map((country) => {
					const e = svgmap.querySelector(`g#${country["country_code"]}`);
					if (!!e) {
						e.classList.add("active");
					}
				});

				regions.map((region) => {
					const e = svgmap.querySelector(`g#${region["country_code"]}`);
					if (!!e) {
						e.classList.add("neutral");

						if (!!region["tooltip_text"]) {
							e.addEventListener("mouseenter", (event) => {
								if (tooltip != region["tooltip_text"]) {
									setTooltip(region["tooltip_text"]);
								}
							});
						}
						e.addEventListener("mouseout", (event) => {
							setTooltip(false);
						});
						e.addEventListener("click", (event) => {
							if (!e.classList.contains("active")) {
								const items = svgmap.querySelectorAll("g.active");
								svgmap.querySelectorAll("g.active").forEach((f) => {
									f.classList.remove("active");
								});
								e.classList.add("active");
							}
							if (props.open_in_panel) {
								props.setPanelContent(<Post {...region} />, region.path);
								props.openPanel();
								setActiveCountries([region["country_code"]]);
							} else if (props.onClickHandler) {
								setActiveCountries([region["country_code"]]);
								props.onClickHandler(region);
							} else {
								navigate(region.path);
							}
						});
					}
				});
			});
	}, [props.src]);
	useEffect(() => {
		const active_regions = document
			.getElementById("svg_map")
			.querySelectorAll("g.active");
		if (!!active_regions) {
			active_regions.forEach((region) => {
				if (!active_countries.includes(region.id)) {
					region.classList.remove("active");
				}
			});
		}
		if (!!active_countries) {
			active_countries.forEach((e) => {
				document.querySelector(`#svg_map g#${e}`)?.classList.add("active");
			});
		}
	}, [active_countries, isLoaded]);

	useEffect(() => {
		if (!!props.panel_props && !props.panel_props.isOpen) {
			setActiveCountries([]);
		}
	}, [props.panel_props]);
	useEffect(() => {
		setActiveCountries(props.active_countries);
	}, [props.active_countries]);

	return (
		<>
			<div
				id="svg_map"
				onMouseMove={(e) => {
					setTooltipPosition({ x: e.clientX, y: e.clientY });
				}}
				className={`svgInline svgInline--${isLoaded ? "loaded" : "loading"} ${
					isErrored ? "svgInline--errored" : ""
				}`}
				dangerouslySetInnerHTML={{ __html: svg }}
			/>
			{!!tooltip && (
				<div
					className="map_tooltip"
					style={{
						top: tooltipPosition.y + 25,
						left: tooltipPosition.x - 70,
					}}
				>
					<span>{tooltip}</span>
				</div>
			)}
		</>
	);
};

export const InteractiveMap = connect(
	mapStateToProps,
	mapDispatchToProps
)(InteractiveMapComponent);
