export const onChangeHandler = (e, field, status, setStatus, setFieldValue, handleChange )=>{
  // e is a syntheticEvent if using standard formik handleChange
  // e is a value if using setValue 
  if(status && status.hasOwnProperty(field.id)){
    let updated_status = {...status}
    delete updated_status[field.id]
    setStatus(updated_status)
  }
  //select fields are implemented using react-select and need to use setValue rather than handleChange
  if(field.type ==="select"){
     return setFieldValue(field.id, e)
  }else {
    return handleChange(e)
  }
}

export const normalizeFormFields = (fields) => {
  let values = {}
  const normalized_fields = fields.map(field=>{
    if(field.type==="phone"){
      field.type = "tel"
    }
    values[field.id.toString()]=field.defaultValue
    return field
  })
  return [normalized_fields, values]
}
