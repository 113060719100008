import React, {useState} from "react";
import {PSubTitle, SubSubTitle, PBody, MetaInfo, LargeButtonLabel} from '../../typography';
import classnames from 'classnames';
import Img from "gatsby-image"
import {ReactComponent as Download } from '../../../assets/icons/file-download.svg';
import {ReactComponent as  ChevronRight } from '../../../assets/icons/chevron-right.svg';
import Moment from 'react-moment';
import {Link} from 'gatsby';
import {Link as CeficLink} from '../../link';
import './style.css';



const RelatedDocumentThumbnail = ({featured_media, default_image, hover}) => {
  let thumbnail = featured_media?.localFile?.smallHThumb;

  return (
    <div className="relative flex-no-shrink  " style={{width:97}}>
      {!!thumbnail ?
       <Img fixed={thumbnail.fixed} style={{margin:'0 auto'}}/>
       :
       <svg className="fill-current block text-grey-lightest flex-no-shrink" width="100%" height="100%">
         <rect width="100%" height="100%"/>
       </svg>
      }
      {hover &&
       <svg className="block absolute opacity-75 pin-y pin-l fill-current text-brand flex-no-shrink z-50" width='100%' height='100%'>
         <rect width="100%" height="100%"/>
       </svg>
      }
    </div>
  );

}

const RelatedDocumentItem = ({title, description, date, path, is_download, is_external, featured_media}) => {
  const [state, setState] = useState({
    hover:false
  });
  const classesChevron = classnames(
    'fill-current',
    {
      'text-grey-darkest': !state.hover,
      'text-white': state.hover
    }
  );

  const className=classnames(
    `supporting-document__item  relative flex items-top justify-center  border-b h-full`,
    {'hover:cursor-pointer': state.hover}
  );
  const btnclassName= classnames(
    'border p-button flex items-center',
    {
      'border-grey-darkest': state.hover,
      'border-brand bg-brand': state.hover,
    },
  );
  const labelClassName= classnames({
    'text-white': state.hover,
  })

  // disable description, the markup is kept because the decision to remove them might be reverted in the future, see issue #390
  description = false;


  return (
    <Link to={path}>
      <div className={className} onMouseEnter={()=>setState({hover:true})} onMouseLeave={()=>setState({hover:false})}>
      <RelatedDocumentThumbnail
        featured_media={featured_media}
      />
      <div className="px-15px py-2 flex flex-col justify-between overflow-auto flex-grow">
        <div>
          <SubSubTitle hover={state.hover?state.hover:undefined} dangerouslySetInnerHTML={{__html:title}}/>
        </div>

          {!!description &&
           <div className="font-sans font-light text-base text-black tracking-02 leading-21 mb-auto" dangerouslySetInnerHTML={{__html:description}}/>

          }
          <div className="flex items-center">

            {is_download &&

               <button
                 className={btnclassName}
               >
                 <Download width={20} height={20} className={`${state.hover?'text-white':''} fill-current text-grey-darkest hover:text-brand mr-2`}/>
             <LargeButtonLabel hover={state.hover} className={labelClassName} dangerouslySetInnerHTML={{__html:"Download"}} />
               </button>



            }
            {!is_download && is_external &&
               <button
                 className={btnclassName}
               >

                 <LargeButtonLabel hover={state.hover} className={labelClassName} dangerouslySetInnerHTML={{__html:"Read More"}} />
                 <ChevronRight width={20} height={20} className={classesChevron}/>
               </button>
            }
            {!is_download && !is_external &&
               <button
                 className={btnclassName}
               >
                 <LargeButtonLabel hover={state.hover} className={labelClassName} dangerouslySetInnerHTML={{__html:"Read More"}} />
                 <ChevronRight width={20} height={20} className={classesChevron}/>
               </button>
            }
          </div>
        </div>
      </div>

    </Link>
  );
};


const RelatedDocuments = (props) => {

  const classes = ` mt-10 related-content`
  return (
    <section className={classes}>
      {!!props.title &&
       <PSubTitle className="mb-7" dangerouslySetInnerHTML={{__html:props.title}}/>
      }
      <div className="grid">
    {
      props?.items?.length>0  && props.items.map(item=>{
        return (
          <RelatedDocumentItem {...item} key={item.slug}/>
        )
      }
      )

    }
    </div>
    </section>
  );
};

export default RelatedDocuments;
