import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {PageTitle, WidgetAside} from '../components/common';
import {CeficShare as SocialShare} from '../components/social-shares';
import FlexibleContent from '../components/flexible-builder'


export default (props) =>{
  const data = props.data.wordpressWpNews;
  return(
    <Layout pageContext={props.pageContext} aside={WidgetAside({contactBox: {...data.contact_box}})} ancestors={data.ancestors}>
    <div style={{maxWidth:1025}} className="mx-0 px-0">
    <div style={{maxWidth: 734}} className="mx-0 px-0">
      <PageTitle title={data.title}/>
      <div className="wp_content" dangerouslySetInnerHTML={{__html:data.content}} />
      <SocialShare className="mt-10" url={data.path} context={props.pageContext.yoast} />
    </div>
      {!!data.widgets?.widgets?.length> 0 &&
       <FlexibleContent data={data.widgets.widgets}/>
      }
      </div>
    </Layout>
  );
}
export const defaultNewsQuery = graphql`
query DefaultNewsById($id: String!) {
  wordpressWpNews(id:{eq:$id}){
    title
    slug
    wordpress_id
    content
    path
    ancestors {
      name:title
      slug
      path
    }
    widgets{
      ...FlexbileContentBuilder
    }
    contact_box {
      show_contact_box
      title
      text
      phone
      email
    }
  }
}
`;
