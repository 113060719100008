import React from 'react';
import {ToolbarBottomMenu} from './menu';

export default (props) => (
  <div className="top-toolbar bg-grey-dark text-white py-1 text-2xs">
    <div className="container mx-auto text-center lg:text-left lg:flex justify-between py-1">

      <ToolbarBottomMenu/>
      { props.before_menu && 
        <div className="mr-5 my-2 lg:my-0" style={{order:-1}} dangerouslySetInnerHTML={{__html:props.before_menu}}></div>
      }
    </div>
  </div>
)
